import _ from 'lodash'
import * as d3 from 'd3'
import * as d3ScaleChromatic from 'd3-scale-chromatic'
import Interpolators from './interpolators'


// Color Scale Options - Need to Start Defaulting Based on Given Situation - These Can Also Be Set as Options
// Not Currently Used, But Keep for Future Optionality
const interps = {
    'blues' : d3.interpolateBlues,  // Light to Dark
    'greens' : d3.interpolateGreens, // Light to Dark
    'grays' : d3.interpolateGreys,  // Light to Dark
    'reds' : d3.interpolateReds, // Light Red to Dark Red
    'red-green' : d3.schemeRdYlGn // Diverging Color Set
}

// Used in Discrete Color Scales Depending on Situation
// Non Default Schemes Indexed by Number of Elements - Max 9 - Just Return Constants for Now
const schemes = {
    'default' : d3ScaleChromatic.schemeCategory10, // An array of 10 categorical colors
    'set1' : d3.schemeSet1, // An array of 9,
    'set2' : d3.schemeSet2,  // An array of 8 - Consistent with d3.schemeCategory20
    'set3' : d3.schemeSet3, // An array of 12,
    'paired' : d3.schemePaired, // An array of 12 - Consistent with d3.schemeCategory20
    'grays' : d3.schemeGreys,
    'spectral' : d3.schemeSpectral,
}

const DiscreteColorGenerator: any = function(this: any, axis: any, values: any){
    if(_.uniq(values).length != values.length) throw new Error('Values for Color Scale Are Not Unique')

    this.values = values
    this.axis = axis
    if(!this.axis.tracked[this.axis.param]) this.axis.tracked[this.axis.param] = {}

    // Determine Which Scheme To Use Based on Number of Unique Values and Param Type - Styling Purposes
    this.scheme = function(){
        if(this.axis.param == 'Name'){
            if(this.values.length <= 12){
                return schemes.paired
            }
            else if(this.values.length <= 8){
                return schemes.set2
            }
            return schemes.default
        }
        // Most Likely Just Strategy for Now - d3.schemeSet1 Works Best
        // To Do: WILL CAUSE ISSUES IF VALUES ARRAY > 9
        if(this.values.length <= 9){
            return schemes.set1
        }
        return schemes.default
    }

    this.highlightedScale = d3.scaleOrdinal(this.scheme())

    // If Group Specified for Datum with Color - Color Overrides
    let self = this
    this.scale = function(datum: any){

        let value = datum[self.axis.param]
        // if(!value && value != 0.0){
            
        //     throw new Error('Datum Does Not Have Parameter ' + self.axis.param)
        // }

        if(datum.Group && datum.Group.color){
            return datum.Group.color
        }

        // Determine if we already set color for the value of the param.
        if(self.axis.tracked[self.axis.param] && self.axis.tracked[self.axis.param][datum.Name]){
            let color = self.axis.tracked[self.axis.param][datum.Name]
            return color
        }
        else{
            let color = self.highlightedScale(value)
            self.axis.tracked[self.axis.param][datum.Name] = color
            return color
        }
    }
}

const ContinuousColorGenerator: any = function(this: any, axis: any, values: any): void{
    this.values = values
    this.axis = axis
    this.lowerClamp = 0.2 // Anything Between This Point (Positive and Negative) is Clamped
    this.upperClamp = 0.8 // Only Used for Gray Scale Now

    // Creating Symmetrical Domain Will Cause Super Light Shades - Want to Truncate Values Past Certain Point
    //this.domain = this.values.SymmetricalDomain()
    this.domain = values
    this.getHighlightedColor = function(value: any){
        if(value < 0.0){
            return Interpolators.NegativeHalfColor(this.domain, this.lowerClamp).interpolate(value)
        }
        return Interpolators.PositiveHalfColor(this.domain, this.lowerClamp).interpolate(value)
    }

    let self = this
    // If Group Specified for Datum with Color - Color Overrides
    this.scale = function(datum: any){
        let value = datum[self.axis.param]
        // if(!value && value != 0.0){
        //     throw new Error('Datum Does Not Have Parameter ' + self.axis.param)
        // }

        if(datum.Group && datum.Group.color){
            return datum.Group.color
        }
        return self.getHighlightedColor(value)
    }
}

export default {
    DiscreteColorGenerator,
    ContinuousColorGenerator
}
