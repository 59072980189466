import Icon from "@ant-design/icons";
import { toggleExpandedFavoritesSidebar } from "_sirius/src/actions/general_actions";
import { Button, Radio, Tag } from "antd";
import { RangerChat } from "Components/RangerChat";
import FavoritesList from "Components/FavoritesPanel/FavoritesList";
import InvestmentSetForm from "Components/InvestmentSetForm";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { heightValue } from "Selectors/header_selectors";
import * as types from "_graphql-types/graphql";
import { styled } from "@mui/material/styles";
import { useDebounce } from "Helpers/hooks";

const StyledListHeaderDiv = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexGrow: 1,
});

enum PannelEnum {
  FavoritesList = "FavoritesList",
  RangerChat = "RangerChat",
}

function SideBar() {
  const params = useParams();
  const [isFocused, setIsFocused] = useState(false);

  const [entityId, entityType] = useMemo(() => {
    if (params.investmentId) {
      return [Number(params.investmentId), types.ChatEntityType.Investment];
    } else if (params.companyId) {
      return [Number(params.companyId), types.ChatEntityType.Company];
    } else if (params.firmId) {
      return [Number(params.firmId), types.ChatEntityType.Firm];
    }
    return [undefined, undefined];
  }, [params]);

  const dispatch = useDispatch();
  const { investmentId, companyId, firmId } = useParams();
  const navBarHeight = useSelector(heightValue);
  const favoritesSidebarExpanded = useSelector(
    (state: any) => state.app.favoritesSidebarExpanded
  );
  const [action, setAction] = useState(false);
  const caretDirection = favoritesSidebarExpanded ? "right" : "left";
  const panelState = favoritesSidebarExpanded ? "shown" : "hidden";

  const [panelSize, setPanelSize] = useState("small");

  const [currentPannel, setCurrentPannel] = useState<PannelEnum>(
    entityId ? PannelEnum.RangerChat : PannelEnum.FavoritesList
  );

  const pannelI = Object.values(PannelEnum).indexOf(currentPannel);

  if (favoritesSidebarExpanded) {
    document.body.classList.add("favorites-panel-shown");
  } else {
    document.body.classList.remove("favorites-panel-shown");
  }

  const Pannels = useMemo<
    {
      key: PannelEnum;
      title: string | JSX.Element;
      body: ({
        buttonAction,
        setButtonAction,
      }: {
        buttonAction?: boolean;
        setButtonAction?: (value: boolean) => void;
      }) => JSX.Element;
      button?: { iconClass: string };
      exclude?: boolean;
      expandOnFocus?: boolean;
    }[]
  >(
    () =>
      [
        {
          key: PannelEnum.FavoritesList,
          title: "Lists",
          body: ({
            buttonAction,
            setButtonAction,
          }: {
            buttonAction?: boolean;
            setButtonAction?: (value: boolean) => void;
          }) => {
            return (
              <>
                <FavoritesList />
                {buttonAction && (
                  <InvestmentSetForm
                    handleCancel={() => {
                      setButtonAction && setButtonAction(false);
                    }}
                    visible={!!buttonAction}
                  />
                )}
              </>
            );
          },
          button: {
            iconClass: "icon icon-plus",
          },
        },
        {
          key: PannelEnum.RangerChat,
          title: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <span style={{ fontSize: "14px" }}>Ranger</span>
              </div>
              <img
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "5px",
                  marginRight: "8px",
                }}
                src="/assets/images/docchatIcon.png"
                alt="Ranger"
              />
            </div>
          ),
          expandOnFocus: true,
          body: () => {
            return <RangerChat />;
          },
          exclude: !investmentId && !companyId && !firmId,
        },
      ].filter(({ exclude }) => !exclude),
    [investmentId, companyId, firmId, setPanelSize]
  );

  const current = Pannels.find(({ key }) => key === currentPannel);

  // defer action to not conflict with panel change events occurring at the same time as focus event
  const debouncedFocus = useDebounce(isFocused, 50);

  useEffect(() => {
    if (favoritesSidebarExpanded) {
      if (isFocused) {
        if (current?.expandOnFocus) {
          setPanelSize("large");
        } else {
          setPanelSize("small");
        }
      } else {
        setPanelSize("small");
      }
    }
  }, [debouncedFocus, favoritesSidebarExpanded, setPanelSize, current]);

  return (
    <div
      id="main-sidebar"
      data-cy="main-sidebar"
      className={`main-sidebar ml-10 main-sidebar--${panelState} ${
        panelSize === "large" ? "main-sidebar__large" : ""
      }`}
      style={{
        height: `calc(100% - ${navBarHeight}px)`,
        top: `${navBarHeight}px`,
      }}
      tabIndex={0}
      onFocus={e => {
        setIsFocused(true);
      }}
      onBlur={e => {
        setIsFocused(false);
      }}
    >
      <div className="main-sidebar__header ">
        <button
          className="main-sidebar__toggle-btn"
          onClick={e => {
            dispatch(toggleExpandedFavoritesSidebar());
          }}
          type="button"
        >
          <i className={`fa fa-caret-${caretDirection}`} aria-hidden="true" />
        </button>

        <div className="main-sidebar__tabs">
          <div
            id="lists"
            data-tab="lists"
            className="main-sidebar__tabs-tab--active main-sidebar__tabs-tab d-flex justify-between mb-0"
          >
            <StyledListHeaderDiv>
              {Pannels.length > 1 && (
                <Radio.Group
                  value={currentPannel}
                  onChange={e => {
                    setCurrentPannel(e.target.value);
                  }}
                >
                  {Pannels.map(({ key, title }) => (
                    <Radio.Button
                      key={key}
                      value={key}
                      data-cy={"sidebar-tab-" + key}
                    >
                      {title}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
              {Pannels.length === 1 && <h4>{current?.title}</h4>}
            </StyledListHeaderDiv>

            {current?.button && (
              <Button
                onClick={() => {
                  setAction(true);
                }}
                className="ml-auto"
                size="small"
                type="primary"
                shape="circle"
                icon={
                  <Icon
                    component={() => (
                      <i className={current?.button?.iconClass} />
                    )}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      {current?.body({ buttonAction: action, setButtonAction: setAction })}
    </div>
  );
}

export default SideBar;
