import AxesParameters from "./axes/params";
import DataModel from "./models/data";
import ContextMenu from "./elements/context-menu";
import Dimension from "./utilities/dimension";
import Constants from "./constants";
import Utilities from "./utilities/utilities";
import Framer from "./frames";
import _ from "lodash";
import * as d3 from "d3";
import { ColorControl, XControl, YControl } from "./elements/controls";
import { FundList } from "./elements/fund_list";
import Timeline from "./elements/timeline";

abstract class ChartState extends DataModel {
  public activeChartType: any;
  public animation: any;
  public dateLabel: any;
  public params: any;
  public chartType: any;
  public controls: any;
  public dimensions: any;
  public ids: any;
  public menu: any;
  public fundList: any;
  public timeline: any;
  public currentData: any;
  public left: any;
  public right: any;
  public rightMenu: any;
  public timeCursor_: any;
  public xAxisControl: any;
  public yAxisControl: any;
  public playing: any;
  public axes: any;
  public axis: any;
  public parent: any;
  public chartArea: any;
  public svg: any;
  public chart: any;

  constructor(raw: any, settings: any, activeChartType: any) {
    super(raw, settings);
    this.activeChartType = activeChartType;

    this.playing = false;
    this.timeCursor_ = null;
    this.animation = null;

    let self = this;
    let defaults = self.defaultParams;

    _.each(AxesParameters.params, function (dim: any) {
      if (self.settings.chart.params[dim]) {
        defaults[dim] = self.settings.chart.params[dim];
      }
    });
    this.params = new AxesParameters(this, defaults);
  }

  abstract get defaultParams(): any;
  abstract get controlDimensions(): any;

  // Dictates Whether or Not Animation is Playing, Paused, Finished or Ready
  get state() {
    if (this.playing) return "playing";
    if (this.timeCursor == this.startDate) return "ready";
    if (this.timeCursor == this.endDate) return "finished";
    return "paused";
  }
  // Called from Context Menu - Shows All Bubbles
  showAll() {
    let self = this;
    _.each(_.keys(this.hidden), function (key: any) {
      self.hidden[key] = false;
    });
    // Rerender Fundlist to Show Update
    if (this.settings.fundlist.enabled) {
      this.fundList.color();
    }
  }
  update(options?: any) {
    throw new Error("Update Method Not Implemented");
  }
  hide(point: any, from?: any) {
    if (this.hidden[point.Name])
      throw new Error("Cannot Hide Already Hidden Point");

    this.hidden[point.Name] = true;
    this.selected[point.Name] = false;

    if (this.settings.fundlist.enabled) {
      this.fundList.color();

      let fromFundList =
        from &&
        from.from &&
        from.from.__name__ &&
        from.from.__name__ != "FundList";
      if (!fromFundList) {
        this.fundList.hide(point);
      }
    }
  }
  show(point: any, from?: any) {
    if (!this.hidden[point.Name])
      throw new Error("Cannot Show Already Non Hidden Point");

    this.hidden[point.Name] = false;
    this.selected[point.Name] = false; // Default Selected to False

    if (this.settings.fundlist.enabled) {
      this.fundList.color();

      let fromFundList =
        from &&
        from.from &&
        from.from.__name__ &&
        from.from.__name__ != "FundList";
      if (!fromFundList) {
        this.fundList.show(point); // Move Back Down
      }
    }
  }
  focus(point: any, from: any) {
    if (this.focused == point.Name) return;
    this.focused = point.Name;

    if (this.settings.fundlist.enabled) {
      this.fundList.color();

      let fromFundList =
        from &&
        from.from &&
        from.from.__name__ &&
        from.from.__name__ != "FundList";
      if (!fromFundList) {
        this.fundList.focus(point); // Move Back Down
      }
    }
  }
  unfocus(point: any, from: any) {
    if (this.focused != point.Name) {
      console.log("Warning: Inconsistent UnFocused State Stored for Fund");
      return;
    }
    this.focused = null;

    if (this.settings.fundlist.enabled) {
      this.fundList.color();

      let fromFundList =
        from &&
        from.from &&
        from.from.__name__ &&
        from.from.__name__ != "FundList";
      if (!fromFundList) {
        this.fundList.unfocus(point); // Move Back Down
      }
    }
  }
  selectPoint(point: any, from: any) {
    if (this.selected[point.Name]) {
      console.log("Warning:  Inconsistent Selected State Stored for Fund");
      return;
    }
    this.selected[point.Name] = true;
    if (this.settings.fundlist.enabled) {
      this.fundList.color();

      let fromFundList =
        from &&
        from.from &&
        from.from.__name__ &&
        from.from.__name__ != "FundList";
      if (!fromFundList) {
        this.fundList.selectPoint(point); // Move Back Down
      }
    }
  }
  deselectPoint(point: any, from: any) {
    if (!this.selected[point.Name]) {
      console.log("Warning: Inconsistent Selected State Stored for Fund");
      return;
    }
    this.selected[point.Name] = false;
    if (this.settings.fundlist.enabled) {
      this.fundList.color();

      let fromFundList =
        from &&
        from.from &&
        from.from.__name__ &&
        from.from.__name__ != "FundList";
      if (!fromFundList) {
        this.fundList.deselectPoint(point); // Move Back Down
      }
    }
  }
  // Time State Handling
  set timeCursor(date) {
    if (!date || date === undefined)
      throw new Error("Cannot Set Time Cursor with Invalid Date");

    this.timeCursor_ = date;
    let label = Utilities.Format.stringify_date(this.timeCursor);
    this.dateLabel.text(label);
  }
  get timeCursor() {
    return this.timeCursor_;
  }

  // Controls What Happens Between Animations of Time Animation
  // Dont Want to Animate the Render - Adjust Time Cursor Manually
  tweenDate() {
    let self = this;

    let timeInterpolator = d3.interpolateNumber(this.timeCursor, this.endDate);
    function setTimeCursor(date: any) {
      // Dont Want to Animate the Render - Adjust Time Cursor Manually to Prevent Auto Rendering
      self.timeCursor_ = date;
      let label = Utilities.Format.stringify_date(self.timeCursor);
      self.dateLabel.text(label);

      self.timeline.render();
      self.update({ animate: false }); // Dont Use Animation on Timeline Animation Play
    }
    return function (t: any) {
      return setTimeCursor(timeInterpolator(t));
    };
  }

  // Called When Timeslider Dragged to Specific Point
  // To Do: Might Have to Use Slider Position Instead of Mouse for Certain Situations
  dragged(event: DragEvent) {
    if (this.state == "playing") this.interrupt();

    let posX = event.x - Constants.Timeline.slider.width / 2;
    
    let scaledD = this.axes.time.scale.invert(posX);
    this.timeCursor = scaledD; // Rerenders on Set

    this.timeline.render();
    this.update({ animate: false }); // Render with Animation

    return;
  }
  // Called When Overlay Clicked
  clicked(event: PointerEvent) {
    if (this.state == "playing") {
      this.interrupt();
    }

    // Calculate the x-coordinate relative to the element
    const posX = event.offsetX - Constants.Timeline.button.width + Constants.Timeline.slider.width / 2;

    let scaledD = this.axes.time.scale.invert(posX);
    this.timeCursor = scaledD;

    this.timeline.render();
    this.update({ animate: false }); // Render with Animation
  }
  interrupt() {
    this.playing = false;
    this.timeline.transition().duration(0);
  }
  pause() {
    this.interrupt();
    this.timeline.render();
  }
  finished() {
    this.playing = false;
    this.timeline.render();
  }
  // Sets the Time Cursor to Start Date and Causes Rerender
  restart() {
    this.timeCursor = this.startDate;
    this.update({ animate: false });
  }
  // Called When Play/Pause Button Clicked in Timeline
  toggleAnimation() {
    switch (this.state) {
      case "ready":
        this.play();
        break;
      case "paused":
        this.play();
        break;
      case "playing":
        this.pause();
        break;
      case "finished":
        this.restart();
        this.play();
        break;
    }
  }
  play() {
    let timeRatioRemaining =
      (this.endDate - this.timeCursor) / (this.endDate - this.startDate);
    this.playing = true;
    this.timeline
      .transition()
      .duration(this.settings.chart.playDuration * timeRatioRemaining)
      .ease(d3.easeLinear)
      .tween(Constants.Timeline.granularity, _.bind(this.tweenDate, this));
  }
}

abstract class BaseChart extends ChartState {
  public containerId: string;
  constructor(
    raw: any,
    containerId: string,
    settings: any,
    chartType: any,
    activeChartType: any
  ) {
    super(raw, settings, activeChartType);
    this.settings.validate(this); // Validate Data Model

    this.chartType = chartType;
    this.containerId = containerId;
    this.currentData = null;

    this.controls = {};
    this.dimensions = { svg: new Dimension() };

    // IDs Needed for Convenience in Date Label, Required for Elements That Have Scrolling Areas to Reference Mouse Pos
    this.ids = {
      chartContainer: _.uniqueId("chart-container-"),
      dateLabel: _.uniqueId("date-label-"),
      fundlist: _.uniqueId("fund-list-"),
      chart: _.uniqueId("chart-"),
      chartArea: _.uniqueId("chart-area-"),
    };

    let self = this;
    this.menu = [
      {
        title: "Show All",
        action: function (elm: any, d: any, i: any) {
          self.showAll();
        },
        disabled: false,
      },
    ];
  }

  closeAll() {
    let self = this;
    _.each(this.controlDimensions, function (dim: any) {
      self.controls[dim].close();
    });
  }

  get controlNodes() {
    return undefined;
  }

  renderControls() {
    if (_.includes(this.controlDimensions, "color")) {
      let colorNodes = _.find(this.controlNodes, { id: "color" });
      let colorPos = { x: 2.0, y: 18.0 }; // Y Position Accounts for Text
      let dim = {
        width: this.rightMenu.width - 4.0,
        height: Constants.Dropdown.height,
      };

      this.rightMenu
        .append("g")
        .append("text")
        .text("Color")
        .attr("class", "menu-label")
        .attr("alignment-baseline", "middle")
        .attr("x", 2.0) // 2.0 is X Offset of Select Rect, Then Add Width of Select Rect and Right Margin of Select Rect
        .attr("y", 8.0);

      this.controls["color"] = ColorControl.bind(this.rightMenu.append("g"))(
        colorNodes,
        this,
        colorPos,
        dim
      );
      this.controls["color"].initialize();
    }

    // TO DO: Handle Situation in which X or Y Only Present (Width Would Cause Issues)
    if (_.includes(this.controlDimensions, "x")) {
      let xNodes = _.find(this.controlNodes, { id: "x" });
      let xPos = { x: 0.0, y: 0.0 };
      let dim = {
        width: this.xAxisControl.width,
        height: Constants.Dropdown.height,
      };

      this.controls["x"] = XControl.bind(this.xAxisControl.append("g"))(
        xNodes,
        this,
        xPos,
        dim
      );
      this.controls["x"].initialize();
    }

    if (_.includes(this.controlDimensions, "y")) {
      let yNodes = _.find(this.controlNodes, { id: "y" });
      let yPos = { x: 0.0, y: 2.0 };
      let dim = {
        width: Constants.Dropdown.height,
        height: this.yAxisControl.height as number,
      };

      this.controls["y"] = YControl.bind(this.yAxisControl.append("g"))(
        yNodes,
        this,
        yPos,
        dim
      );
      this.controls["y"].initialize();
    }
  }

  drawBoundaries() {
    Framer.bind(this.parent)(this).DrawBoundaries("Parent");
    Framer.bind(this.left)(this).DrawBoundaries("Left");
    Framer.bind(this.chartArea)(this).DrawBoundaries("ChartArea");
    Framer.bind(this.chart)(this).DrawBoundaries("Chart");
    Framer.bind(this.timeline)(this).DrawBoundaries("Timeline");

    if (this.right) Framer.bind(this.right)(this).DrawBoundaries("Right");
    if (this.rightMenu)
      Framer.bind(this.rightMenu)(this).DrawBoundaries("RightMenu");
    if (this.fundList)
      Framer.bind(this.fundList)(this).DrawBoundaries("FundList");

    if (this.xAxisControl)
      Framer.bind(this.xAxisControl)(this).DrawBoundaries("XAxisControl");
    if (this.yAxisControl)
      Framer.bind(this.yAxisControl)(this).DrawBoundaries("YAxisControl");
  }

  // Creates and Lays Out Chat Components in Different Components of Chart
  afterInitialize() {
    let self = this;

    // rewrite occurred here to not use Jquery on port.
    // Set margin-left attribute
    const container = document.getElementById(self.containerId);

    if (!container)
      throw new Error("Error: Must Specify Valid Container for Chart");
    container.setAttribute("margin-left", "0");

    // Create and append the chart container
    const chartContainer = document.createElement("div");
    chartContainer.id = self.ids.chartContainer;
    container.appendChild(chartContainer);

    // Determine Dimensions of SVG and Right/Left Panels
    self.dimensions.svg.y1(self.settings.chart.height);
    self.dimensions.svg.x1(self.settings.chart.width);

    self.svg = d3
      .select("#" + self.ids.chartContainer)
      .append("svg")
      .attr("width", self.dimensions.svg.width)
      .attr("height", self.dimensions.svg.height)
      .attr("class", "topg")
      .attr("id", self.ids.chartContainer);

    self.parent = self.svg.append("g");
    Framer.bind(self.parent)(self).Frame("Parent");

    self.left = self.parent.append("g");
    Framer.bind(self.left)(self).Frame("Left");

    self.right = null;
    this.fundList = null;
    this.rightMenu = null;
    if (
      self.settings.fundlist.enabled ||
      _.includes(self.controlDimensions, "z") ||
      _.includes(self.controlDimensions, "color")
    ) {
      self.right = self.parent.append("g");
      Framer.bind(self.right)(self).Frame("Right");

      if (self.settings.fundlist.enabled) {
        self.fundList = FundList.bind(self.right.append("g"))(self).attr(
          "id",
          self.ids.fundlist
        );
        Framer.bind(self.fundList)(self).Frame("FundList");
      }
    }

    self.chartArea = self.left.append("g").attr("id", self.ids.chartArea);
    Framer.bind(self.chartArea)(self).Frame("ChartArea");

    self.chart = self.chartArea.append("g").attr("id", self.ids.chart);
    Framer.bind(self.chart)(self).Frame("Chart");

    self.timeline = Timeline.bind(self.left.append("g"))(self);
    Framer.bind(self.timeline)(self).Frame("Timeline");

    self.xAxisControl = null;
    if (_.includes(self.controlDimensions, "x")) {
      self.xAxisControl = self.chartArea.append("g");
      Framer.bind(self.xAxisControl)(self).Frame("XAxisControl");
    }

    self.yAxisControl = null;
    if (_.includes(self.controlDimensions, "y")) {
      self.yAxisControl = self.chartArea.append("g");
      Framer.bind(self.yAxisControl)(self).Frame("YAxisControl");
    }

    if (
      _.includes(self.controlDimensions, "z") ||
      _.includes(self.controlDimensions, "color")
    ) {
      self.rightMenu = self.right.append("g");

      Framer.bind(self.rightMenu)(self).Frame("RightMenu");
    }

    // Add Data Label - Value Set on Transition
    this.dateLabel = this.chart
      .append("text")
      .attr("class", "date label")
      .attr("id", this.ids.dateLabel)
      .attr("text-anchor", "end")
      .attr("y", this.chart.height - Constants.Timeline.dateLabel.offset.y)
      .attr("x", this.chart.width - Constants.Timeline.dateLabel.offset.x);

    if (self.settings.chart.contextMenu) {
      self.svg.on("contextmenu", ContextMenu(this.menu));
    }
  }
}

export default BaseChart;
