import Base from './base_chart'
import Settings from './settings/settings'
import _ from 'lodash'
import * as d3 from 'd3'
import ControlNodes from './controls'
import { BubbleAxes } from './axes/axes'
import { ZControl } from './elements/controls'
import Constants from './constants'

// Regular Bubble Chart Has No Controls - Just Plots The Data
class BubbleChart extends Base {
    public startPoint: any
    public endPoint: any
    public draggableRect: any
    public bubbles: any
    public initialized?: boolean
    public clickableOverlay?: any


    constructor(raw: any, containerId: string, settings: any){
        let activeChartType = 'BubbleChart' // Default 
        let chartType = 'BubbleChart'
        let settingsObj = new Settings.BubbleChartSettings(settings) 
        super(raw, containerId, settingsObj, chartType, activeChartType)
    }

    overlayClicked(){
        console.log('clicked')

    }
    overlayDragged(event: DragEvent){
        
        const chartElement = document.getElementById(this.ids.chart);
        if (!chartElement) {
            throw new Error('Error: Could not find chart element');
        }
        
        if(!this.startPoint){
            this.startPoint = d3.pointer(event, chartElement)
            this.startPoint = {x : this.startPoint[0], y : this.startPoint[1]}
        }

        this.endPoint = d3.pointer(event, chartElement)
        this.endPoint = {x : this.endPoint[0], y : this.endPoint[1]}

        let x = Math.min(this.startPoint.x, this.endPoint.x)
        let y = Math.min(this.endPoint.y, this.startPoint.y)
        // let x = this.startPoint.x
        // if(this.endPoint.x < this.startPoint.x){
        //     x = this.endPoint.x 
        // }

        // let y = this.startPoint.y
        // if(this.endPoint.y < this.startPoint.y){
        //     y = this.endPoint.y
        // }

        if(!this.draggableRect){
            this.draggableRect = this.chart.append('rect')
                .attr('class','draggable-rect')
                .attr('x',x)
                .attr('y',y)
                .attr('height',Math.abs(this.endPoint.y - this.startPoint.y))
                .attr('width',Math.abs(this.endPoint.x - this.startPoint.x))
        }
        else{
            this.draggableRect.transition().ease(d3.easeLinear).duration(20)
                .attr('height',Math.abs(this.endPoint.y - this.startPoint.y))
                .attr('width',Math.abs(this.endPoint.x - this.startPoint.x))
        }
        
    }
    // Loads Data and Sets Base Chart Parameters
    afterInitialize(){  
        let self = this
        super.afterInitialize()

        // Have to Set Time Cursor Before Rendering Axes and Timeline
        self.timeCursor = self.startDate 
        self.axes = BubbleAxes.bind(self.chart.append('g'))(self)

        // Timeline Has to Come After Axes
        self.timeline.draw() 
        self.timeline.render()

        self.currentData = self.points({date : self.timeCursor}) // Returns As List of Data Points - Points Do Not Include Hidden Points
        _.sortBy(self.currentData, 'Name');

        let points = self.points() // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
        self.axes.initialize(points)

        // Must Wait Until We Have Data
        if(self.settings.fundlist.enabled){
            self.fundList.initialize(self.currentData)
        } 

        self.bubbles = self.chart.append('g').Bubbles(self) 
        self.bubbles.animate(self.currentData)

        self.renderControls()
        self.drawBoundaries()
        self.initialized = true

        this.clickableOverlay = self.chart.append('g')
            .append('rect')
            .attr('class','clickable-overlay')
            .attr('x', 0.0)
            .attr('y', 0.0)
            .attr('width', self.chart.width)
            .attr('height', self.chart.height)

        self.clickableOverlay.on('click', _.bind(self.overlayClicked, self))
        self.clickableOverlay.call(d3.drag().on("drag", _.bind(self.overlayDragged, self)))
    }

    renderControls(){
        if(_.includes(this.controlDimensions, 'z')){
            let zNodes = _.find(this.controlNodes, {'id' : 'z'}) 
            let zPos = {'x' : 2.0, 'y' : 18.0 + 50.0} // Y Position Accounts for Text
            let dim = {'width' : this.rightMenu.width - 4.0, 'height' : Constants.Dropdown.height}

            this.rightMenu.append('g').append('text')
                .text('Size')
                .attr('class','menu-label')
                .attr('alignment-baseline','middle')
                .attr('x', 2.0) // 2.0 is X Offset of Select Rect, Then Add Width of Select Rect and Right Margin of Select Rect
                .attr('y', 8.0 + 50.0);

            this.controls['z'] = ZControl.bind(this.rightMenu.append('g'))(zNodes, this, zPos, dim)
            this.controls['z'].initialize()
        }
        super.renderControls()
    }

    // Setting Chart Param Automatically Updates Axis
    dimensionChanged(dimension: any, param: any){
        if(!this.settings.controls.enabled) throw new Error('Error: Should Not Be Able to Update Dimension Param When Controls Disabled')
        this.params[dimension] = param 
        let points = this.points() // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
        this.axes.update(points)
        this.bubbles.animate(this.currentData) 
    }

    hide(point: any){
        super.hide(point)
        this.currentData = this.points({date : this.timeCursor}) // Maintain Time Filtered Points for Non Hidden Markers

        let points = this.points() // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
        this.axes.update(points)
        this.bubbles.animate(this.currentData) // Default Behavior for Hide/Show
    }
    // Called from Context Menu - Shows All Bubbles
    showAll(){
        super.showAll()
        this.currentData = this.points({date : this.timeCursor}) 

        let points = this.points() // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
        this.axes.update(points)
        this.bubbles.animate(this.currentData) // Default Behavior for Hide/Show
    }

    show(point: any){
        super.show(point)
        this.currentData = this.points({date : this.timeCursor}) 

        let points = this.points() // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
        this.axes.update(points)
        this.bubbles.animate(this.currentData) // Default Behavior for Hide/Show
    }

    // Update Bubble Positions and Fund List - Do Not Update Axes - Animate Optionally
    update(options: any){
        this.currentData = this.points({date : this.timeCursor}) // Returns As List of Data Points - Points Do Not Include Hidden Points
    
        if(options && options.animate) this.bubbles.animate(this.currentData)
        else this.bubbles.draw(this.currentData) // Default Behavior

        // Updating With Filtered Points by Date (i.e. Current Data) Will Causes Axes to Rescale and Look Funny on Animation
        let points = this.points() 
        this.axes.update(points)
        
        if(this.settings.fundlist.enabled){
            this.fundList.renderItems(this.currentData)
        }
        if(this.endDate == this.timeCursor){
            this.finished()
        }
    }

    focus(point: any, from?: any){
        super.focus(point, from)
        this.bubbles.focus(point) // Will Also Clear All Focuses From Other Bubbles
    }
    unfocus(point: any, from?: any){
        super.unfocus(point, from)
        this.bubbles.unfocus(point)
    }
    selectPoint(point: any, from?: any){
        super.selectPoint(point, from)
        this.bubbles.selectPoint(point)
    }
    deselectPoint(point: any, from?: any){
        super.deselectPoint(point, from)
        this.bubbles.deselectPoint(point)
    }

    get controlNodes(){
        let result = (new ControlNodes.BubbleControl(this, this.settings.controls.params)).nodes()
        if(this.settings.data.idEnabled){
            result.forEach((node: any) => {
                node.children = node.children.filter((child: any) => {
                    return child.label !== this.settings.data.idColumn;
                });
            });
        }
        return result;
    }

    get controlDimensions(){
        if(this.settings.controls.enabled){
            return this.settings.controls.params
        }
        return []
    }

    static allowedParam(chart: any, dimension: any, param: any){
        let fakeControls = new ControlNodes.BubbleControl(chart, [dimension])
        let children = fakeControls[dimension].children
        let ids = _.map(children, 'id')
        return _.includes(ids, param)
    }

    static defaultParams_(chart: any){
        if(chart.numeric.length < 2) throw new Error('Error: Bubble Chart Must Have At Least 2 Numeric Axes')
        let params = {'x':chart.numeric[0], 'y':chart.numeric[1], 'color':'Name', 'z':chart.numeric[2]}
        if(! params.z){
            params.z = 'same'
        }
        return params
    }
    get defaultParams(){
        return BubbleChart.defaultParams_(this)
    }

    static get allowedNodeParents(){
        let parents = ['x','y','z','color']
        return parents
    }
}

export default BubbleChart

