import _ from 'lodash'
import * as d3 from 'd3'
import Constants from '../constants'
import DropdownScroller from './dropdown-scroll'
import { ControlChild } from '../controls'

const DropdownControl = function(this: typeof d3.selection.prototype, node: any, chart: any, position: any, size: any){
    this.chart = chart
    this.position = position
    this.size = size

    this.data = node
    if(!_.includes(this.chart.controlDimensions,this.dimension)){
        throw new Error('Cannot Create Control for Non Controllable Dimension')
    }

    this.view = {}
    this.opened = false;
    this.disabled = false;
    this.enabled = true;

    // Item Scroll Area ID Referenced for Relative Scroll Position
    this.itemsScrollArea = _.uniqueId('control-item-scroll-area-')
    DropdownScroller.bind(this)(10)

    // Total Height of Items Area
    this.itemsHeight = this.maxItems * (Constants.Dropdown.item.height + Constants.Dropdown.item.spacing) + 2.0

    this.updateNode = function(node: any){
        this.data = node
    }
    this.toggle = function(){

        // Maybe Want to Close if Disabled and Open - Case of Toggling Chart When Menu Open
        if(this.disabled) return 
        if(this.opened) this.close()
        else this.open()
    }

    this.open = function(){
        //this.chart.closeAll()
        this.drawChildren()

        let scroll_range = [0.0, this.itemsHeight]
        let position = {x : this.size.width - Constants.Scroll.width + 0.5, y : 0.0}
        this.drawScroll(this.view.itemsGroup, scroll_range, position)

        this.opened = true;
    }

    this.close = function(){
        this.closeChildren()
        this.removeScroll()
        this.opened = false;
    }

    this.disable = function(){
        this.disabled = true
        this.enabled = false;
        this.view.dropdown.classed('disabled',true)
    }

    this.enable = function(){
        this.disabled = false
        this.enabled = true;
        this.view.dropdown.classed('disabled',false)
    }

    this.itemClicked = function(_event: PointerEvent, target: ControlChild){
        this.chart.dimensionChanged(this.dimension, target.id)
        this.showActive()
        this.close()
    }

    // Shows Active Param as Toggle Label
    this.showActive = function(){
        let self = this 
        this.view.activeParam.text(function(){
            return self.chart.params[self.dimension]
        })
    }

    this.drawChildren = function(){
        // Group to Hold Individual Items
        let self = this
        this.view.itemsGroup = this.append('g').attr('class','control-dropdown-items')
            .attr('id', this.itemsScrollArea)
            .attr('transform',function(){
                return 'translate(' + String(0.0) + ',' + String(self.size.height + 5.0) + ')'
            });

        this.selectAll('.control-dropdown-item').remove()
        this.view.items = this.view.itemsGroup.selectAll('.control-dropdown-item').data(this.visible);

        let enter = this.view.items.enter()
            .append('g')
            .attr('class','control-dropdown-item')
            .attr('transform',function(d: any, i: any){
                return 'translate(' + String(0.0) + ',' + String((Constants.Dropdown.item.height + Constants.Dropdown.item.spacing) * i) + ')'
            });
        
        self = this 
        this.view.itemRects = enter.append('rect')
            .attr('class','control-dropdown-item-rect')
            .attr('height', Constants.Dropdown.item.height)
            .attr('x', 0.0)
            .attr('y', 0.0)
            .attr('width', function(){
                return self.size.width - Constants.Scroll.width
            }); 

        this.view.itemNames = enter.append('text')
            .attr('class','control-dropdown-item-name')
            .attr('alignment-baseline','middle')
            .attr('x', 5.0) 
            .attr('y', 0.5 * self.size.height);
            
        this.view.itemNames.text(function(d: any){
            return d.label
        });
        this.view.items.exit().remove()
        this.view.itemRects.on("click", _.bind(this.itemClicked, this))
        this.view.itemNames.on("click", _.bind(this.itemClicked, this))
    }

    this.closeChildren = function(){
        this.selectAll('.control-dropdown-item').remove()
    }

    this.afterRender = function(){
        this.view.caret.on("click", _.bind(this.toggle, this))
        this.view.dropdown.on("click", _.bind(this.toggle, this))
        this.view.activeParam.on("click", _.bind(this.toggle, this))
        this.showActive()
    }

    this.render = function(){

        this.view.main = this.selectAll('.dropdown').data([this.data]);

        let enter = this.view.main.enter()
            .append('g')
            .attr('class','dropdown')
            
        this.view.dropdown = enter.append('rect')
            .attr('class','control-dropdown-dropdown')
            .attr('height', this.size.height)
            .attr('x', this.offsets.dropdown.x)
            .attr('y', this.offsets.dropdown.y)
            .attr('rx', Constants.Dropdown.rx)
            .attr('ry', Constants.Dropdown.ry)
            .attr('width', this.size.width); // To Do: Need to Come Up with Way to Set Width of Text Based on Width of Fund List Frame

        this.view.activeParam = enter.append('text')
            .attr('class','control-dropdown-name')
            .attr('alignment-baseline','middle')
            .attr('x', this.offsets.text.x) 
            .attr('y', this.offsets.text.y)
        
        this.view.caret = enter.append("svg:image")
            .attr('class','caret')
            .attr("xlink:href", Constants.Dropdown.caret.link) // TO DO: Make This a Reference from Constants
            .attr("height", Constants.Dropdown.caret.size.height)
            .attr("width", Constants.Dropdown.caret.size.width)
            .attr('y', this.offsets.caret.y)
            .attr('x', this.offsets.caret.x);

        this.afterRender()
    }
    return this
}   

export const ColorControl = function(this: typeof d3.selection.prototype, controlNodes: any, chart: any, position: any, size: any){
    this.__name__ == 'ColorControl'
    this.dimension = 'color'

    this.attr('class','color-control')

    DropdownControl.bind(this)(controlNodes, chart, position, size)
    this.attr('transform','translate(' + this.position.x + ',' + this.position.y + ')')

    this.itemsContainerWidth = this.size.width
    
    this.offsets = {
        dropdown : { x : 0.0, y : 0.0 },
        text : { x : 8.0, y : 0.5 * this.size.height},
        caret : { x : this.size.width - 20, y : 0.5 * this.size.height - 4.0}
    }

    this.initialize = function(){
        this.render()
    }

    return this
}

export const ZControl = function(this: typeof d3.selection.prototype, controlNodes: any, chart: any, position: any, size: any){
    this.__name__ == 'ZControl'
    this.dimension = 'z'
    this.label = 'Size'

    this.attr('class','z-control')

    DropdownControl.bind(this)(controlNodes, chart, position, size)
    this.attr('transform','translate(' + this.position.x + ',' + this.position.y + ')')

    this.itemsContainerWidth = this.size.width

    this.offsets = {
        dropdown : { x : 0.0, y : 0.0 },
        text : { x : 8.0, y : 0.5 * this.size.height}, 
        caret : { x : this.size.width - 20, y : 0.5 * this.size.height - 4.0}
    }

    this.initialize = function(){
        this.render()
    }

    return this
}

export const XControl = function(this: typeof d3.select.prototype, controlNodes: any, chart: any, position: any, size: any){
    this.__name__ == 'XControl'
    this.dimension = 'x'
    this.label = 'X Axis'

    this.attr('class','x-control')

    DropdownControl.bind(this)(controlNodes, chart, position, size)
    this.attr('transform','translate(' + this.position.x + ',' + this.position.y + ')')

    this.itemsContainerWidth = 300.0

    // Offsets Account for Relative Space Between Container and Dropdown Rect
    let margin = 0.5 * (this.chart.xAxisControl.height - this.size.height)
    this.offsets = {
        dropdown : { x : 0.0, y : margin},
        text : { x : 8.0, y : 0.5 * this.size.height + margin},
        caret : { x : this.size.width - 10, y : 0.5 * this.size.height + margin + 3.0}
    }
    if(this.offsets.dropdown.y < 0.0) throw new Error('Dropdown Area Height Must Exceed Dropdown Rect Height')

    this.open = function(){
        //this.chart.closeAll()

        this.drawChildren()
        let scroll_range = [0.0, this.itemsHeight]
        let position = {x : this.itemsContainerWidth - Constants.Scroll.width + 0.5, y : 0.0}
        this.drawScroll(this.view.itemsGroup, scroll_range, position)
        this.opened = true;
    }

    // Overrride for Vertical Position
    this.drawChildren = function(){
    
        // Group to Hold Individual Items
        let self = this
        this.view.itemsGroup = this.append('g').attr('class','control-dropdown-items')
            .attr('id', this.itemsScrollArea)
            .attr('transform',function(d: any, i: any){
                return 'translate(' + String(self.size.width - self.itemsContainerWidth) + ',' + String(- self.itemsHeight) + ')'
            });

        this.selectAll('.control-dropdown-item').remove()

        this.view.items = this.view.itemsGroup.selectAll('.control-dropdown-item').data(this.visible);

        let enter = this.view.items.enter()
            .append('g')
            .attr('class','control-dropdown-item')
            .attr('transform',function(d: any, i: any){
                return 'translate(' + String(0.0) + ',' + String((Constants.Dropdown.item.height + Constants.Dropdown.item.spacing) * i) + ')'
            });
        
        // For Y Control - Have to Manually Set Width of Items
        this.view.itemRects = enter.append('rect')
            .attr('class','control-dropdown-item-rect')
            .attr('height', Constants.Dropdown.item.height)
            .attr('x', 0.0)
            .attr('y', 0.0)
            .attr('width', function(d: any,i: any){
                return self.itemsContainerWidth - (Constants.Scroll.width) 
            }); 

        this.view.itemNames = enter.append('text')
            .attr('class','control-dropdown-item-name')
            .attr('alignment-baseline','middle')
            .attr('x', 5.0) 
            .attr('y', 0.5 * Constants.Dropdown.item.height);
            
        this.view.itemNames.text(function(d: any,i: any){
            return d.label
        });
        this.view.items.exit().remove()
        this.view.itemRects.on("click", _.bind(this.itemClicked, this))
        this.view.itemNames.on("click", _.bind(this.itemClicked, this))
    }

    // Override for Vertical Orientation
    this.render = function(){
        this.angle = 180
        this.main = this.selectAll('.dropdown').data([this.data]);
        let enter = this.main.enter()
            .append('g')
            .attr('class','dropdown')
            
        this.view.dropdown = enter.append('rect')
            .attr('class','control-dropdown-dropdown')
            .attr('height', this.size.height)
            .attr('x', this.offsets.dropdown.x)
            .attr('y', this.offsets.dropdown.y)
            .attr('rx', Constants.Dropdown.rx)
            .attr('ry', Constants.Dropdown.ry)
            .attr('width', this.size.width); // To Do: Need to Come Up with Way to Set Width of Text Based on Width of Fund List Frame

        this.view.activeParam = enter.append('text')
            .attr('class','control-dropdown-name')
            .attr('alignment-baseline','middle')
            .attr('x', this.offsets.text.x) 
            .attr('y', this.offsets.text.y)
        
        this.view.caret = enter.append("svg:image")
            .attr('class','caret')
            .attr("xlink:href", Constants.Dropdown.caret.link) // TO DO: Make This a Reference from Constants
            .attr("height", Constants.Dropdown.caret.size.height)
            .attr("width", Constants.Dropdown.caret.size.width)
            .attr("transform", "rotate("+-this.angle+")")
            .attr('y', -this.offsets.caret.y)
            .attr('x', -this.offsets.caret.x);

        this.afterRender()
    }

    this.initialize = function(){
        this.render()
    }

    return this
}

export const YControl = function(this: typeof d3.select.prototype, controlNodes: any, chart: any, position: any, size: any){
    this.__name__ == 'YControl'
    this.dimension = 'y'
    this.label = 'Y Axis'

    this.attr('class','y-control')

    DropdownControl.bind(this)(controlNodes, chart, position, size)
    this.attr('transform','translate(' + this.position.x + ',' + this.position.y + ')')

    this.itemsContainerWidth = 300.0
    this.angle = 90

    // Offsets Account for Relative Space Between Container and Dropdown Rect
    let margin = 0.5 * (this.chart.yAxisControl.width - this.size.width)

    this.offsets = {
        dropdown : { x : 0.5 * (this.chart.yAxisControl.width - this.size.width), y : 0.0},
        text : { x : 0.5 * this.size.height + 0.5 *(this.chart.yAxisControl.width - this.size.height), y : this.size.height - 8.0},
        caret : { y : 20, x : 0.5 * this.size.width + margin - 3.0}
    }
    if(this.offsets.dropdown.x < 0.0) throw new Error('Dropdown Area Height Must Exceed Dropdown Rect Height')

    this.open = function(){
        //this.chart.closeAll()

        this.drawChildren()
        let scroll_range = [0.0, this.itemsHeight]
        let position = {x : this.itemsContainerWidth - Constants.Scroll.width + 0.5, y : 0.0}
        this.drawScroll(this.view.itemsGroup, scroll_range, position)
        this.opened = true;
    }

    // Overrride for Vertical Position
    this.drawChildren = function(){

        let self = this
        this.view.itemsGroup = this.append('g').attr('class','control-dropdown-items')
            .attr('id', this.itemsScrollArea)
            .attr('transform',function(){
                return 'translate(' + String(self.size.width + 8.0) + ',' + String(1.0) + ')'
            });

        this.selectAll('.control-dropdown-item').remove()

        this.view.items = this.view.itemsGroup.selectAll('.control-dropdown-item').data(this.visible);

        let enter = this.view.items.enter()
            .append('g')
            .attr('class','control-dropdown-item')
            .attr('transform',function(d: any, i: any){
                return 'translate(' + String(0.0) + ',' + String((Constants.Dropdown.item.height + Constants.Dropdown.item.spacing) * i) + ')'
            });
        
        this.view.itemRects = enter.append('rect')
            .attr('class','control-dropdown-item-rect')
            .attr('height', Constants.Dropdown.item.height)
            .attr('x', 0.0)
            .attr('y', 0.0)
            .attr('width', function(d: any,i: any){
                return self.itemsContainerWidth - (Constants.Scroll.width) 
            }); 

        this.view.itemNames = enter.append('text')
            .attr('class','control-dropdown-item-name')
            .attr('alignment-baseline','middle')
            .attr('x', 5.0) 
            .attr('y', 0.5 * Constants.Dropdown.item.height);
            
        this.view.itemNames.text(function(d: any){
            return d.label
        });
        this.view.items.exit().remove()
        this.view.itemRects.on("click", _.bind(this.itemClicked, this))
        this.view.itemNames.on("click", _.bind(this.itemClicked, this))
    }

    // Override for Vertical Orientation
    this.render = function(){
        
        this.view.main = this.selectAll('.dropdown').data([this.data]);
        let enter = this.view.main.enter()
            .append('g')
            .attr('class','dropdown')
            
        this.view.dropdown = enter.append('rect')
            .attr('class','control-dropdown-dropdown')
            .attr('height', this.size.height)
            .attr('x', this.offsets.dropdown.x)
            .attr('y', this.offsets.dropdown.y)
            .attr('rx', 2.0)
            .attr('ry',2.0)
            .attr('width', this.size.width); // To Do: Need to Come Up with Way to Set Width of Text Based on Width of Fund List Frame

        this.view.activeParam = enter.append('text')
            .attr('class','control-dropdown-name')
            .attr('alignment-baseline','middle')
            .attr("transform", "rotate("+-90+")")
            .attr('y', this.offsets.text.x) 
            .attr('x', -this.offsets.text.y);
        
        this.view.caret = enter.append("svg:image")
            .attr('class','caret')
            .attr("xlink:href", Constants.Dropdown.caret.link) // TO DO: Make This a Reference from Constants
            .attr("height", Constants.Dropdown.caret.size.height)
            .attr("width", Constants.Dropdown.caret.size.width)
            .attr("transform", "rotate("+-this.angle+")")
            .attr('y', this.offsets.caret.x)
            .attr('x', -this.offsets.caret.y);

        this.afterRender()
    }

    this.initialize = function(){
        this.render()
    }

    return this
}