import ParentSettings from './parent'

// Overall Settings Object for Any Chart - Inherited by Specific Chart Settings Objects
class FundListSettingsObj extends ParentSettings {
    public enabled: any;
    public width: any;

    constructor(settings: any){
        super(settings)

        this.__name__ = 'FundList'

        this.enabled = true;
        this.width = 280;

        this.validation = {
            enabled : 'bool',
            width : 'numeric',
        }
    }
}
export default FundListSettingsObj