import { ActionType } from "../actions/action_types";

interface State {
  favoritesSidebarExpanded: boolean;
}

interface Action {
  type: ActionType.TOGGLE_EXPANDED_FAVORITES_SIDEBAR;
}

const getInitialState = (): State => {
  const storedState = localStorage.getItem("favoritesSidebarExpanded");
  if (storedState !== null) {
    return {
      favoritesSidebarExpanded: JSON.parse(storedState),
    };
  }

  // Default behavior based on window size if no localStorage value
  return {
    favoritesSidebarExpanded: window.innerWidth >= 720,
  };
};

const defaultState = Object.freeze(getInitialState());

function appReducer(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case ActionType.TOGGLE_EXPANDED_FAVORITES_SIDEBAR:
      const newState = {
        ...state,
        favoritesSidebarExpanded: !state.favoritesSidebarExpanded,
      };

      // Persist the updated state to localStorage
      localStorage.setItem(
        "favoritesSidebarExpanded",
        JSON.stringify(newState.favoritesSidebarExpanded)
      );

      return newState;
    default:
      return state;
  }
}

export default appReducer;
