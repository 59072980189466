import Coordinate from "../utilities/coordinate";
import * as d3 from "d3";
import _ from "lodash";
import { Connector } from "./connectors";
import { Bubble, BubbleBar, NegativityLabel } from "./marker";
import { BubbleBarLabel, BubbleLabel } from "./label";

export const MarkerGroup = function (this: typeof d3.selection.prototype) {
  this.disabled = {
    label: { hover: false, select: false },
    connector: { hover: false, select: false },
  };
  if (this.chart.settings.chart.unhover || this.chart.settings.chart.hover) {
    this.disabled.label.hover = true;
    this.disabled.connector.hover = true;
  }
  if (
    this.chart.settings.chart.unclicked ||
    this.chart.settings.chart.clicked
  ) {
    this.disabled.label.select = true;
    this.disabled.connector.select = true;
  }

  Object.defineProperty(this, "selected", {
    get: function selected() {
      return this.chart.selected[this.Name];
    },
  });
  Object.defineProperty(this, "focused", {
    get: function focused() {
      return this.chart.focused == this.Name;
    },
  });

  // If Hover Callback Parameter Set, Dont Show Marker and Label but Make Callback
  this.focus = function () {
    this.marker.focus();

    if (this.chart.settings.chart.hover) {
      this.chart.settings.chart.hover(this.datum().Name);
      return;
    }
    if (!this.disabled.label.hover) this.label.focus();
    if (!this.disabled.connector.hover) this.connector.focus();
  };
  this.unfocus = function () {
    this.marker.unfocus();

    if (this.chart.settings.chart.unhover) {
      this.chart.settings.chart.unhover(this.datum().Name);
      return;
    }
    if (!this.disabled.label.hover) this.label.unfocus();
    if (!this.disabled.connector.hover) this.connector.unfocus();
  };

  // If Clicked Callback Parameter Set, Dont Show Marker and Label but Make Callback
  this.select_ = function () {
    this.marker.select_();

    if (this.chart.settings.chart.clicked) {
      this.chart.settings.chart.clicked(this.datum().Name);
      return;
    }
    if (!this.disabled.label.select) this.label.select_();
    if (!this.disabled.connector.select) this.connect();
  };
  this.deselect = function () {
    this.marker.deselect();

    if (this.chart.settings.chart.unclicked) {
      this.chart.settings.chart.unclicked(this.datum().Name);
      return;
    }
    if (!this.disabled.label.select) this.label.deselect();
    if (!this.disabled.connector.select) this.connector.deselect();
  };
  // Draws Adjustment of Items
  this.draw = function (datum: any) {
    this.marker.draw(datum);
    if (this.negativityLabel) this.negativityLabel.draw(datum);
    if (this.selected || this.focused) {
      if (this.focused && !this.disabled.label.hover) this.label.draw(datum);
      else if (this.selected && !this.disabled.label.select)
        this.label.draw(datum);

      // This is Called During Play Animation If Already Connected - Want Connector to Follow Bubble
      if (this.selected && !this.disabled.connector.select) {
        this.follow();
      }
    }
  };
  // Animates Adjustment of Items
  this.animate = function (datum: any) {
    this.marker.animate(datum);
    if (this.negativityLabel) this.negativityLabel.animate(datum);
    if (this.selected || this.focused) {
      if (this.focused && !this.disabled.label.hover) this.label.animate(datum);
      else if (this.selected && !this.disabled.label.select)
        this.label.animate(datum);

      // This is Called During Play Animation If Already Connected - Want Connector to Follow Bubble
      if (this.selected && !this.disabled.connector.select) {
        this.follow({ animate: true });
      }
    }
  };
};

// The Only Reason That .follow, .connect and .point Methods Are Not in Parent Object
// Are Because When We Adjust the Origin Destination (i.e. Where Connector Starts) it Depends on Marker Type
// Follow: Connector Start Point Follows Bubble
// Point: Connector End Point Follows Label On Drag
// Connect: Initial Draw of Connector to Existing Label Location from Marker
// All Methods: Destination Adjusted First, Then Origin - Origin Adjustment Not Fine Tuned - Ignore For Now
export const BubbleGroupBehavior = function (
  this: typeof d3.selection.prototype
) {
  this.follow = function (options: any) {
    this.origin = this.marker.center;
    this.connector.follow(this.origin, options);
  };

  this.connect = function () {
    this.origin = this.marker.center;
    this.destination = this.label.center;

    this.destination.AdjustConnectorDestination(this.label, this.marker);
    this.connector.connect(this.origin, this.destination);
  };

  this.point = function (options: any) {
    if (!this.origin || !this.destination)
      throw new Error("Connector Must Already Be Set In Order to Repoint");
    this.destination.AdjustConnectorDestination(this.label, this.marker);
    this.connector.point(this.destination, options);
  };
};

// Origin for Bar Label Has to Adjust Based on Orientation of Bars
const BarGroupBehavior = function (this: typeof d3.selection.prototype) {
  this.follow = function (options: any) {
    if (this.marker.positive)
      this.origin = new Coordinate(this.marker.center.x, this.marker.y1);
    else this.origin = new Coordinate(this.marker.center.x, this.marker.y2);

    this.connector.follow(this.origin, options);
  };

  this.connect = function () {
    if (this.marker.positive)
      this.origin = new Coordinate(this.marker.center.x, this.marker.y1);
    else this.origin = new Coordinate(this.marker.center.x, this.marker.y2);

    this.destination = this.label.center;

    this.destination.AdjustConnectorDestination(this.label, this.marker);
    this.connector.connect(this.origin, this.destination);
  };

  this.point = function (options: any) {
    if (!this.origin || !this.destination)
      throw new Error("Connector Must Already Be Set In Order to Repoint");
    this.destination.AdjustConnectorDestination(this.label, this.marker);
    this.connector.point(this.destination, options);
  };
};

export const BubbleGroup = function (
  this: typeof d3.selection.prototype,
  datum: any,
  bubbles: any,
  chart: any
) {
  this.markers = bubbles;
  this.chart = chart;
  this.Name = datum.Name;

  this.marker = Bubble.bind(this.append("circle"))(
    this,
    this.markers,
    this.chart
  );
  this.label = BubbleLabel.bind(this.append("g"))(
    this,
    this.marker,
    this.markers,
    this.chart
  );
  this.negativityLabel = this.append("rect").NegativityLabel(
    this,
    this.markers,
    this.chart
  );
  MarkerGroup.bind(this)();

  this.origin = null;
  this.destination = null;
  BubbleGroupBehavior.bind(this)();

  // To Do: May Want to Pass In Markers to Connector As Well for Future Use
  this.connector = Connector.bind(this.append("g"))(
    this,
    this.marker,
    this.markers,
    this.chart
  );

  return this;
};

export const BarGroup = function (
  this: typeof d3.selection.prototype,
  datum: any,
  bars: any,
  chart: any
) {
  this.markers = bars;
  this.chart = chart;
  this.Name = datum.Name;

  this.marker = this.append("rect").Bar(this, this.markers, this.chart);
  this.label = this.append("g").BarLabel(
    this,
    this.marker,
    this.markers,
    this.chart
  );
  MarkerGroup.bind(this)();

  this.origin = null;
  this.destination = null;
  BarGroupBehavior.bind(this)();

  // To Do: May Want to Pass In Markers to Connector As Well for Future Use
  this.connector = Connector.bind(this.append("g"))(
    this,
    this.marker,
    this.markers,
    this.chart
  );

  return this;
};

export const BubbleBarGroup = function (
  this: typeof d3.selection.prototype,
  datum: any,
  bubbleBars: any,
  chart: any
) {
  this.markers = bubbleBars;
  this.chart = chart;
  this.Name = datum.Name;

  this.marker = BubbleBar.bind(this.append("rect"))(
    this,
    this.markers,
    this.chart
  );
  this.label = BubbleBarLabel.bind(this.append("g"))(
    this,
    this.marker,
    this.markers,
    this.chart
  );
  this.negativityLabel = NegativityLabel.bind(this.append("rect"))(
    this,
    this.markers,
    this.chart
  );

  MarkerGroup.bind(this)();

  switch (this.chart.activeChartType) {
    case "BubbleChart":
      BubbleGroupBehavior.bind(this)();
      break;
    case "BarChart":
      BarGroupBehavior.bind(this)();
      break;
  }

  this.toggle = function () {
    switch (this.chart.activeChartType) {
      case "BubbleChart":
        BubbleGroupBehavior.bind(this)();
        break;
      case "BarChart":
        BarGroupBehavior.bind(this)();
        break;
    }
    this.label.toggle();
    this.marker.toggle();
  };
  // To Do: May Want to Pass In Markers to Connector As Well for Future Use
  this.connector = Connector.bind(this.append("g"))(
    this,
    this.marker,
    this.markers,
    this.chart
  );

  return this;
};
