import { useQuery } from "@apollo/client";
import PeerCorrelationStats from "Components/show/overview/PeerCorrelationStats";
import PeerReturnStream from "Components/show/overview/PeerReturnsStream";
import PeerStatistics from "Components/show/overview/PeerStatistics";
import { gql } from "_graphql-types/gql";
import { Select as AntdSelect, Switch } from "antd";
import i18n from "i18next";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  investmentId: number;
}

const FETCH_INVESTMENT_SET_LIST = gql(`
  query getInvestmentSetList($query: String) {
    investmentSetList(filter: { q: $query }) {
      items {
        id
        name
      }
      total
    }
  }
`);

const { Option } = AntdSelect;

export function PeersTable({ investmentId }: Props): JSX.Element {
  const [tableToDisplay, setTableToDisplay] = useState<string>("stats");
  const [selectedSet, setSelectedSet] = useState<number | "PeersList">();
  const [includeBenchmarks, setIncludeBenchmarks] = useState<
    boolean | undefined
  >();

  const [query, setQuery] = useState<string>("");

  const debouncedSetQuery = useDebouncedCallback(setQuery, 500);

  const { data, loading: loadingInvestmentSet } = useQuery(
    FETCH_INVESTMENT_SET_LIST,
    {
      variables: {
        query,
      },
    }
  );

  const investmentSetList = data?.investmentSetList.items || [];

  const handleIncludeBenchmarksChange = (value: boolean) =>
    setIncludeBenchmarks(value);
  const handleTableChange = (value: string) => setTableToDisplay(value);
  const handleSetChange = (value: number | "PeersList") =>
    setSelectedSet(value);

  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <AntdSelect
          defaultValue="stats"
          style={{ width: 240 }}
          onChange={handleTableChange}
          data-cy="peer-table-select"
        >
          <Option value="stats">Peer Statistics</Option>
          <Option value="returnsStream">Peer Returns Stream</Option>
          <Option value="correlationStats">Correlation Stats</Option>
        </AntdSelect>
        {tableToDisplay === "stats" && (
          <div
            id="peer-statistics__includeBenchmarks flex-row"
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <Switch
              data-cy="peers__include-benchmarks-switch"
              checked={includeBenchmarks}
              onChange={handleIncludeBenchmarksChange}
            />
            <p className="summary-heading__desc" style={{ marginLeft: "5px" }}>
              {i18n.t("analytics.peer_statistics.include_benchmarks")}
            </p>
          </div>
        )}
      </div>
      <h4>Select List for comparison</h4>

      <AntdSelect
        showSearch
        onSearch={debouncedSetQuery}
        style={{ width: 240 }}
        filterOption={false}
        loading={loadingInvestmentSet}
        onChange={handleSetChange}
        data-cy="peer-list-select"
      >
        <Option value={"PeersList"} data-cy="list-option-peers">
          {"(Default)"} Peers List{" "}
        </Option>
        {investmentSetList.map((set, index) => (
          <Option value={set.id} data-cy={`list-option-${index}`} key={set.id}>
            {" "}
            {set.name}{" "}
          </Option>
        ))}
      </AntdSelect>
      {tableToDisplay === "stats" && (
        <PeerStatistics
          investmentId={investmentId}
          investmentSetId={
            selectedSet === "PeersList" ? undefined : selectedSet
          }
          includeBenchmarks={includeBenchmarks}
        />
      )}
      {tableToDisplay === "returnsStream" && (
        <PeerReturnStream
          investmentId={investmentId}
          investmentSetId={
            selectedSet === "PeersList" ? undefined : selectedSet
          }
        />
      )}
      {tableToDisplay === "correlationStats" && (
        <PeerCorrelationStats
          investmentId={investmentId}
          investmentSetId={
            selectedSet === "PeersList" ? undefined : selectedSet
          }
        />
      )}
    </>
  );
}
