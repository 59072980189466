import ParentSettings from './parent'
import ParamSettings from './params'
import Assertions from './assertions'
import _ from 'lodash'

const Group: any = function(this: any, group: any){
    this.color = null;
    this.name = null;
    this.data = [] // Array of Names

    if(group.color) this.color = group.color 
    if(group.name) this.name = group.name 
    this.data = group.data || []

    this.validate = function(model: any){
        const self = this 
        _.each(this.data, function(name: any){
            if(! _.includes(model.names, name)){
                throw new Error('Invalid Name Specified for Group ' + self.name)
            }
        })
    }

    this.contains = function(point: any){
        if(_.includes(this.data, point.Name)){
            return true 
        }
        return false
    }

    return this
}

class ChartSettingsObj extends ParentSettings {
    public height: any;
    public contextMenu: any;
    public width: any;
    public gridlines: any;
    public playDuration: any;
    public animationDuration: any;
    public hover: any;
    public clicked: any;
    public unhover: any;
    public unclicked: any;
    public groups: any;
    public params: any;
    public showBoundaries: any;

    constructor(settings: any){
        super(settings)

        this.grouped = ['params','highlight']

        this.height = 520
        this.contextMenu = true
        this.width= 950
        this.gridlines = true
        this.playDuration = 10000
        this.animationDuration = 250

        this.hover = null;
        this.clicked = null;
        this.unhover = null;
        this.unclicked = null;

        this.groups = []
        this.params = {}

        // Show Boundaries of Chart Area
        this.showBoundaries = false 

        this.validation = {
            playDuration : 'numeric',
            animationDuration : 'numeric',
            gridlines : 'bool',
            showBoundaries : 'bool',
            height : 'numeric', 
            width: 'numeric',
            contextMenu : 'bool',
            hover : 'function',
            clicked : 'function',
            unhover : 'function',
            unclicked : 'function',
            groups : 'array',
        }
    }
    validate(model: any){
        super.validate(model)
        _.each(this.groups, function(group: any){
            group.validate(model)
        })
        return
    }
    populate(){
        super.populate('groups')
        
        if(this.settings.groups){
            // Need to Validate Groups Before Trying to Populate
            Assertions.array('groups', this.settings.groups) // No Options - Blackbox
            const self = this 
            
            _.each(this.settings.groups, function(group: any, i){
                const groupObj = new Group(group)
                self.groups.push(groupObj)
            })
        }

        this.params = new ParamSettings.BubbleParamsSettings(this.settings.params || {})
        this.params.populate()
        return this
    }
}

export default ChartSettingsObj
