import Transition_Chart from "./script/transition_chart";
import Bubble_Chart from "./script/bubble_chart";
import Bar_Chart from "./script/bar_chart";
import "./static/charting.css"
import "./static/context-menu.css"
import "./static/controls.css"
import "./static/elements.css"
import "./static/fundlist.css"
import "./static/timeline.css"


const CHART_LOOKUP = {
  bar: "BarChart",
  bubble: "BubbleChart",
  transition: "TransitionChart",
};

interface VisualizationChartInterface {
  chartLookup: typeof CHART_LOOKUP;
  chart: any;
  chartType: keyof typeof CHART_LOOKUP;
  chartClass: (typeof CHART_LOOKUP)[keyof typeof CHART_LOOKUP];
  containerId: string;
  create: any;
  prepare: any;
  initialize: any;
  generate: (raw: any, settings: any) => any;
}

// get type of VisualizationsChart  returns on new

interface VisualizationsChartConstructor {
  new (
    containerId: string,
    chartType: keyof typeof CHART_LOOKUP,
    data: any,
    settings: any
  ): VisualizationChartInterface;
}

const VisualizationsChart: VisualizationsChartConstructor = function (
  this: VisualizationChartInterface,
  containerId: string,
  chartType: keyof typeof CHART_LOOKUP,
  data: any,
  settings: any
): void {
  this.chartLookup = CHART_LOOKUP;

  this.chartType = chartType;
  this.chart = null;

  this.containerId = containerId;
  if (!this.containerId)
    throw new Error("Error: Must Specify Valid Container for Chart");

  if (
    this.chartType.toLowerCase() != "bar" &&
    this.chartType.toLowerCase() != "transition" &&
    this.chartType.toLowerCase() != "bubble"
  ) {
    throw new Error("Error: Invalid Chart Type");
  }

  this.create = function (raw: any, settings: any) {
    this.prepare(raw, settings);
    this.initialize(raw, settings);
  };

  this.prepare = function (raw: any, settings: any) {
    this.chartClass = this.chartLookup[this.chartType];
    let self = this;
    switch (this.chartClass) {
      // To Do: Validate Container by Looking for Element
      case "BarChart":
        this.generate = function (raw: any, settings: any) {
          let chart = new Bar_Chart(raw, self.containerId, settings);
          return chart;
        };
        break;
      case "BubbleChart":
        this.generate = function (raw: any, settings: any) {
          let chart = new Bubble_Chart(raw, self.containerId, settings);
          return chart;
        };
        break;
      case "TransitionChart":
        this.generate = function (raw: any, settings: any) {
          let chart = new Transition_Chart(raw, self.containerId, settings);
          return chart;
        };
        break;
      default:
        throw new Error("Error: Invalid Chart Type");
        break;
    }
  };

  this.initialize = function (raw: any, settings: any) {
    // Validate Data After Settings Initialized
    if (!raw || !(raw instanceof Array)) {
      throw new Error("Error: Must Provide Data as Valid Array");
    }
    if (raw instanceof Array && raw.length == 0) {
      throw new Error("Error: Must Provide Non Empty Data Array");
    }
    this.chart = this.generate(raw, settings);
    this.chart.afterInitialize();
  };
  this.create(data, settings);
} as unknown as VisualizationsChartConstructor;

export default VisualizationsChart;
