import * as d3 from 'd3'

export const BarPosition = function(this: typeof d3.selection.prototype){
    Object.defineProperty(this, "cX", {
        configurable : true,
        get: function cX() {
            return this.x + 0.5 * this.width
        },
    });
    Object.defineProperty(this, "cY", {
        configurable : true,
        get: function cY() {
            return this.y1 + 0.5 * (this.y2 - this.y1)
        },
    });
    Object.defineProperty(this, "x", {
        configurable : true,
        get: function x() {
            let value = this.datum().Name
            return this.chart.axes.x.scale(value)
        },
    });
    Object.defineProperty(this, "y", {
        configurable : true,
        get: function y() {
            return this.y1
        },
    });
    return this
}


export const BubblePosition = function(this: typeof d3.selection.prototype){
    Object.defineProperty(this, "x", {
        configurable : true,
        get: function x() {
            return this.cX - this.r
        },
    });
    Object.defineProperty(this, "y", {
        configurable : true,
        get: function y() {
            return this.cY - this.r
        },
    });
    Object.defineProperty(this, "cX", {
        configurable : true,
        get: function cX() {
            let data = this.datum()[this.chart.params.x];
            if(data || data === 0){
                return this.chart.axes.x.scale(data);
            }else{
                return -10000;
            }
        },
    });

    Object.defineProperty(this, "cY", {
        configurable : true,
        get: function cY() {
            let data = this.datum()[this.chart.params.y];
            if(data || data === 0){
                return this.chart.axes.y.scale(data);
            }else{
                return -10000;
            }
        },
    });
    return this
}