import { useQuery, ApolloClient, useApolloClient } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { DatePicker as AntdDatePicker, TableColumnsType, Spin } from "antd";
import InvestmentLink from "Components/InvestmentLink";
import { parseISO, startOfMonth } from "date-fns";
import { formatCurrency } from "Helpers/index";
import i18n from "i18next";
import moment, { Moment } from "moment";
import momentConfig from "rc-picker/lib/generate/moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import ExpandableStatisticsTable from "./ExpandableStatisticsTable";
import RiskVisualizations from "frontend/src/components/RiskVisualizations";
import {
  ALLOCATION_BALANCE_DATES,
  FETCH_ACCOUNT_MANAGER_DATA,
  FETCH_PORTFOLIO_BALANCES,
  INVESTMENT_PRIMARY_PORTFOLIO,
} from "./graphql";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

export type AssetAllocationItem = {
  key: string;
  id?: number;
  name: string;
  isEntitled?: boolean;
  endAllocation: number;
  weight?: number;
  primaryPortfolioId?: number;
  childrenLoaded?: boolean;
  children?: AssetAllocationItem[];
  loading?: boolean;
};

function compareAllocationItems(
  a: AssetAllocationItem,
  b: AssetAllocationItem
) {
  return a.name.localeCompare(b.name);
}

const getAssetAllocationRows = (
  accountsMap?: Map<number, string>,
  balancesList?: types.GetPortfolioBalancesQuery["balancesList"]["items"]
): AssetAllocationItem[] | undefined => {
  if (!accountsMap || !balancesList) return;

  // hierarchy is Open Tag (tag3) / Client Tag (tag) / Investment
  const totalItem: AssetAllocationItem = {
    key: "total",
    name: "Total",
    endAllocation: 0,
    weight: 1,
    children: [],
  };
  const acctItem = {
    key: "accounting",
    name: "Cash/Receivable/Liabilities",
    endAllocation: 0,
  };

  // create tree structure
  for (const balance of balancesList) {
    const { managerId, manager, tags } = balance;
    const openTag = tags?.tag3 ?? "";
    const clientTag = tags?.tag ?? "";
    if (accountsMap.has(managerId) || openTag === "xCash") {
      acctItem.endAllocation += balance.endBalance;
      totalItem.endAllocation += balance.endBalance;
      continue;
    }
    let openTagItem = totalItem.children!.find(row => row.name === openTag);
    if (!openTagItem) {
      openTagItem = {
        key: `open-${totalItem.children!.length}`,
        name: openTag,
        endAllocation: 0,
        childrenLoaded: true,
        children: [],
      };
      totalItem.children!.push(openTagItem);
    }
    let clientTagItem = openTagItem.children!.find(
      row => row.name === clientTag
    );
    if (!clientTagItem) {
      clientTagItem = {
        key: `client-${openTagItem.children!.length}_${openTagItem.key}`,
        name: clientTag,
        endAllocation: 0,
        childrenLoaded: true,
        children: [],
      };
      openTagItem.children!.push(clientTagItem);
    }
    clientTagItem.children!.push({
      key: `investment-${managerId}`,
      id: manager?.id,
      name: manager?.name || `Investment ${managerId}`,
      isEntitled: !!manager?.isEntitled,
      endAllocation: balance.endBalance,
      primaryPortfolioId: manager?.primaryPortfolio?.id,
      // stub `children` to enable expansion in antd table
      // they will be lazy-loaded on expansion
      children: manager?.primaryPortfolio?.id ? [] : undefined,
    });
    clientTagItem.endAllocation += balance.endBalance;
    openTagItem.endAllocation += balance.endBalance;
    totalItem.endAllocation += balance.endBalance;
  }
  totalItem.children!.sort(compareAllocationItems);
  totalItem.children!.push(acctItem);

  // total children already sorted
  totalItem.children?.forEach(item1 => {
    item1.weight = item1.endAllocation / totalItem.endAllocation;
    item1.children?.sort(compareAllocationItems).forEach(item2 => {
      item2.weight = item2.endAllocation / totalItem.endAllocation;
      item2.children?.sort(compareAllocationItems).forEach(item3 => {
        item3.weight = item3.endAllocation / totalItem.endAllocation;
      });
    });
  });

  const { children } = totalItem;
  delete totalItem.children;
  return [...children!, totalItem];
};

async function fetchChildren(
  apolloClient: ApolloClient<object>,
  date: string,
  accountsMap: Map<number, string>,
  parent: AssetAllocationItem
) {
  const {
    data: { balancesList },
  } = await apolloClient.query({
    query: FETCH_PORTFOLIO_BALANCES,
    variables: {
      portfolioId: parent.primaryPortfolioId!,
      date,
    },
    fetchPolicy: "no-cache",
  });

  const items: AssetAllocationItem[] = [];
  let acctEndAlloc = 0;
  let endAlloc = 0;
  for (const balance of balancesList.items) {
    endAlloc += balance.endBalance;
    const { managerId, manager, tags } = balance;
    const openTag = tags?.tag3 ?? "";
    if (accountsMap.has(balance.managerId) || openTag === "xCash") {
      acctEndAlloc += balance.endBalance;
      continue;
    }
    items.push({
      key: `${parent.key}-${managerId}`,
      id: manager?.id,
      name: manager?.name || `Investment ${managerId}`,
      isEntitled: !!manager?.isEntitled,
      endAllocation: balance.endBalance,
      primaryPortfolioId: manager?.primaryPortfolio?.id,
      children: manager?.primaryPortfolio?.id ? [] : undefined,
    });
  }
  items.sort(compareAllocationItems);
  items.push({
    key: `${parent.key}-accounting`,
    name: "Cash/Receivable/Liabilities",
    endAllocation: acctEndAlloc,
  });
  items.forEach(row => {
    const childWeight = row.endAllocation / endAlloc;
    row.weight = parent.weight! * childWeight;
    row.endAllocation = parent.endAllocation * childWeight;
  });
  return items;
}

// because we have to immutably rebuild the data when the structure changes
function insertChildren(
  tree: AssetAllocationItem[],
  parent: AssetAllocationItem,
  children: AssetAllocationItem[]
): AssetAllocationItem[] {
  return tree.map(node =>
    node.key === parent.key
      ? {
          ...node,
          childrenLoaded: true,
          children,
        }
      : {
          ...node,
          children:
            node.children && insertChildren(node.children, parent, children),
        }
  );
}

const columns: TableColumnsType<AssetAllocationItem> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 8,
    align: "left" as const,
    fixed: true,
    render: (name: string, record: AssetAllocationItem) => {
      const { id, isEntitled } = record;
      if (record.loading) {
        return <Spin size="small" />;
      }
      if (!id || !isEntitled) {
        return <p>{name}</p>;
      }
      return (
        <InvestmentLink
          className="fund-link-wrapper"
          investmentId={id}
          isEntitled={isEntitled}
        >
          <p style={{ wordBreak: "break-word" }}>{name}</p>
        </InvestmentLink>
      );
    },
  },
  {
    title: "Allocation",
    dataIndex: "endAllocation",
    key: "endAllocation",
    width: 4,
    align: "right" as const,
    ellipsis: true,
    render: (endAllocation: number) => (
      <p
        style={{
          color: endAllocation < 0 ? "red" : undefined,
          wordBreak: "break-word",
        }}
      >
        {endAllocation < 0
          ? `(${formatCurrency(Math.abs(endAllocation).toFixed(0))})`
          : `${formatCurrency(endAllocation.toFixed(0))}`}
      </p>
    ),
  },
  {
    title: "Weight",
    dataIndex: "weight",
    key: "weight",
    width: 3,
    align: "right" as const,
    ellipsis: true,
    render: (weight: number) => (
      <p
        style={{
          color: weight < 0 ? "red" : undefined,
          wordBreak: "break-word",
        }}
      >
        {(weight * 100).toFixed(2)}%
      </p>
    ),
  },
].map(column => ({
  ...column,
  ellipsis: false,
  fixed: false,
}));

/* eslint-disable-next-line max-lines-per-function */
export function AssetAllocationTable({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const apolloClient = useApolloClient();

  const [date, setDate] = useState<Date | null>(null);
  const { data: portfolioData } = useQuery(INVESTMENT_PRIMARY_PORTFOLIO, {
    variables: { investmentId },
  });

  const portfolioId = portfolioData?.investment?.primaryPortfolio?.id || 0;

  const {
    loading: balancesLoading,
    error: balancesError,
    data: balancesData,
  } = useQuery(FETCH_PORTFOLIO_BALANCES, {
    variables: {
      portfolioId,
      date: date?.toISOString().slice(0, 10),
    },
    skip: !date || !portfolioId,
  });

  const { data: accountsData, loading: accountsLoading } = useQuery(
    FETCH_ACCOUNT_MANAGER_DATA
  );

  const { data: datesData, loading: datesLoading } = useQuery(
    ALLOCATION_BALANCE_DATES,
    {
      variables: {
        portfolioId,
      },
      skip: !portfolioId,
    }
  );

  useEffect(() => {
    if (datesData?.balanceDates.length) {
      const currentDate = startOfMonth(new Date()).toISOString().slice(0, 10);
      const newDate = new Date(
        datesData.balanceDates.find(dateVal => dateVal <= currentDate)
      );
      setDate(newDate);
    }
  }, [datesData]);

  const accountsMap = useMemo(
    () =>
      accountsData &&
      new Map(
        accountsData?.accountingManagerList?.items.map(item => [
          item.id,
          item.name,
        ])
      ),
    [accountsData]
  );

  const [managerAllocationData, setManagerAllocationData] =
    useState<AssetAllocationItem[]>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  useEffect(() => {
    const allocationRows = getAssetAllocationRows(
      accountsMap,
      balancesData?.balancesList?.items
    );
    setManagerAllocationData(allocationRows);
    setExpandedRowKeys([]);
  }, [balancesData, accountsMap]);

  const loadChildren = useCallback(
    async (parent: AssetAllocationItem) => {
      // temporary child with loading indicator
      {
        const tree = insertChildren(managerAllocationData!, parent, [
          {
            key: `${parent.key}_loading`,
            name: "",
            endAllocation: 0,
            weight: 0,
            loading: true,
          },
        ]);
        setManagerAllocationData(tree);
      }

      // fetch the children
      const children = await fetchChildren(
        apolloClient,
        date?.toISOString().slice(0, 10) || "",
        accountsMap!,
        parent
      );

      // insert the children
      {
        const tree = insertChildren(managerAllocationData!, parent, children);
        setManagerAllocationData(tree);
      }
    },
    [apolloClient, date, accountsMap, managerAllocationData]
  );

  const updateDate = (newDate: moment.Moment | null) => {
    if (!newDate) return;
    const monthStart = startOfMonth(newDate.toDate());
    setDate(monthStart);
  };

  const disabledTime = useCallback(
    (selectionDate: Moment) =>
      !datesData?.balanceDates
        .map(dateDatum =>
          parseISO(new Date(dateDatum).toISOString().slice(0, 10))
        )
        .some(
          (dateDatum: Date) =>
            selectionDate.month() === dateDatum.getMonth() &&
            selectionDate.year() === dateDatum.getFullYear()
        ),
    [datesData]
  );

  if (balancesError) {
    return (
      <span>
        Something went wrong fetching allocation data
        <span hidden>{JSON.stringify(balancesError, null, 2)}</span>
      </span>
    );
  }

  return (
    <div>
      {balancesError && (
        <div className="error">
          Something went wrong, please contact support.
        </div>
      )}
      <div className="summary-heading" data-cy="assetAllocation">
        <h3 className="invt-tab__title" id="assetAllocation">
          {i18n.t("overview.portfolio")}
        </h3>

        <div className="summary-heading__desc-wrap">
          {datesLoading && (
            <i
              data-testid="loading-spinner"
              className="fa fa-spinner fa-spin"
            />
          )}
          {date && (
            <>
              <span className="summary-heading__desc-label">
                {i18n.t("date.as_of", { date: "" })}
              </span>

              <div className="rc-calendar-custom-wrap width-120">
                <DatePicker
                  picker="month"
                  format="MMM yyyy"
                  disabledDate={disabledTime}
                  allowClear={false}
                  placeholder="Set the date"
                  defaultValue={moment(date.toISOString().substring(0, 10))}
                  onChange={updateDate}
                  data-cy="month-picker"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <div id="assetAllocationTable" className="table-responsive">
          <ExpandableStatisticsTable
            columns={columns}
            loading={balancesLoading || accountsLoading}
            data={managerAllocationData}
            loadChildren={loadChildren}
            expandedRowKeys={expandedRowKeys}
            setExpandedRowKeys={setExpandedRowKeys}
          />
        </div>
      </div>
    </div>
  );
}
