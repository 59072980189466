import _ from 'lodash'

class InvalidValueWarning{
    public readonly param: any;
    public readonly value: any;
    public readonly point: any;
    public readonly message: string;
    constructor(param: any, value: any, point: any){
        this.param = param 
        this.value = value 
        this.point = point
        this.message = 'Warning: Invalid Value ' + this.param + ' = ' +  this.value + ' at Index ' + String(this.point.index) + '... Defaulting'
    }
}
class InvalidValueError{
    public readonly param: any;
    public readonly value: any;
    public readonly point: any;
    public readonly message: string;

    constructor(param: any, value: any, point: any){
        this.param = param 
        this.value = value 
        this.point = point
        this.message = 'Fatal Error: Invalid Value ' + this.param + ' = ' +  this.value + ' at Index ' + String(this.point.index)
    }
}

class FatalMissingFieldError{
    public readonly param: any;
    public readonly point: any;
    public readonly message: string;

    constructor(param: any, point: any){
        this.param = param 
        this.point = point 
        this.message = 'Fatal Error: Missing Required Value ' + this.param + ' at Index ' + String(this.point.index)
    }
}

class ConflictingCategoricalParamError{
    public readonly param: any;
    public readonly point: any;
    public readonly message: string;

    constructor(param: any, value: any, point: any){
        this.param = param 
        this.point = point 
        this.message = 'Error: Conflicting Values for ' + this.param + ' : ' + point[param] + ' and ' + value + ', at Index ' + String(this.point.index)
    }
}

class DuplicatePointError{
    public readonly point: any;
    public readonly message: string;
    constructor(point: any){
        this.point = point
        this.message = 'Error: Found Duplicate Points for ' + this.point.Name + ' on Date ' + this.point.Date
    }
}

class Warnings extends Object{
    public readonly point: any;
    public warnings: any[];
    constructor(point: any){
        super({})
        this.point = point
        this.warnings = [] 
    }
    invalid(param: any, value: any, notify: any){
        let warning = new InvalidValueWarning(param, value, this.point)
        this.warnings.push(warning)
        if(notify) console.log(warning.message)
    }
    notify(){
        _.each(this.warnings, function(warning: {message: string}){
            console.log(warning.message)
        })
    }
}

class Errors extends Object{
    public readonly point: any;
    public errors: any[];
    public valid?: boolean;

    constructor(point: any){
        super({})
        this.point = point
        this.errors = [] 
    }
    notify(){
        _.each(this.errors, function(error: any){
            console.log(error.message)
        })
    }
    duplicate(notify: any){
        let error = new DuplicatePointError(this)
        this.errors.push(error) // Make New Point Invalid
        this.valid = false;
        if(notify) console.log(error.message)
    }
    conflictingCategoricalParam(param: any, value: any, notify: boolean){
        this.point.valid = false;
        let error = new ConflictingCategoricalParamError(param, value, this.point)
        this.errors.push(error)
        if(notify) console.log(error.message)
    }
    missing(param: any, notify: boolean){
        this.point.valid = false;
        let error = new FatalMissingFieldError(param, this.point)
        this.errors.push(error)
        if(notify) console.log(error.message)
    }
    invalid(param: any, value: any, notify: boolean){
        this.point.valid = false;
        let error = new InvalidValueError(param, value, this.point)
        this.errors.push(error)
        if(notify) console.log(error.message)
    }
}

export default {
    Warnings : Warnings,
    Errors : Errors
}


