
import _ from 'lodash'

const Nodes = {
	x : {'label':'X Axis','id':'x'},
	y : {'label':'Y Axis','id':'y'},
	z : {'label':'Size','id':'z'},
	color : {'label' : 'Color', 'id' : 'color'}
}
export class ControlChild {
	id: any
	label: any

	constructor(param: any, label?: any){
		this.id = param
		this.label = param
		if(label){
			this.label = label
		}
	}
}

function NumericChildren(array: any, chart: any){
	array.chart = chart 

	let self = array
	_.each(array.chart.numeric, function(param: any){
		let child = new ControlChild(param)
		self.push(child)
	})
	return array
}

function CategoricChildren(array: any, chart: any){
	array.chart = chart 

	let self = array
	_.each(array.chart.categoric, function(param: any){
		let child = new ControlChild(param)
		self.push(child)
	})
	return array
}

const ControlNodeChildren: any = function(this: any){

	this.x = function(chart: any){
		return NumericChildren([], chart)
	}
	this.y = function(chart: any){
		return NumericChildren([], chart)
	}
	this.color = function(chart: any){
		let children = NumericChildren([], chart)
		CategoricChildren(children, chart)

		let additional = new ControlChild('same', 'Same Color')
		children.push(additional)
		return children 
	}
	return this
}

ControlNodeChildren.prototype.Bubble = function(){
	this.z = function(chart: any){
		let children = NumericChildren(new Array(), chart)
		let additional = new ControlChild('same', 'Same Size')
		children.push(additional)
		return children 
	}
	return this
}

ControlNodeChildren.prototype.Bar = function(){
	this.x = function(chart: any){
		let children = NumericChildren(new Array(), chart)
		let nameAdditional = new ControlChild('Name')
		children.push(nameAdditional)
		return children 
	}
	return this
}

// Excludes Dimensions from Controls
let BarControl: any = function(this: any, chart: any, dimensions: any){
	let factory = new ControlNodeChildren().Bar()
	this.dimensions = dimensions

	// Generate Nodes for Controls
	let self = this
	_.each(this.dimensions, function(dim: keyof typeof Nodes){
		self[dim] = _.clone(Nodes[dim])
		self[dim].children = factory[dim](chart)
	})

	this.length = function(){
		return Object.keys(this).length
	}
	this.nodes = function(){
		let nodes: any[] = []
		let self = this
		_.each(this.dimensions, function(dim){
			nodes.push(self[dim])
		})
		return nodes
	}
	return this
}

// Excludes Dimensions from Controls
let BubbleControl: any = function(this: any, chart: any, dimensions: any){
	let factory = new ControlNodeChildren().Bubble()
	this.dimensions = dimensions

	// Generate Nodes for Controls
	let self = this
	_.each(this.dimensions, function(dim: keyof typeof Nodes){
		self[dim] = _.clone(Nodes[dim])
		self[dim].children = factory[dim](chart)
	})

	this.length = function(){
		return Object.keys(this).length
	}
	this.nodes = function(){
		let nodes: any[] = []
		let self = this
		_.each(this.dimensions, function(dim){
			nodes.push(self[dim])
		})
		return nodes
	}
}

export default {
	'BubbleControl' : BubbleControl,
	'BarControl' : BarControl,
}
