import * as d3 from "d3";

export const Connector = function (
  this: typeof d3.selection.prototype,
  group: any,
  marker: any,
  markers: any,
  chart: any
) {
  this.attr("class", "label-connector");

  this.chart = chart;
  this.group = group;
  this.marker = marker;
  this.markers = markers;

  this.visible = false; // Additional State
  this.line = null;

  this.hide = function () {
    this.selectAll("line").remove();
    this.line = null;
  };

  // Classes for Focused Determined in Color Function
  this.focus = function () {
    this.color();
  };
  this.unfocus = function () {
    this.color();
  };

  this.color = function () {
    let self = this;
    this.classed("focused", function (d: any) {
      return self.group.focused;
    });
    this.classed("selected", function (d: any) {
      return self.group.selected;
    });
  };

  // Classes for Focused Determined in Color Function
  this.select_ = function () {
    this.draw();
    this.visible = true;
  };
  this.deselect = function () {
    this.hide();
    this.visible = false;
  };

  // Draws Line and Connects Points (Marker and Label)
  // To Do: Only Draw Connector if Not Overlapping - This Becomes Applicable for AutoPositioning
  this.connect = function (origin: any, destination: any) {
    if (!this.line) this.line = this.append("line");

    this.line.attr("x1", origin.x).attr("y1", origin.y);
    this.line.attr("x2", destination.x).attr("y2", destination.y);
    this.color();
  };

  // Follows Marker As It Adjusts
  this.follow = function (origin: any, options: any) {
    // Dont Throw Error if No Line - This Can Happen on Custom Callbacks for Hover
    if (this.line) {
      if (options && options.animate) {
        this.line
          .transition()
          .duration(this.chart.settings.chart.animationDuration)
          .attr("x1", origin.x)
          .attr("y1", origin.y);
      } else {
        this.line.attr("x1", origin.x).attr("y1", origin.y);
      }
    }
  };

  // Follows Label As It Moves
  this.point = function (destination: any, options: any) {
    // Dont Throw Error if No Line - This Can Happen on Custom Callbacks for Hover
    if (this.line) {
      if (options && options.animate) {
        this.line
          .transition()
          .duration(this.chart.settings.chart.animationDuration)
          .attr("x2", destination.x)
          .attr("y2", destination.y);
      } else {
        this.line.attr("x2", destination.x).attr("y2", destination.y);
      }
    }
  };
  return this;
};
