import ParentSettings from './parent'

// Settings used to parse\transform the chart's data model
class DataSettingsObj extends ParentSettings {
  public idEnabled: any;
  public idColumn: any;

  constructor(settings: any){
      super(settings)

      this.__name__ = 'Data'

      this.idEnabled = true;
      this.idColumn = 'id';

      //todo: also validate enabled and column properties
      this.validation = {
        idEnabled: 'bool',
        idColumn: 'string'
      }
  }
}

export default DataSettingsObj
