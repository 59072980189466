/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query getBusinessApplicantDocuments($companyId: Int!) {\n    documents(\n      filter: { companyId: $companyId }\n      page: { limit: 100, offset: 0 }\n    ) {\n      items {\n        id\n        name\n        date\n        signedUrl\n        signedThumbnailUrl\n        documentTypeEnumId\n        accessLevel\n        isGallery\n        companyId\n        documentTypeEnum {\n          id\n          name\n        }\n        businessObjectEnumId\n        businessObject {\n          id\n          name\n        }\n      }\n      total\n    }\n    documentTypeEnumList {\n      items {\n        name\n        id\n      }\n    }\n  }\n": types.GetBusinessApplicantDocumentsDocument,
    "\n  mutation uploadBussinessApplicantDocument($input: DocumentInput!) {\n    addDocument(input: $input) {\n      id\n    }\n  }\n": types.UploadBussinessApplicantDocumentDocument,
    "\n  mutation deleteBusinessApplicationDocument($id: Int!) {\n    deleteDocument(id: $id)\n  }\n": types.DeleteBusinessApplicationDocumentDocument,
    "\n  query asyncDocumentProcess(\n    $documentId: Int!\n    $processName: String!\n    $sessionId: String!\n    $requestId: String!\n  ) {\n    asyncDocumentProcess(\n      documentId: $documentId\n      processName: $processName\n      sessionId: $sessionId\n      requestId: $requestId\n    )\n  }\n": types.AsyncDocumentProcessDocument,
    "\n  subscription DocumentProcessChatMessageSubscription($sessionId: String!) {\n    chatMessages(sessionId: $sessionId) {\n      sessionId\n      requestId\n      error\n      messageStream {\n        message\n        messageIndex\n        contentType\n        blockIndex\n        event\n        id\n      }\n    }\n  }\n": types.DocumentProcessChatMessageSubscriptionDocument,
    "\n  query investmentCompleteness($investmentId: Int!) {\n    investmentCompleteness(investmentId: $investmentId) {\n      category\n      id\n      data {\n        id\n        subCategory\n        value\n        description\n        percentComplete\n      }\n    }\n  }\n": types.InvestmentCompletenessDocument,
    "\n  query GetRiskVisualizationsData($portfolioId: Int!) {\n    riskVisualizationsData(portfolioId: $portfolioId) {\n      json\n    }\n  }\n": types.GetRiskVisualizationsDataDocument,
    "\n  query FETCH_COMPANY_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    company(id: $id) {\n      id\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n": types.Fetch_Company_TemplateDocument,
    "\n  query FETCH_FIRM_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    firm(id: $id) {\n      id\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n": types.Fetch_Firm_TemplateDocument,
    "\n  mutation rangerDueDilligenceDrafts(\n    $fieldKeys: [String!]!\n    $investmentId: Int!\n  ) {\n    rangerDueDilligenceDrafts(\n      investmentId: $investmentId\n      fieldKeys: $fieldKeys\n    ) {\n      id\n    }\n  }\n": types.RangerDueDilligenceDraftsDocument,
    "\n  query GetAsyncTaskStatus($id: ID!) {\n    asyncTaskStatus(id: $id) {\n      id\n      endDate\n      error\n    }\n  }\n": types.GetAsyncTaskStatusDocument,
    "\n  query rangerDueDilligenceDraftsReturn($id: ID!) {\n    getRangerDueDiligenceDrafts(id: $id)\n  }\n": types.RangerDueDilligenceDraftsReturnDocument,
    "\n  query FETCH_INVESTMENT_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    investment(id: $id) {\n      id\n      firmId\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n": types.Fetch_Investment_TemplateDocument,
    "\n  mutation AddDocument($input: DocumentInput!) {\n    addDocument(input: $input) {\n      id\n      name\n      signedUrl\n    }\n  }\n": types.AddDocumentDocument,
    "\n  mutation DeleteDocument($id: Int!) {\n    deleteDocument(id: $id)\n  }\n": types.DeleteDocumentDocument,
    "\n  fragment UserSettingsAttributes on UserSettings {\n    id\n    data {\n      defaultInvestmentView {\n        type\n        id\n      }\n    }\n  }\n": types.UserSettingsAttributesFragmentDoc,
    "\n  query currentUser {\n    currentUser {\n      id\n      organizationId\n      sub\n      username\n      userName\n      commonName\n      email\n      imageType\n      imageTypeSm\n      entryTypeId\n      flags {\n        ...UserFlagList\n      }\n      userSettings {\n        ...UserSettingsAttributes\n      }\n      organization {\n        name\n        id\n        isRockCreek\n      }\n      currentUserId\n    }\n  }\n": types.CurrentUserDocument,
    "\n  fragment UserFlagList on UserFlags {\n    DCAdmin\n    isRockCreek\n    rcgDataAccess\n    isDataManager\n    rcgDataEntry\n    anyUser\n    businessApplicant\n    isRockCreekAdmin\n    RECFReviewer\n    canAssumeUser\n    canSiriusChat\n    isClientUser\n    isPortalUser\n    investmentPageAccess\n  }\n": types.UserFlagListFragmentDoc,
    "\nfragment InvestmentProfileFragment on GeneratedInvestmentProfile {\n  name\n  firmId\n  firmName\n  inceptionDate\n  isImpact\n  summary\n  marketEnumId\n  vehicleTypeEnumId\n  assetClassEnumId\n  strategyEnumId\n  primaryGeographicFocusId\n  primaryBenchmarkId\n  secondaryBenchmarkId\n  currencyDenominationEnumId\n  vintageYear\n  peLegalLifeYrs\n  gpCommitted\n  committedCapital\n  targetRaise\n  minTargetNetMOIC\n  minTargetNetIRR\n  maxTargetNetMOIC\n  maxTargetNetIRR\n}\n": types.InvestmentProfileFragmentFragmentDoc,
    "\n  mutation AddTempFiles($input: [TempFileUpload!]!) {\n    addTempFiles(input: $input) {\n      key\n      bucket\n      filename\n      suggestions {\n        documentTypeEnumId\n        date\n      }\n    }\n  }\n": types.AddTempFilesDocument,
    "\n  query ProfileBuilder($keys: [String!]!, $bucket: String!) {\n    investmentProfileBuilder(\n      Keys: $keys\n      bucket: $bucket\n    ) {\n      answer\n      conversationId\n      investmentProfile {\n        ...InvestmentProfileFragment\n      }\n    }\n  }\n": types.ProfileBuilderDocument,
    "\nmutation AddUserActivity($input: UserActivityInput!) {\n  addUserActivity(input: $input) {\n    id\n  }\n}": types.AddUserActivityDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getBusinessApplicantDocuments($companyId: Int!) {\n    documents(\n      filter: { companyId: $companyId }\n      page: { limit: 100, offset: 0 }\n    ) {\n      items {\n        id\n        name\n        date\n        signedUrl\n        signedThumbnailUrl\n        documentTypeEnumId\n        accessLevel\n        isGallery\n        companyId\n        documentTypeEnum {\n          id\n          name\n        }\n        businessObjectEnumId\n        businessObject {\n          id\n          name\n        }\n      }\n      total\n    }\n    documentTypeEnumList {\n      items {\n        name\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query getBusinessApplicantDocuments($companyId: Int!) {\n    documents(\n      filter: { companyId: $companyId }\n      page: { limit: 100, offset: 0 }\n    ) {\n      items {\n        id\n        name\n        date\n        signedUrl\n        signedThumbnailUrl\n        documentTypeEnumId\n        accessLevel\n        isGallery\n        companyId\n        documentTypeEnum {\n          id\n          name\n        }\n        businessObjectEnumId\n        businessObject {\n          id\n          name\n        }\n      }\n      total\n    }\n    documentTypeEnumList {\n      items {\n        name\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation uploadBussinessApplicantDocument($input: DocumentInput!) {\n    addDocument(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation uploadBussinessApplicantDocument($input: DocumentInput!) {\n    addDocument(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteBusinessApplicationDocument($id: Int!) {\n    deleteDocument(id: $id)\n  }\n"): (typeof documents)["\n  mutation deleteBusinessApplicationDocument($id: Int!) {\n    deleteDocument(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query asyncDocumentProcess(\n    $documentId: Int!\n    $processName: String!\n    $sessionId: String!\n    $requestId: String!\n  ) {\n    asyncDocumentProcess(\n      documentId: $documentId\n      processName: $processName\n      sessionId: $sessionId\n      requestId: $requestId\n    )\n  }\n"): (typeof documents)["\n  query asyncDocumentProcess(\n    $documentId: Int!\n    $processName: String!\n    $sessionId: String!\n    $requestId: String!\n  ) {\n    asyncDocumentProcess(\n      documentId: $documentId\n      processName: $processName\n      sessionId: $sessionId\n      requestId: $requestId\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription DocumentProcessChatMessageSubscription($sessionId: String!) {\n    chatMessages(sessionId: $sessionId) {\n      sessionId\n      requestId\n      error\n      messageStream {\n        message\n        messageIndex\n        contentType\n        blockIndex\n        event\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription DocumentProcessChatMessageSubscription($sessionId: String!) {\n    chatMessages(sessionId: $sessionId) {\n      sessionId\n      requestId\n      error\n      messageStream {\n        message\n        messageIndex\n        contentType\n        blockIndex\n        event\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query investmentCompleteness($investmentId: Int!) {\n    investmentCompleteness(investmentId: $investmentId) {\n      category\n      id\n      data {\n        id\n        subCategory\n        value\n        description\n        percentComplete\n      }\n    }\n  }\n"): (typeof documents)["\n  query investmentCompleteness($investmentId: Int!) {\n    investmentCompleteness(investmentId: $investmentId) {\n      category\n      id\n      data {\n        id\n        subCategory\n        value\n        description\n        percentComplete\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRiskVisualizationsData($portfolioId: Int!) {\n    riskVisualizationsData(portfolioId: $portfolioId) {\n      json\n    }\n  }\n"): (typeof documents)["\n  query GetRiskVisualizationsData($portfolioId: Int!) {\n    riskVisualizationsData(portfolioId: $portfolioId) {\n      json\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FETCH_COMPANY_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    company(id: $id) {\n      id\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n"): (typeof documents)["\n  query FETCH_COMPANY_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    company(id: $id) {\n      id\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FETCH_FIRM_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    firm(id: $id) {\n      id\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n"): (typeof documents)["\n  query FETCH_FIRM_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    firm(id: $id) {\n      id\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation rangerDueDilligenceDrafts(\n    $fieldKeys: [String!]!\n    $investmentId: Int!\n  ) {\n    rangerDueDilligenceDrafts(\n      investmentId: $investmentId\n      fieldKeys: $fieldKeys\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation rangerDueDilligenceDrafts(\n    $fieldKeys: [String!]!\n    $investmentId: Int!\n  ) {\n    rangerDueDilligenceDrafts(\n      investmentId: $investmentId\n      fieldKeys: $fieldKeys\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAsyncTaskStatus($id: ID!) {\n    asyncTaskStatus(id: $id) {\n      id\n      endDate\n      error\n    }\n  }\n"): (typeof documents)["\n  query GetAsyncTaskStatus($id: ID!) {\n    asyncTaskStatus(id: $id) {\n      id\n      endDate\n      error\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query rangerDueDilligenceDraftsReturn($id: ID!) {\n    getRangerDueDiligenceDrafts(id: $id)\n  }\n"): (typeof documents)["\n  query rangerDueDilligenceDraftsReturn($id: ID!) {\n    getRangerDueDiligenceDrafts(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FETCH_INVESTMENT_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    investment(id: $id) {\n      id\n      firmId\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n"): (typeof documents)["\n  query FETCH_INVESTMENT_TEMPLATE(\n    $id: Int!\n    $name: FieldSetType!\n    $version: String\n    $state: String\n  ) {\n    investment(id: $id) {\n      id\n      firmId\n      templateOptions(fieldSetType: $name)\n      fieldData(fieldSetType: $name, version: $version, state: $state) {\n        assetClassName\n        fieldMeta\n        fieldSet\n        fieldSetType\n        strategyName\n        queryFragments\n        version\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddDocument($input: DocumentInput!) {\n    addDocument(input: $input) {\n      id\n      name\n      signedUrl\n    }\n  }\n"): (typeof documents)["\n  mutation AddDocument($input: DocumentInput!) {\n    addDocument(input: $input) {\n      id\n      name\n      signedUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteDocument($id: Int!) {\n    deleteDocument(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteDocument($id: Int!) {\n    deleteDocument(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserSettingsAttributes on UserSettings {\n    id\n    data {\n      defaultInvestmentView {\n        type\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment UserSettingsAttributes on UserSettings {\n    id\n    data {\n      defaultInvestmentView {\n        type\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query currentUser {\n    currentUser {\n      id\n      organizationId\n      sub\n      username\n      userName\n      commonName\n      email\n      imageType\n      imageTypeSm\n      entryTypeId\n      flags {\n        ...UserFlagList\n      }\n      userSettings {\n        ...UserSettingsAttributes\n      }\n      organization {\n        name\n        id\n        isRockCreek\n      }\n      currentUserId\n    }\n  }\n"): (typeof documents)["\n  query currentUser {\n    currentUser {\n      id\n      organizationId\n      sub\n      username\n      userName\n      commonName\n      email\n      imageType\n      imageTypeSm\n      entryTypeId\n      flags {\n        ...UserFlagList\n      }\n      userSettings {\n        ...UserSettingsAttributes\n      }\n      organization {\n        name\n        id\n        isRockCreek\n      }\n      currentUserId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserFlagList on UserFlags {\n    DCAdmin\n    isRockCreek\n    rcgDataAccess\n    isDataManager\n    rcgDataEntry\n    anyUser\n    businessApplicant\n    isRockCreekAdmin\n    RECFReviewer\n    canAssumeUser\n    canSiriusChat\n    isClientUser\n    isPortalUser\n    investmentPageAccess\n  }\n"): (typeof documents)["\n  fragment UserFlagList on UserFlags {\n    DCAdmin\n    isRockCreek\n    rcgDataAccess\n    isDataManager\n    rcgDataEntry\n    anyUser\n    businessApplicant\n    isRockCreekAdmin\n    RECFReviewer\n    canAssumeUser\n    canSiriusChat\n    isClientUser\n    isPortalUser\n    investmentPageAccess\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment InvestmentProfileFragment on GeneratedInvestmentProfile {\n  name\n  firmId\n  firmName\n  inceptionDate\n  isImpact\n  summary\n  marketEnumId\n  vehicleTypeEnumId\n  assetClassEnumId\n  strategyEnumId\n  primaryGeographicFocusId\n  primaryBenchmarkId\n  secondaryBenchmarkId\n  currencyDenominationEnumId\n  vintageYear\n  peLegalLifeYrs\n  gpCommitted\n  committedCapital\n  targetRaise\n  minTargetNetMOIC\n  minTargetNetIRR\n  maxTargetNetMOIC\n  maxTargetNetIRR\n}\n"): (typeof documents)["\nfragment InvestmentProfileFragment on GeneratedInvestmentProfile {\n  name\n  firmId\n  firmName\n  inceptionDate\n  isImpact\n  summary\n  marketEnumId\n  vehicleTypeEnumId\n  assetClassEnumId\n  strategyEnumId\n  primaryGeographicFocusId\n  primaryBenchmarkId\n  secondaryBenchmarkId\n  currencyDenominationEnumId\n  vintageYear\n  peLegalLifeYrs\n  gpCommitted\n  committedCapital\n  targetRaise\n  minTargetNetMOIC\n  minTargetNetIRR\n  maxTargetNetMOIC\n  maxTargetNetIRR\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddTempFiles($input: [TempFileUpload!]!) {\n    addTempFiles(input: $input) {\n      key\n      bucket\n      filename\n      suggestions {\n        documentTypeEnumId\n        date\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddTempFiles($input: [TempFileUpload!]!) {\n    addTempFiles(input: $input) {\n      key\n      bucket\n      filename\n      suggestions {\n        documentTypeEnumId\n        date\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ProfileBuilder($keys: [String!]!, $bucket: String!) {\n    investmentProfileBuilder(\n      Keys: $keys\n      bucket: $bucket\n    ) {\n      answer\n      conversationId\n      investmentProfile {\n        ...InvestmentProfileFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProfileBuilder($keys: [String!]!, $bucket: String!) {\n    investmentProfileBuilder(\n      Keys: $keys\n      bucket: $bucket\n    ) {\n      answer\n      conversationId\n      investmentProfile {\n        ...InvestmentProfileFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddUserActivity($input: UserActivityInput!) {\n  addUserActivity(input: $input) {\n    id\n  }\n}"): (typeof documents)["\nmutation AddUserActivity($input: UserActivityInput!) {\n  addUserActivity(input: $input) {\n    id\n  }\n}"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;