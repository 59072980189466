import * as d3 from 'd3'
import _ from 'lodash'

export const BarDimensions = function(this: typeof d3.selection.prototype){
    Object.defineProperty(this, "r", {
        configurable : true,
        get: function r() {
            return 0.0
        },
    });
    Object.defineProperty(this, "width", {
        configurable : true,
        get: function width() {
            return this.chart.axes.x.scale.bandwidth()
        },
    });
    Object.defineProperty(this, "height", {
        configurable : true,
        get: function height() {
            return this.y2 - this.y1
        },
    });     

    Object.defineProperty(this, "y1", {
        configurable : true,
        get : function y1(){
            
            // If Negative, Bound on Top by Either 0 Line or Top of Chart
            if(this.negative){
                // If ymin > y0 -> Horizontal Line for 0 Above Chart Area
                let min = this.chart.axes.y.scale.domain()[1]
                let ymin = this.chart.axes.y.scale(min)
                let y0 = this.chart.axes.y.scale(0.0)
                return Math.max(y0, ymin)
            }
            // If Positive, Bound at Top by Value of Param
            else{
                let value = this.datum()[this.chart.params.y]
                return this.chart.axes.y.scale(value)
            }
        }
    })

    Object.defineProperty(this, "y2", {
        configurable : true,
        get : function y2(){

            // If Positive, Bound on Bottom by Either 0 Line or Bottom of Chart ( If X Axis Underneath Bottom of Chart )
            if(this.positive){
                let min = this.chart.axes.y.scale.domain()[0] // Bottom Domain Point
                let ymin = this.chart.axes.y.scale(min)
                let y0 = this.chart.axes.y.scale(0.0)
                return Math.min(y0, ymin)
            }
            // If Negative, Bound at Bottom by Value of Param
            else{
                let value = this.datum()[this.chart.params.y]
                return this.chart.axes.y.scale(value)
            }
        }
    })
}

export const BubbleDimensions = function(this: typeof d3.selection.prototype){
    Object.defineProperty(this, "width", {
        configurable : true,
        get: function width() {
            return 2.0 * this.r
        },
    });
    Object.defineProperty(this, "height", {
        configurable : true,
        get: function height() {
            return 2.0 * this.r
        },
    });
    Object.defineProperty(this, "r", {
        configurable : true,
        get: function r() {
            if(this.chart.params.z === "same"){
                return this.chart.axes.z.scale(0);                 
            }else{
                let z = this.datum()[this.chart.params.z];
                if(z === null){
                    return 0;
                }else{
                    return this.chart.axes.z.scale(Math.abs(z)) * 11 / 20;
                }
            }
        },
    });
}
