import * as d3 from 'd3'
import Utilities from '../utilities/utilities'

export const MarkerProperties = function(this: typeof d3.selection.prototype){
	// To Do: Use Fill Alpha Specified by Settings Instead of CSS
    // For Now - Stroke and Fill Same Colors Because No Alpha Applied - Alpha Applied in CSS
    Object.defineProperty(this, 'fillColor', {
        configurable : true,
        get : function fillColor(){

            // Alpha Specified by CSS
            let color = this.chart.axes.color.scale(this.datum())
            color = Utilities.Coloring.universal_rgb_convert(color)
            return color
        },
    })
    Object.defineProperty(this, 'strokeColor', {
        configurable : true,
        get : function strokeColor(){

            // Alpha Specified by CSS
            let color = this.chart.axes.color.scale(this.datum())
            color = Utilities.Coloring.universal_rgb_convert(color)
            return color
        },
    })
    this.color = function(){
    	let self = this 
    	this.attr('fill', this.fillColor)
	    this.attr('stroke', this.strokeColor)

	    this.classed('focused', function(d: any) {
	        return self.group.focused
	    })
	    this.classed('selected', function(d: any) {
	        return self.group.selected
	    })
    }
}

export const BarProperties = function(this: typeof d3.selection.prototype){
	MarkerProperties.bind(this)()

	Object.defineProperty(this, "negative", {
        configurable : true,
        get : function negative(){
            let value = this.datum()[this.chart.params.y]
            return value < 0.0
        }
    })

    Object.defineProperty(this, "positive", {
        configurable : true,
        get : function positive(){
            let value = this.datum()[this.chart.params.y]
            return value > 0.0
        }
    })
}

export const BubbleProperties = function(this: typeof d3.selection.prototype){
	MarkerProperties.bind(this)()

	this.color = function(){
		let self = this
    	this.attr('fill', this.fillColor)
	    this.attr('stroke', this.strokeColor)

	    this.classed('focused', function(d: any) {
	        return self.group.focused
	    })
	    this.classed('selected', function(d: any) {
	        return self.group.selected
	    })
	    this.classed("negative-radius", function(this: typeof d3.selection.prototype, d: any, i: any) {
            return this.negative
        })
    }

	Object.defineProperty(this, "negative", {
        configurable : true,
        get : function negative(){
            let value = this.datum()[this.chart.params.z]
            if(this.chart.params.z != 'same' && value < 0.0){
                return true
            }
            return false
        }
    })

    Object.defineProperty(this, "positive", {
        configurable : true,
        get : function positive(){
            return !this.negative
        }
    })
}

