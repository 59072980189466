import Utilities from '../utilities/utilities'
import _ from 'lodash'

// Not Currently Using IDs but Leave Just In Case We Reincorporate
class FormatRow {
    public Name: any;
    public Date: any;

    constructor(row: any, index?: any){
        // To Do: Conglomerate Errors and Remove Invalid Rows Ahead of Time - Dont Throw Error
        //this.id = row['id'] || row['ID'] || row['Id']

        this.Name = row['name'] || row['Name']
        this.Date = row['date'] || row['Date']
        if(this.Date){
            this.Date = Date.parse(this.Date)
        }
        
        let self = this
        // key != 'id'  && key != 'ID' && key != 'Id'
        Object.keys(row).forEach(function(key){
            if(key != 'date' && key != 'Date' && key != 'name' && key != 'Name'){
                self[key as keyof typeof self] = row[key]
            }
        })
    }
    // Get Data Types for Each Key in Row Excluding Date Field - Invalid Data Types Ignored
    // Includes Name as Categoric Param (String Param)
    // If Value Missing - Dont Include in Data Types - Note as Null - This Might Be An Issue if We Have Missing Values in First Row
    get dataTypes(): any {
        let types = _.map(this, function(value: any, key: any) {
            //if(key.toLowerCase() != 'id'){
            key = Utilities.Format.is_valid_string(key)
            if(key && key != 'Date'){
                let float = Utilities.Format.is_valid_float(value)
                if(float || float === 0){
                    return [key, 'numeric']
                }
                let string = Utilities.Format.is_valid_string(value)
                if(string){
                    return [key, 'string']
                }
            }
        })
        let dataTypes = _.fromPairs(_.compact(types))
        return dataTypes
    }
}

export default FormatRow