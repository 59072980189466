/* eslint-disable max-lines-per-function */
import {
  ApiOutlined,
  CommentOutlined,
  LoadingOutlined,
  UploadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { styled } from "@mui/material/styles";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Input,
  Row,
  Spin,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";
import {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMutation, useQuery } from "@apollo/client";
import { SAVE_STORED_PROMPT, GET_STORED_PROMPTS_LIST } from "./graphql";
import { ChatMessageHead } from "../ChatPage/ChatMessageHead";
import { ChatMessageItem } from "../ChatPage/ChatMessageItem";
import { ChatMessage } from "./types";
import { useMemo } from "react";

const StyledUpload = styled(Upload)({
  width: "100%",
  height: "50px",
  "& .ant-upload": {
    width: "100% !important",
    height: "50px !important",
    borderRadius: "10px",
  },
});

const MarkdownCard = styled(Card)({
  "& .markdown-li": {
    display: "list-item",
  },
  "& .markdown-ol": {
    listStyle: "decimal outside none",
    display: "block",
    margin: "1em 0",
    padding: "0 0 0 2em",
  },
  "& .markdown-ul": {
    listStyle: "disc outside none",
    display: "block",
    margin: "1em 0",
    padding: "0 0 0 2em",
  },
  "& .markdown-table": {
    width: "initial",
    "& th": {
      fontWeight: "bold",
    },
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "#f2f2f2",
    },
  },
  "& code": {
    backgroundColor: "#f2f2f2",
    borderRadius: "3px",
    padding: "0 3px",
  },
});

const MARKDOWN_CARD_STYLE = {
  borderRadius: "10px",
  width: "100%",
};

const TEXT_AREA_STYLE = {
  width: `100%`,
  borderRadius: "5px",
};

const AUTOCOMPLETE_STYLE = { height: "inherit" };

export const ChatComponent = ({
  chatMessages,
  handleUserMessage,
  userText,
  setUserText,
  fileUpload,
  agentWorking,
  responseButtonClickAction,
  cancelAgentFocus,
  cardStyles,
  onNewChat,
  agentCharacter,
  llmOnly,
  setLlmOnly,
}: {
  chatMessages: (ChatMessage & { key?: string })[];
  handleUserMessage: (text?: string) => Promise<void>;
  userText: string;
  setUserText: (text: string) => void;
  fileUpload?: {
    customRequest: UploadProps["customRequest"];
  };
  responseButtonClickAction?: (value: string | undefined) => void;
  agentWorking: boolean;
  cancelAgentFocus?: () => void;
  cardStyles?: CSSProperties;
  onNewChat?: () => void;
  agentCharacter: {
    name: string;
    src: string;
  };
  llmOnly?: boolean;
  setLlmOnly?: (value: boolean) => void;
}) => {
  const [localChatId, setLocalChatId] = useState(0);
  const [fileList, setFileList] = useState<any[]>([]);
  const [autoScroll, setAutoScroll] = useState(true);
  const [storedPromptSearch, setStoredPromptSearch] = useState<string | null>(
    null
  );
  const [saveButtonState, setSavedButtonSet] = useState<
    null | "saved" | "saving"
  >(null);
  const [saveStoredPrompt, { loading: savePromptLoading, error }] =
    useMutation(SAVE_STORED_PROMPT);

  const { data: storedPromptsData } = useQuery(GET_STORED_PROMPTS_LIST, {
    skip: !storedPromptSearch,
    variables: { filter: { q: storedPromptSearch } },
  });

  const localOnNewChat = useCallback(() => {
    setLocalChatId(prev => prev + 1);
    setUserText("");
    setFileList([]);
    onNewChat?.();
  }, [onNewChat, setUserText, setFileList, setLocalChatId]);

  const { ref } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
  });

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const shouldAutoScroll = useCallback(() => {
    if (!chatContainerRef.current) return false;
    const currentScrollTop = chatContainerRef.current.scrollTop;
    const scrollHeight = chatContainerRef.current.scrollHeight;
    const clientHeight = chatContainerRef.current.clientHeight;
    const threshold = 50;

    return currentScrollTop + clientHeight >= scrollHeight - threshold;
  }, [chatContainerRef]);

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;
    // Check if the user is at the bottom of the chat
    if (shouldAutoScroll()) {
      setAutoScroll(true);
    } else {
      setAutoScroll(false);
    }
  }, [setAutoScroll, autoScroll]);

  function scrollIntoView() {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const container = chatContainerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (autoScroll && chatContainerRef.current) {
      scrollIntoView();
    }
  }, [chatMessages, autoScroll]);

  const handleSendMessage = useCallback(() => {
    setAutoScroll(true);
    handleUserMessage();
    scrollIntoView();
  }, [setAutoScroll, handleUserMessage, scrollIntoView]);

  const chatMessagesStyle: CSSProperties = useMemo(() => {
    return {
      overflowY: "auto",
      width: "100%",
      margin: "10px",
      height: "calc(100vh - 345px)",
      ...cardStyles,
    };
  }, [cardStyles]);

  return (
    <Row>
      <Col span={24}>
        <MarkdownCard ref={ref} style={MARKDOWN_CARD_STYLE}>
          <div
            ref={chatContainerRef}
            data-cy="chat-messages"
            style={chatMessagesStyle}
          >
            <Row style={{ marginRight: "10px" }}>
              {chatMessages.map((message, imessage) => (
                <Fragment key={message.key ?? imessage}>
                  {message.type !== chatMessages[imessage - 1]?.type && (
                    <ChatMessageHead
                      type={message.type}
                      separator={!!imessage}
                      agentCharacter={agentCharacter}
                    />
                  )}
                  <ChatMessageItem
                    message={message}
                    onResponseButtonClick={responseButtonClickAction}
                  />
                </Fragment>
              ))}
            </Row>
          </div>
          <Row>
            <AutoComplete
              value={userText}
              onSelect={value => {
                setUserText(value);
              }}
              style={AUTOCOMPLETE_STYLE}
              options={storedPromptsData?.storedPromptsList.items.map(o => ({
                value: o.prompt,
              }))}
            >
              <Input.TextArea
                value={userText}
                style={TEXT_AREA_STYLE}
                placeholder="Message..."
                data-cy="chat-input"
                autoSize={{ minRows: 1, maxRows: 10 }}
                onChange={event => {
                  setUserText(event.target.value);
                  if (event.target.value[0] === "/") {
                    console.log(event.target.value);
                    setStoredPromptSearch(event.target.value.slice(1));
                  } else {
                    setStoredPromptSearch(null);
                  }
                }}
                onKeyPress={event => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
            </AutoComplete>
          </Row>
          <Row>
            <div className="d-flex justify-between mt-10 full-width">
              <div>
                {onNewChat && (
                  <Tooltip title="Start A New Chat">
                    <Button
                      key={"newChat"}
                      onClick={localOnNewChat}
                      type="default"
                      shape="circle"
                      icon={<CommentOutlined />}
                      data-cy="new-chat-button"
                    ></Button>
                  </Tooltip>
                )}
                {setLlmOnly && llmOnly !== undefined && (
                  <Tooltip
                    title={
                      !llmOnly
                        ? "Toggle to send requests to LLM Only."
                        : "Toggle to send requests to LLM and Knowledge Base."
                    }
                  >
                    <Tag.CheckableTag
                      style={{
                        fontSize: "14px",
                        height: "32px",
                        minWidth: "32px",
                        paddingRight: 0,
                        paddingLeft: 0,
                        textAlign: "center",
                        borderRadius: " 50%",
                        width: "32px",
                        fontFamily: "inherit",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      checked={llmOnly}
                      data-cy="llm-only-request-toggle"
                      onChange={checked => {
                        setLlmOnly(checked);
                      }}
                    >
                      <ApiOutlined style={{ marginTop: "7px" }} />
                    </Tag.CheckableTag>
                  </Tooltip>
                )}
                <Tooltip
                  title={
                    saveButtonState === "saved"
                      ? "Prompt saved For later find it with '/'"
                      : "Save Message For Later"
                  }
                  open={saveButtonState === "saved" ? true : undefined}
                >
                  <Button
                    icon={
                      saveButtonState === null ||
                      saveButtonState === "saved" ? (
                        <SaveOutlined />
                      ) : (
                        <LoadingOutlined />
                      )
                    }
                    style={
                      saveButtonState === "saved"
                        ? {
                            backgroundColor: "lightGreen",
                          }
                        : {}
                    }
                    onClick={() =>
                      saveStoredPrompt({
                        variables: { input: { prompt: userText } },
                      }).then(() => {
                        console.log("Saved Prompt");
                        setSavedButtonSet("saved");
                        setTimeout(() => {
                          setSavedButtonSet(null);
                        }, 5000);
                      })
                    }
                    shape="circle"
                  />
                </Tooltip>
              </div>
              {agentWorking && cancelAgentFocus ? (
                <Button
                  type="default"
                  style={{
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={cancelAgentFocus}
                >
                  Stop
                </Button>
              ) : agentWorking ? (
                <Button
                  type="default"
                  style={{
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  disabled
                >
                  <Spin
                    size="small"
                    indicator={<LoadingOutlined spin />}
                    style={{ fontSize: 14 }}
                  />
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleSendMessage()}
                >
                  Send
                </Button>
              )}
            </div>
          </Row>
        </MarkdownCard>
      </Col>
      <Col span={24}></Col>
      {fileUpload && (
        <Col span={24}>
          <StyledUpload
            key={localChatId}
            data-cy="chat-file-upload"
            name="upload"
            multiple
            listType="picture-card"
            showUploadList={false}
            disabled={agentWorking}
            customRequest={fileUpload.customRequest}
            onChange={info => {
              setFileList(info.fileList);
            }}
          >
            <UploadOutlined />
            &nbsp; {fileList.length > 0 && `(${fileList.length})`} Drag or click
            to upload {fileList.length > 0 ? `another` : "a"} file.
          </StyledUpload>
        </Col>
      )}
    </Row>
  );
};
