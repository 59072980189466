
import _ from 'lodash'
import ParentSettings from './parent'

// Overall Settings Object for Any Chart - Inherited by Specific Chart Settings Objects
class ParamsSettingsParent extends ParentSettings {
    public x: any;
    public y: any;
    public color: any;
    public z: any;

    constructor(settings: any){
        super(settings)

        this.__name__ = 'Params'
        this.x = null;
        this.y = null;
        this.color = null;
        
        this.validation = {
            x : 'string_if_defined',
            y : 'string_if_defined',
            color : 'string_if_defined',
        }
    }
    populate(){
        let providedKeys = _.keys(this.settings)

        let availableKeys: any = _.keys(this)
        availableKeys = _.filter(availableKeys, function(key: keyof typeof self){
            if(key != 'validation' && key != '__name__' && key != 'settings'){
                return true
            }
            return false
        })
        let self = this
        _.each(providedKeys, function(key: string){
            // Make Sure Key is Valid
            if(!_.includes(availableKeys, key as string)) throw new Error('Invalid Key ' + key.toString() + ' for ' + self.__name__ + ' Settings')
            self[key as keyof typeof self] = self.settings[key]
        })
        return this
    }
    validate(model: any){
        super.validate(model)
        let self = this

        let availableKeys: any = _.keys(this)

        availableKeys = _.filter(availableKeys, function(key: keyof typeof self){
            if(key != 'validation' && key != '__name__' && key != 'settings'){
                if(self[key as keyof ParamsSettingsParent] instanceof Function) return false 
                return true
            }
            return false
        })

        _.each(availableKeys, function(key: keyof typeof self){
            // If Present - Make Sure Model Has Data
            if(self[key]){
                let sameSize = (key == 'z' && (self[key] as string).toLowerCase() == 'same') || (key == 'color' && (self[key] as string).toLowerCase() == 'same')
                if(!sameSize){
                    if(! _.includes(model.numeric, self[key]) && ! _.includes(model.categoric, self[key]) ){
                        throw new Error('Invalid Parameter ' + self[key] + ' Specified for ' + key.toString() + 'Axis... Parameter Does Not Exist in Data')
                    }
                    if((self[key] as string).toLowerCase() == 'date'){
                        throw new Error('Cannot Specify Date as Axis Parameter')
                    }
                }
            }
        })
    }
}

class TransitionParamsSettings extends ParamsSettingsParent {
    constructor(settings: any){
        super(settings)

        this.z = null;
        this.validation['z'] = 'string_if_defined'
    }
    validate(model: any){
        super.validate(model)
        // Validate That Parameters Specified Are Specifically Valid
        if(this.y && ! _.includes(model.numeric, this.y)){
            throw new Error('Y Axis Parameter for Bubble Chart Must be Numeric')
        }   
    }
}   

class BubbleParamsSettings extends ParamsSettingsParent {
    constructor(settings: any){
        super(settings)

        this.z = null;
        this.validation['z'] = 'string_if_defined'
    }
    validate(model: any){
        super.validate(model)
        // Validate That Parameters Specified Are Specifically Valid
        if(this.x && ! _.includes(model.numeric, this.x)){
            throw new Error('X Axis Parameter for Bubble Chart Must be Numeric')
        }
        if(this.y && ! _.includes(model.numeric, this.y)){
            throw new Error('Y Axis Parameter for Bubble Chart Must be Numeric')
        }
    }
}   

class BarParamsSettings extends ParamsSettingsParent {
    constructor(settings: any){
        super(settings)
    }
    validate(model: any){
        super.validate(model)
        // Validate That Parameters Specified Are Specifically Valid
        if(this.y && ! _.includes(model.numeric, this.y)){
            throw new Error('Y Axis Parameter for Bubble Chart Must be Numeric')
        }
    }
}   


export default {
    TransitionParamsSettings : TransitionParamsSettings,
    BubbleParamsSettings : BubbleParamsSettings,
    BarParamsSettings : BarParamsSettings,
}

