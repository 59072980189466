import Assertions from './assertions'
import _ from 'lodash'

// Inherited by Sub Settings Components
class ParentSettingsObj {
    public settings: any
    public grouped: any
    public __name__: string | undefined
    public validation: any

    constructor(settings: any){
        this.settings = settings || {}
    }
    populate(ignore: any){
   
        let self = this
        if(ignore && !(ignore instanceof Array)){
            ignore = [ignore]
        }

        let providedKeys = _.keys(this.settings)
        providedKeys = _.filter(providedKeys, function(key: string){
            // Ignore Grouped Elements
            if(self.grouped && _.includes(self.grouped, key)){
                return false
            }
            // Ignore Items That We Ignore for Population
            if(ignore && _.includes(ignore, key)){
                return false
            }
            return true
        })

        let availableKeys = _.keys(this)
        availableKeys = _.filter(availableKeys, function(key: any){
            if(key != 'validation' && key != '__name__' && key != 'settings' && key != 'grouped'){
                if(key == 'hover' || key == 'clicked'){ // Not Putting This In Will Cause Hover or Clicked Params to Throw Error Since They Are Functions
                    return true
                }
                if(self[key as keyof ParentSettingsObj] instanceof Function) return false 
                return true
            }
            return false
        })

        _.each(providedKeys, function(key: any){
            // Make Sure Key is Valid
            if(!_.includes(availableKeys, key)) throw new Error('Invalid Key ' + key + ' for ' + self.__name__ + ' Settings')
            self[key as keyof ParentSettingsObj] = self.settings[key]
        })
        return this
    }

    // Model Passed In to Ensure Data Provided is Okay
    // Cant Ignore Validation
    validate(model: any){
        let self = this 

        let availableKeys: any = _.keys(this)

        availableKeys = _.filter(availableKeys, function(key: keyof ParentSettingsObj){
            if(key != 'validation' && key != '__name__' && key != 'settings'){
                if(self[key] as any instanceof Function) return false 
                return true
            }
            return false
        })

        _.each(availableKeys, function(key: string){
            // Validate Key Against Type/Assertion
            if(self.validation[key]){
                if(self.validation[key] instanceof Array) {
                    Assertions[self.validation[key][0] as keyof typeof Assertions](key, self[key as keyof typeof self], self.validation[key][1]) // Include Options in Assertion
                } else {
                    Assertions[self.validation[key] as keyof typeof Assertions](key, self[key as keyof typeof self])
                } 
            }
        })
    }
}

export default ParentSettingsObj