import _ from 'lodash'

let AssertionsObj = {
    function : function(name: string, value: any){
        if(value === null){
            return
        }
        if(!(value instanceof Function)){
            throw new Error(name + ' Must be a Valid Function')
        }
    },
    string_if_defined : function(name: string, value: any){
        if(value === null){
            return
        }
        AssertionsObj.string(name, value)
    },
    defined : function(name: string, value: any){
        if(value === undefined || value === null){
            throw new Error(name + ' Must be Defined')
        }
        return
    },
    array : function(name: string, value: any, options?: any){
        if(value instanceof Array){
            _.each(value, function(val: any){
                if(options && !_.includes(options, val)){
                    throw new Error('Invalid Specification ' + val + ' for ' + name)
                }
            })
        }
        else{
            throw new Error(name + ' Must be An Array')
        }
    },
    among : function(name: string, value: any, options?: any){
        if(!_.includes(options, value)){
            throw new Error(name + ' Must be One of ' + options.join(','))
        }
    },
    string : function(name: string, value: any){
        if (typeof value === 'string' || value instanceof String){
            return
        }
        throw new Error(name + ' Must be a String')
    },
    numeric : function(name: string, value: any){
        value = parseFloat(value)
        if(isNaN(value)){
            throw new Error(name + ' Must be Numeric')
        }
    },
    bool : function(name: string, value: any){
        if(value != true && value != false){
            throw new Error(name + ' Must be Boolean')
        }
    }
}

export default AssertionsObj



