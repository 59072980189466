import React, { useState, useEffect, useRef, useCallback } from "react";
import { throttle } from "lodash";
import { ResizeSensor } from "css-element-queries";
import classNames from "classnames";

const FadingHeader = ({
  disable,
  onChange,
  children,
}: {
  disable: boolean;
  onChange: Function;
  children: any;
}) => {
  const [position, setPosition] = useState("unfixed");
  const [headerHeight, setHeaderHeight] = useState(0);
  const mainHeaderRef = useRef<HTMLDivElement>(null);
  const lastKnownScrollY = useRef(0);
  const scrollDirection = useRef("");

  const handleResize = useCallback(() => {
    if (mainHeaderRef.current) {
      setHeaderHeight(mainHeaderRef.current.getBoundingClientRect().height);
    }
  }, []);

  const updateScrollDirection = useCallback(
    () =>
      throttle(() => {
        const currentScrollY = window.scrollY;
        scrollDirection.current =
          currentScrollY >= lastKnownScrollY.current ? "down" : "up";

        if (scrollDirection.current === "up" && window.scrollY !== 0) {
          setPosition("pinned");
          onChange("pinned");
        } else if (
          scrollDirection.current === "down" &&
          window.scrollY > headerHeight
        ) {
          setPosition("unpinned");
          onChange("unpinned");
        } else {
          setPosition("unfixed");
          onChange("unfixed");
        }

        lastKnownScrollY.current = currentScrollY;
      }, 100),
    [headerHeight]
  );

  useEffect(() => {
    if (!disable && mainHeaderRef.current) {
      const resizeSensor = new ResizeSensor(
        mainHeaderRef.current,
        handleResize
      );
      window.addEventListener("scroll", updateScrollDirection);

      return () => {
        resizeSensor.detach();
        window.removeEventListener("scroll", updateScrollDirection);
      };
    }
  }, [disable, headerHeight]);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div
      style={
        position === "pinned" ? { height: `${headerHeight}px` } : undefined
      }
      className={classNames("main-header-wrap", {
        "main-header-wrap--scrolled-up": position === "pinned",
        "main-header-wrap--scrolled": position !== "unfixed",
      })}
    >
      <div className="main-header" ref={mainHeaderRef}>
        {children}
      </div>
    </div>
  );
};

export default FadingHeader;
