import _ from "lodash";
import * as d3 from "d3";
import {
  BarYCoordinateAxis,
  CategoryAxis,
  ColorAxis,
  FakeCategoryAxis,
  TimeAxis,
  XCoordinateAxis,
  YCoordinateAxis,
  ZCoordinateAxis,
} from "./axis";

const Axes = function (this: typeof d3.selection.prototype, chart: any) {
  this.chart = chart;

  this.renderLabels = function () {
    this.x.renderLabel();
    this.y.renderLabel();
  };

  this.populate = function (points: any) {
    let self = this;
    _.each(this.dimensions, function (dim) {
      self[dim].update(points);
    });
  };
  return this;
};

export const BubbleAxes = function (
  this: typeof d3.selection.prototype,
  chart: any,
  maintain?: any
) {
  Axes.bind(this)(chart);

  this.__name__ = "BubbleAxes";
  this.dimensions = ["x", "y", "z", "color"]; // Just for Reference for Now

  this.z = new ZCoordinateAxis(this, chart);
  this.color = new ColorAxis(this, chart);
  this.time = new TimeAxis(this, chart);

  // Creates Axes and Initializes with Initial Data Points
  this.initialize = function (points: any) {
    this.x = XCoordinateAxis.bind(this.append("g"))(this, chart);
    this.y = YCoordinateAxis.bind(this.append("g"))(this, chart);

    // Must Create Y First to Setup Scales for Initial X Position
    this.y.initialize(points);
    this.x.initialize(points);
    this.z.initialize(points);
    this.color.initialize(points);
  };
  // Updates Axes - Repositioning Y Axis Unnecessary
  this.update = function (points: any) {
    this.y.update(points);
    this.x.update(points);
    this.z.update(points);
    this.color.update(points);
  };
  return this
};

export const BarAxes = function (
  this: typeof d3.selection.prototype,
  chart: any,
  maintain?: any
) {
  Axes.bind(this)(chart);

  this.dimensions = ["x", "y", "color"]; // Just for Reference for Now
  this.__name__ = "BarAxes";

  this.z = null;
  this.color = new ColorAxis(this, chart);
  this.time = new TimeAxis(this, chart);

  // Creates Axes and Initializes with Initial Data Points
  this.initialize = function (points: any) {
    this.x = CategoryAxis.bind(this.append("g"))(this, chart);
    this.y = BarYCoordinateAxis.bind(this.append("g"))(this, chart);

    this.x_fake = FakeCategoryAxis.bind(this.append("g"))(this, chart);

    // Must Create Y First to Setup Scales for Initial X Position
    this.y.initialize(points);
    this.x.initialize(points);
    this.x_fake.initialize(points);
    this.color.initialize(points);
  };

  // Updates Axes - Repositioning Y Axis Unnecessary
  this.update = function (points: any) {
    this.y.update(points);
    this.x.update(points);
    this.x_fake.update(points);
    this.color.update(points);
  };
  return this
};

export const TransitionAxes = function (
  this: typeof d3.selection.prototype,
  chart: any
) {
  Axes.bind(this)(chart);
  this.__name__ = "TransitionAxes";

  this.color = new ColorAxis(this, chart);
  this.time = new TimeAxis(this, chart);
  this.z = new ZCoordinateAxis(this, chart);

  // Creates Axes and Initializes with Initial Data Points
  this.initialize = function (points: any) {
    switch (this.chart.activeChartType) {
      case "BarChart":
        this.dimensions = ["x", "y", "color"]; // Just for Reference for Now

        if (!this.y)
          this.y = BarYCoordinateAxis.bind(this.append("g"))(this, chart);
        if (!this.x) this.x = CategoryAxis.bind(this.append("g"))(this, chart);

        if (!this.x_fake)
          this.x_fake = FakeCategoryAxis.bind(this.append("g"))(this, chart);
        break;

      default:
        this.dimensions = ["x", "y", "z", "color"]; // Just for Reference for Now

        if (!this.y)
          this.y = YCoordinateAxis.bind(this.append("g"))(this, chart);
        if (!this.x)
          this.x = XCoordinateAxis.bind(this.append("g"))(this, chart);
        break;
    }

    // Must Create Y First to Setup Scales for Initial X Position
    this.y.initialize(points);
    this.x.initialize(points);
    this.z.initialize(points); // Initialize Z Even Though We May Not Use for Bars
    this.color.initialize(points);

    if (this.chart.activeChartType == "BarChart") {
      this.x_fake.initialize(points);
    }
  };

  // Updates Axes - Repositioning Y Axis Unnecessary
  this.update = function (points: any) {
    this.y.update(points);
    this.x.update(points);
    this.color.update(points);

    if (this.chart.activeChartType == "BubbleChart") {
      this.z.update(points);
    }
    if (this.chart.activeChartType == "BarChart") {
      this.x_fake.update(points);
    }
  };

  this.toggle = function (points: any) {
    switch (this.chart.activeChartType) {
      case "BarChart":
        this.dimensions = ["x", "y", "color"]; // Just for Reference for Now

        CategoryAxis.bind(this.x)(this, chart);
        this.x.update(points);

        this.x_fake = FakeCategoryAxis.bind(this.append("g"))(this, chart);
        this.x_fake.initialize(points);

        BarYCoordinateAxis.bind(this.y)(this, chart);
        this.y.update(points);

        this.color.update(points);
        break;

      default:
        this.dimensions = ["x", "y", "z", "color"]; // Just for Reference for Now
        XCoordinateAxis.bind(this.x)(this, chart);
        this.x.initialize(points);
        //this.x.update(points)
        this.x_fake.remove();

        YCoordinateAxis.bind(this.y)(this, chart);
        this.y.update(points);

        this.z.update(points);
        this.color.update(points);
        break;
    }
  };
  return this;
};
