import { useQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";
import {
  Table as AntdTable,
  Button,
  Checkbox,
  Spin,
  TableColumnsType,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { QUERY_ENTRIES } from "./graphql";
import { Link } from "react-router-dom";

const Table = styled(AntdTable<EntryView>)({
  "& .divider": {
    backgroundColor: "#eee !important",
  },
  "& th": {
    verticalAlign: "bottom",
  },
  "& th, td": {
    fontSize: "8pt",
    padding: "1px !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // antd ellipsis is enabled on some columns to set the title attribute
    textOverflow: "clip !important",
  },
  "& th.estRtn": {
    backgroundColor: "Gold !important",
    borderColor: "Gold !important",
  },
  "& th.liquidity": {
    backgroundColor: "LightGreen !important",
    borderColor: "LightGreen !important",
  },
  "& th.commitment": {
    backgroundColor: "LightBlue !important",
    borderColor: "LightBlue !important",
  },
  "& tr.group td": {
    fontWeight: "bold",
  },
  "& tr.group.mgr td, tr.group.acct td": {
    backgroundColor: "#dfdfff",
  },
  "& tr.group.cash td": {
    backgroundColor: "#ddffdd",
  },
  "& tr.group.total td": {
    backgroundColor: "#bfbfff",
  },
  "& tr.selected td": {
    backgroundColor: "yellow !important",
  },
});

const MAX_PERIODS = 12;

enum AcctType {
  futures = 21212121,
  futuresCogency = 31313131,
  cash = 66666666,
  receivables = 77777777,
  liabilities = 88888888,
  shortTermRecs = 55555555,
  restrictedCash = 99999999,
  inflows = 90000001,
  outflows = 90000002,
}

const INVESTABLE_CASH_ACCT_TYPES = [AcctType.cash, AcctType.liabilities];

const ACCT_ORDER = new Map([
  [AcctType.cash, 0],
  [AcctType.liabilities, 1],
  [AcctType.futures, 2],
  [AcctType.futuresCogency, 3],
  [AcctType.shortTermRecs, 4],
  [AcctType.receivables, 5],
  [AcctType.restrictedCash, 6],
  [AcctType.inflows, 7],
  [AcctType.outflows, 8],
]);

const IGNORE_TAGS = ["Inactive", "Redeem"];

function useQueryEntries(variables: {
  portfolioId: number;
  date: string;
  periods: number;
  lookthrough: boolean;
}) {
  return useQuery(QUERY_ENTRIES, {
    variables,
    fetchPolicy: "no-cache",
  });
}

type Portfolio = NonNullable<
  NonNullable<ReturnType<typeof useQueryEntries>["data"]>["portfolio"]
>;

type Entry = NonNullable<Portfolio["entries"]>[number];

const nameSort = (a: EntryView, b: EntryView) => a.name.localeCompare(b.name);
const acctSort = (a: Entry, b: Entry) =>
  ACCT_ORDER.get(a.managerId)! - ACCT_ORDER.get(b.managerId)!;

const formatCurrency = (value?: number | null, fractionDigits = 0) => {
  if (typeof value !== "number") return "";
  const absValue = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(Math.abs(value));
  return value < 0 ? `(${absValue})` : absValue;
};

const formatPercent = (value?: number | null, fractionDigits = 2) =>
  typeof value !== "number"
    ? ""
    : new Intl.NumberFormat(undefined, {
        style: "percent",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(value);

const parseDate = (value?: any) =>
  !value
    ? undefined
    : // if UTC time, parse as local time
      dayjs(typeof value === "string" ? value.slice(0, 10) : value);

const formatDate = (value?: dayjs.Dayjs) =>
  !value ? "" : value.format("M/D/YYYY");

enum RedemptionsSource {
  NavModel = 1,
  ManualEntry = 2,
  RedemptionsSystemTicketConfirmed = 3,
  RedemptionsSystemTicketApproved = 4,
}

const GROUP_BY = {
  firm: (entry: Entry) => entry.initBalance.manager?.strategy?.name ?? "",
  client: (entry: Entry) => entry.initBalance.tags?.clientTag ?? "",
  team: (entry: Entry) => entry.initBalance.tags?.teamTag ?? "",
  open: (entry: Entry) => entry.initBalance.tags?.openTag ?? "",
  guideline: (entry: Entry) => entry.initBalance.tags?.guidelineTag ?? "",
} as const;

interface TranView {
  beginBalance: number;
  estimatedReturn?: number;
  estimatedReturnDate?: dayjs.Dayjs;
}

interface BalanceView {
  additions: number;
  redemptions: number;
  redemptionsSource?: number;
  endBalance: number;
  weight: number;
  nonZeroCount: number;
}

interface LiquidityView {
  nextNotice?: dayjs.Dayjs;
  nextRedemption?: dayjs.Dayjs;
  quantity?: number;
  frequency?: number;
}

interface CommitmentView {
  amount: number;
  isPreInvestment: boolean;
}

interface EntryView {
  key: string | number;
  mgrIndex?: number;
  managerId?: number;
  isAcct: boolean;
  name: string;
  firmTag?: string;
  clientTag?: string;
  teamTag?: string;
  openTag?: string;
  guidelineTag?: string;
  isLinkManager?: boolean;
  isLinkMutable?: boolean;
  initTran?: TranView;
  initBalance: BalanceView;
  initNonZeroCount?: number;
  balances: BalanceView[];
  liquidity?: LiquidityView;
  commitment?: CommitmentView;
  subitems?: EntryView[];
}

function getAllocationColumns(
  date: dayjs.Dayjs,
  iperiod: number
): TableColumnsType<EntryView> {
  return [
    {
      title: date.add(iperiod + 1, "month").format("MMM YYYY"),
      children: [
        {
          width: 2,
          className: "divider",
        },
        {
          title: "Redemptions",
          width: 100,
          render: (_, entry) =>
            formatCurrency(entry.balances[iperiod]?.redemptions),
          onCell: entry => {
            const redemptionSource = entry.balances[iperiod]?.redemptionsSource;
            return {
              style: {
                backgroundColor:
                  redemptionSource ===
                  RedemptionsSource.RedemptionsSystemTicketApproved
                    ? "pink"
                    : redemptionSource ===
                        RedemptionsSource.RedemptionsSystemTicketConfirmed
                      ? "cyan"
                      : undefined,
              },
            };
          },
          key: "redemptions",
          align: "right",
        },
        {
          title: "Additions",
          width: 100,
          render: (_, entry) =>
            formatCurrency(entry.balances[iperiod]?.additions),
          key: "additions",
          align: "right",
        },
        {
          title: "End Allocation",
          width: 100,
          render: (_, entry) =>
            formatCurrency(entry.balances[iperiod]?.endBalance),
          key: "endBalance",
          align: "right",
        },
        {
          title: "Weight",
          width: 50,
          render: (_, entry) => formatPercent(entry.balances[iperiod]?.weight),
          key: "weight",
          align: "right",
        },
        {
          title: "#",
          width: 30,
          render: (_, entry) => {
            if (!entry.isAcct && !entry.managerId) {
              return entry.balances[iperiod].nonZeroCount;
            }
          },
          key: "nonZeroCount",
          align: "right",
        },
      ],
    },
  ];
}

function getTableColumns(
  date: dayjs.Dayjs,
  periods: number,
  collapsedGroups: Set<string>,
  setCollapsedGroups: (collapsedGroups: Set<string>) => void
): TableColumnsType<EntryView> {
  const allocationColumns = Array.from({ length: periods }, (_, i) =>
    getAllocationColumns(date, i)
  ).flat();

  const tableColumns: TableColumnsType<EntryView> = [
    // fixed columns
    {
      width: 30,
      render: (_, entry) => {
        if (entry.subitems) {
          return (
            <Button
              size="small"
              onClick={() => {
                const newSet = new Set(collapsedGroups);
                newSet.delete(entry.name) || newSet.add(entry.name);
                setCollapsedGroups(newSet);
              }}
            >
              {collapsedGroups.has(entry.name) ? "+" : "-"}
            </Button>
          );
        }
        return entry.mgrIndex;
      },
      key: "mgrIndex",
      fixed: "left",
      align: "center",
    },
    {
      title: "Manager Name",
      width: 200,
      render: (_, entry) => {
        if (entry.managerId && !entry.isAcct) {
          return (
            <Link
              data-cy="investment-link"
              to={{ pathname: `/investments/${entry.managerId}` }}
            >
              {entry.name}
            </Link>
          );
        }
        return entry.name;
      },
      key: "name",
      fixed: "left",
      ellipsis: true,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 6,
      }),
    },
    {
      title: "Firm",
      width: 40,
      dataIndex: "firmTag",
      key: "firmTag",
      fixed: "left",
      ellipsis: true,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
    },
    {
      title: "Client",
      width: 40,
      dataIndex: "clientTag",
      key: "clientTag",
      fixed: "left",
      ellipsis: true,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
    },
    {
      title: "Team",
      width: 40,
      dataIndex: "teamTag",
      key: "teamTag",
      fixed: "left",
      ellipsis: true,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
    },
    {
      title: "Open",
      width: 40,
      dataIndex: "openTag",
      key: "openTag",
      fixed: "left",
      ellipsis: true,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
    },
    {
      title: "Guideline",
      width: 40,
      dataIndex: "guidelineTag",
      key: "guidelineTag",
      fixed: "left",
      ellipsis: true,
      onCell: entry => ({
        colSpan: entry.managerId ? 1 : 0,
      }),
    },
    {
      width: 2,
      className: "divider",
      fixed: "left",
    },
    // scrolling columns
    {
      title: "Link",
      width: 30,
      render: (_, entry) => {
        if (entry.isLinkMutable) {
          return <Checkbox checked={entry.isLinkManager} />;
        }
      },
      key: "link",
      align: "center",
    },
    {
      width: 2,
      className: "divider",
    },
    {
      title: date.format("MMM YYYY"),
      children: [
        {
          title: "End Allocation",
          width: 100,
          render: (_, entry) => formatCurrency(entry.initBalance.endBalance),
          key: "endBalance",
          align: "right",
        },
        {
          title: "Weight",
          width: 50,
          render: (_, entry) => formatPercent(entry.initBalance.weight),
          key: "weight",
          align: "right",
        },
        {
          title: "#",
          width: 30,
          render: (_, entry) => {
            if (!entry.managerId && !entry.isAcct) {
              return entry.initBalance.nonZeroCount;
            }
          },
          key: "nonZeroCount",
          align: "right",
        },
      ],
    },
    {
      title: "MTD Update",
      className: "estRtn",
      children: [
        {
          title: "Est Rtn",
          width: 50,
          render: (_, entry) => {
            if (entry.isLinkManager || (!entry.managerId && !entry.isAcct)) {
              return formatPercent(entry.initTran?.estimatedReturn);
            }
          },
          key: "estimatedReturn",
          align: "right",
          className: "estRtn",
        },
        {
          title: "Rtn Date",
          width: 60,
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatDate(entry.initTran?.estimatedReturnDate);
            }
          },
          key: "estimatedReturnDate",
          align: "right",
          className: "estRtn",
        },
      ],
    },
    ...allocationColumns,
    {
      width: 2,
      className: "divider",
    },
    {
      title: "Redemption",
      className: "liquidity",
      children: [
        {
          title: "Notice",
          width: 60,
          key: "nextNotice",
          align: "right",
          className: "liquidity",
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatDate(entry.liquidity?.nextNotice);
            }
          },
          onCell: entry => {
            if (entry.isLinkManager) {
              const nextNotice = entry.liquidity?.nextNotice;
              if (nextNotice) {
                return {
                  style: {
                    backgroundColor: nextNotice.isBefore(dayjs())
                      ? "pink"
                      : nextNotice.isBefore(dayjs().add(1, "month"))
                        ? "LightGreen"
                        : undefined,
                  },
                };
              }
            }
            return {};
          },
        },
        {
          title: "Date",
          width: 60,
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatDate(entry.liquidity?.nextRedemption);
            }
          },
          key: "nextRedemption",
          align: "right",
          className: "liquidity",
        },
        {
          title: "Qty",
          width: 60,
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return formatPercent(entry.liquidity?.quantity, 0);
            }
          },
          key: "quantity",
          align: "right",
          className: "liquidity",
        },
        {
          title: "Freq",
          width: 60,
          render: (_, entry) => {
            if (entry.isLinkManager) {
              return entry.liquidity?.frequency;
            }
          },
          key: "frequency",
          align: "right",
          className: "liquidity",
        },
      ],
    },
    {
      width: 2,
      className: "divider",
    },
    {
      title: "Commitment",
      align: "right",
      className: "commitment",
      children: [
        {
          title: "Amount",
          width: 100,
          render: (_, entry) => formatCurrency(entry.commitment?.amount, 0),
          key: "amount",
          align: "right",
          className: "commitment",
          onCell: entry => ({
            style: {
              backgroundColor: entry.commitment?.isPreInvestment
                ? "pink"
                : undefined,
            },
          }),
        },
      ],
    },
  ];
  return tableColumns;
}

const getEntryView = (portfolio: Portfolio, entry: Entry): EntryView => {
  const isAcct = !!entry.initBalance.acctManager;
  const name = isAcct
    ? entry.initBalance.acctManager?.name || ""
    : entry.initBalance.manager?.shortName ||
      entry.initBalance.manager?.name ||
      "";
  const firmTag = entry.initBalance.manager?.strategy?.code ?? "";
  const clientTag = entry.initBalance.tags?.clientTag ?? "";
  const teamTag = entry.initBalance.tags?.teamTag ?? "";
  const openTag = entry.initBalance.tags?.openTag ?? "";
  const guidelineTag = entry.initBalance.tags?.guidelineTag ?? "";
  const isLinkManager = (portfolio?.isPrimary || entry.isLinked) && !isAcct;
  const isLinkMutable = !portfolio?.isPrimary && !isAcct;

  return {
    key: entry.managerId,
    managerId: entry.managerId,
    isAcct,
    name,
    firmTag,
    clientTag,
    teamTag,
    openTag,
    guidelineTag,
    isLinkManager: isLinkManager,
    isLinkMutable,
    initTran: !entry.initTran
      ? undefined
      : {
          beginBalance: entry.initTran.beginBalance,
          estimatedReturn: entry.initTran.estimatedReturn ?? undefined,
          estimatedReturnDate: parseDate(entry.initTran.estimatedReturnDate),
        },
    initBalance: {
      additions: 0,
      redemptions: 0,
      endBalance: entry.initBalance.endBalance,
      weight: entry.initBalance.weight,
      nonZeroCount:
        !isAcct &&
        !IGNORE_TAGS.includes(clientTag) &&
        entry.initBalance.endBalance
          ? 1
          : 0,
    },
    balances: entry.balances.map(balance => ({
      additions: balance.additions,
      redemptions: balance.redemptions,
      redemptionsSource: balance.redemptionsSource ?? undefined,
      endBalance: balance.endBalance,
      weight: balance.weight,
      nonZeroCount:
        !isAcct && !IGNORE_TAGS.includes(clientTag) && balance.endBalance
          ? 1
          : 0,
    })),
    liquidity: !entry.liquidity
      ? undefined
      : {
          nextNotice: parseDate(entry.liquidity.nextNotice),
          nextRedemption: parseDate(entry.liquidity.nextRedemption),
          quantity: entry.liquidity.quantity ?? undefined,
          frequency: entry.liquidity.frequency ?? undefined,
        },
    commitment: entry.commitment ?? undefined,
  };
};

const sumInitTrans = (items: EntryView[]): TranView => {
  let beginBalance = 0;
  let delta = 0;
  let count = 0;
  for (const item of items) {
    if (!item.initTran) continue;
    beginBalance += item.initTran.beginBalance;
    if (typeof item.initTran.estimatedReturn !== "number") continue;
    delta += item.initTran.beginBalance * item.initTran.estimatedReturn;
    ++count;
  }
  return {
    beginBalance,
    estimatedReturn: count && beginBalance ? delta / beginBalance : undefined,
  };
};

const sumBalances = (items: EntryView[]) => {
  const balances: BalanceView[] = Array.from(
    { length: MAX_PERIODS + 1 },
    () => ({
      additions: 0,
      redemptions: 0,
      endBalance: 0,
      weight: 0,
      nonZeroCount: 0,
    })
  );
  for (const item of items) {
    [item.initBalance, ...item.balances].forEach((balance, i) => {
      balances[i].additions += balance.additions;
      balances[i].redemptions += balance.redemptions;
      balances[i].endBalance += balance.endBalance;
      balances[i].weight += balance.weight;
      balances[i].nonZeroCount += balance.nonZeroCount;
    });
  }
  return balances;
};

const getGroups = (
  groupBy: (entry: Entry) => string,
  portfolio?: Portfolio | null
) => {
  const entries = portfolio?.entries;
  if (!entries) return;
  const mgrEntries = entries.filter(entry => !entry.initBalance.acctManager);
  const mgrGroupMap = new Map<string, EntryView[]>();
  for (const entry of mgrEntries) {
    const name = groupBy(entry);
    const subitems = mgrGroupMap.get(name) ?? [];
    const subitem = getEntryView(portfolio, entry);
    subitems.push(subitem);
    mgrGroupMap.set(name, subitems);
  }

  const groups = [...mgrGroupMap].map(([name, subitems]) => {
    const initTran = sumInitTrans(subitems);
    console.log(name, initTran);
    const [initBalance, ...balances] = sumBalances(subitems);
    const mgrGroup: EntryView = {
      key: `mgr::${name}`,
      isAcct: false,
      name,
      initTran,
      initBalance,
      balances,
      subitems,
    };
    return mgrGroup;
  });

  let index = 0;
  for (const group of groups.sort(nameSort)) {
    for (const subitem of group.subitems!.sort(nameSort)) {
      subitem.mgrIndex = ++index;
    }
  }

  const acctEntries = entries.filter(entry => entry.initBalance.acctManager);
  {
    const subitems = acctEntries
      .sort(acctSort)
      .map(entry => getEntryView(portfolio, entry));
    const initTran = sumInitTrans(subitems);
    const [initBalance, ...balances] = sumBalances(subitems);
    const name = "Accounting";
    const acctGroup: EntryView = {
      isAcct: true,
      key: `acct::${name}`,
      name,
      initTran,
      initBalance,
      balances,
      subitems,
    };
    groups.push(acctGroup);
  }
  {
    const subitems = groups;
    const initTran = sumInitTrans(subitems);
    const [initBalance, ...balances] = sumBalances(subitems);
    const name = "Total";
    const totalGroup: EntryView = {
      isAcct: false,
      key: `total::${name}`,
      name,
      initTran,
      initBalance,
      balances,
      // no subitems
    };
    groups.push(totalGroup);
  }
  {
    const subitems = acctEntries
      .filter(entry => INVESTABLE_CASH_ACCT_TYPES.includes(entry.managerId))
      .map(entry => getEntryView(portfolio, entry));
    const [initBalance, ...balances] = sumBalances(subitems);
    const name = "Investable Cash";
    const cashGroup: EntryView = {
      isAcct: true,
      key: `cash::${name}`,
      name,
      // no initTran
      initBalance,
      balances,
      // no subitems
    };
    // insert before total group
    groups.splice(-1, 0, cashGroup);
  }

  console.log("GROUPS ->", groups);
  return groups;
};

const getDataSource = (collapsedGroups: Set<string>, groups?: EntryView[]) => {
  if (!groups) return;
  const dataSource = groups.flatMap(group => {
    const expanded = !collapsedGroups.has(group.name);
    const subitems = (expanded && group.subitems) || [];
    return [...subitems, group];
  });

  console.log("DATASOURCE ->", dataSource);
  return dataSource;
};

const useTableHeight = () => {
  const [tableHeight, setTableHeight] = useState(400);
  // Update the height based on the viewport height
  useEffect(() => {
    const updateTableHeight = () => {
      // Adjust the height as needed (subtracting for any fixed headers/footers)
      const height = window.innerHeight - 280;
      setTableHeight(height);
    };

    // Initial height calculation
    updateTableHeight();

    // Add event listener to adjust the table height when the window is resized
    window.addEventListener("resize", updateTableHeight);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateTableHeight);
  }, []);

  return tableHeight;
};

export function Portfolio(props: {
  portfolioId: number;
  date: dayjs.Dayjs;
  periods: number;
  lookthrough: boolean;
  groupBy: "firm" | "client" | "team" | "open" | "guideline";
}) {
  const { date, lookthrough, periods, portfolioId } = props;

  const [selectedRowKey, setSelectedRowKey] = useState<string | number>();
  const [collapsedGroups, setCollapsedGroups] = useState(new Set(IGNORE_TAGS));

  const tableColumns: TableColumnsType<EntryView> = useMemo(
    () => getTableColumns(date, periods, collapsedGroups, setCollapsedGroups),
    [date, periods, collapsedGroups, setCollapsedGroups]
  );

  const { data, loading, error, refetch } = useQueryEntries({
    date: date.format("YYYY-MM-DD"),
    lookthrough,
    periods: MAX_PERIODS,
    portfolioId,
  });

  const portfolio = data?.portfolio;

  useEffect(() => {
    console.log("ENTRIES ->", portfolio?.entries);
  }, [portfolio?.entries]);

  const groups = useMemo(
    () => getGroups(GROUP_BY[props.groupBy], portfolio),
    [portfolio, props.groupBy]
  );

  const dataSource = useMemo(
    () => getDataSource(collapsedGroups, groups),
    [groups, collapsedGroups]
  );

  const tableHeight = useTableHeight();

  if (loading) return <Spin />;
  if (!dataSource) return null;

  return (
    <Table
      data-cy="asset-allocation-grid"
      columns={tableColumns}
      dataSource={dataSource}
      scroll={{ x: "100%", y: tableHeight }}
      pagination={false}
      bordered
      size="small"
      tableLayout="fixed"
      onRow={entry => {
        const classNames: string[] = [];
        if (entry.key === selectedRowKey) {
          classNames.push("selected");
        }
        if (!entry.managerId) {
          classNames.push("group");
          if (typeof entry.key === "string") {
            if (entry.key.startsWith("mgr::")) {
              classNames.push("mgr");
            } else if (entry.key.startsWith("acct::")) {
              classNames.push("acct");
            } else if (entry.key.startsWith("total::")) {
              classNames.push("total");
            } else if (entry.key.startsWith("cash::")) {
              classNames.push("cash");
            }
          }
        }

        return {
          className: classNames.join(" ") || undefined,
          onClick: () => {
            setSelectedRowKey(entry.key);
          },
        };
      }}
    />
  );
}
