import _ from 'lodash'
import * as d3 from 'd3'


// No Index, Errors, Warnings, Validity - Just Data
class InterpolatedPoint{
    public Date: any;
    public model: any;
    public Name: any;
    public Group: any;
    public left: any;
    public right: any;
    public closest: any;

    constructor(point1: any, point2: any, date: any, model: any){
        let inconsistent = (point1.Name != point2.Name) || (point1.highlighted != point2.highlighted) // doesn't appear to be used. 
        if(point1.Name != point2.Name) {
            throw new Error('Inconsistent Points to Interpolate')
        }

        if(date == point1.Date || date == point2.Date || point1.Date == point2.Date){
            throw new Error('Error: Cannot Interpolate Two Points with Same Date')
        }
        let inBetween = (date > point1.Date && date < point2.Date) || (date < point1.Date && date > point2.Date)
        if(!inBetween){
            throw new Error('Error: Interpolation Date Must be Between Start and End Dates')
        }

        this.Date = date 
        this.model = model 
        this.Name = point1.Name 
        this.Group = point1.Group

        if(point1.Date < point2.Date){
            this.left = point1 
            this.right = point2 
        }
        else{
            this.left = point2 
            this.right = point1
        }
        if(Math.abs(this.left.Date - this.Date) < Math.abs(this.right.Date - this.Date)){
            this.closest = this.left
        }
        else{
            this.closest = this.right
        }
    }
    interpolate(){
        let ratio = (this.Date - this.left.Date) / (this.right.Date - this.left.Date)
        if(ratio < 0.0 || ratio > 1.0) throw new Error('Suspicious Operation')
     
        // Use Closest Point Values for Categoric
        let self = this 
        _.each(this.model.categoric, function(param){
            if(!self.closest[param]){
                throw new Error('Data Point in Interpolation Missing Categorical Param ' + param)
            }
            self[param as keyof typeof self] = self.closest[param]
        })

        _.each(this.model.numeric, function(dim){
            const left = self.left[dim];
            const right = self.right[dim];
            let hide = false;
            if(!left && left !== 0) hide = true;
            if(!right && right !== 0) hide = true;
            if(hide){
                self[dim as "Date" | "model" | "Name" | "Group" | "left" | "right" | "closest"] = null;
            }else{
                self[dim as keyof typeof self] = ratio * (right - left) + left;
            }
        })   
    }

    // Interpolates Numeric Dimensions of Point with Another Point on Different Date with Center Interpolation Date
    static interpolatePoints(point1: any, point2: any, date: any, model: any) { 
        let newPoint = new InterpolatedPoint(point1, point2, date, model) 
        newPoint.interpolate()
        return newPoint 
    }
}

export default InterpolatedPoint

