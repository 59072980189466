import { format } from 'date-fns'

let Format = {
    is_valid_float : function(value: any){
        value = Number(value)
        if(!isNaN(value)){
            return value
        }
        return null
    },
    is_valid_string : function(value: any){
        if(!value || String(value).trim() == ""){
            return null
        }
        return String(value)
    },
    stringify_date : function(date: Date){
        let stringDate = format(date, 'yyyy-MM-dd')
        return stringDate
    },
}

let Geometric = {
    distance : function(point1: any, point2: any){
        let y = Math.pow(point1.y - point2.y, 2)
        let x = Math.pow(point1.x - point2.x, 2)
        return Math.sqrt(x + y)
    }
}

let Coloring = {
    // Takes Any Color Specification and Converts to RGB String with Desired Alpha
  universal_rgb_convert : function(color: any, alpha?: any){
      if(!alpha) alpha = 1.0

      let format = Coloring.colorFormat(color) // Determine if RGB or Hex

      let alphaColor = ''
      if(format == 'hex'){
          let rgb = Coloring.hexToRgb(color)
          alphaColor = Coloring.rgb_to_string(rgb, alpha)
      }
      else{
          let rgb = Coloring.parse_rgb_string(color)
          alphaColor = Coloring.rgb_to_string(rgb, alpha)
      }

      return alphaColor
  },
  rgb_to_string : function(rgb: {r: number, g: number, b: number}, alpha: number){
      let rgbstring = 'rgba(' + rgb['r'] + ',' + rgb['g'] + ',' + rgb['b'] + ',' + String(alpha) + ')'
      return rgbstring
  },
  parse_rgb_string : function(rgb: string){
    let parts = rgb.split(',')
    let components: {r: number, g: number, b: number} = {'r':0,'g':0,'b':0}
    components.r = parseInt(parts[0].split('(')[1])
    components.g = parseInt(parts[1])
    components.b = parseInt(parts[2].split(')')[0])
    return components
  },
  colorFormat : function(color: any){
    color = String(color)
    if(color.indexOf('rgb') != -1) return 'rgb'
    return 'hex'
  },
  hexToRgb: function(hex: string) {
      if(hex.indexOf('#') != -1){
         let parts = hex.split('#')
         hex = parts[1]
      }
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
      } : {
        r: 0,
        g: 0,
        b: 0
      };
  },
}

let Charting = {
  generateLabel : function(name: string){
      return name
  }  
}

export default {
    'Charting' : Charting,
    'Geometric' : Geometric,
    'Coloring' : Coloring,
    'Format' : Format
}
