import "./browserify/index";
import React from "react";
import ReactDOM from "react-dom";
import "frontend/src/legacyStyles/fonts";
import App from "./components/App";
import * as Sentry from "@sentry/react";
import config from "./config/config";

Sentry.init({
  dsn: `https://${config.SENTRY_DSN()}`,
  tunnel: config.SENTRY_TUNNEL(),
  environment: config.SENTRY_ENVIRONMENT(),
  release: config.SENTRY_RELEASE(),
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  beforeSend(event) {
    if (
      event.environment &&
      !["dev", "staging", "production", "test"].includes(event.environment!)
    ) {
      console.log("Environment: ", event.environment);
      console.log("Stubbed Sentry Event: ", event);
      return null;
    }
    return event;
  },
});

// We seem to be running into compatibility issues switching to modern way of rendering.
// I believe this update will require an update to antd. Otherwise it corrupts local.
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
