import ParentSettings from "./parent"


class ControlSettingsParent extends ParentSettings {
    public params: any;
    public enabled: boolean;
    constructor(settings: any){
        super(settings)

        this.__name__ = 'Controls'

        this.enabled = true;
        this.validation = {
            enabled : 'bool'
        }
    }
}

class BarControlSettings extends ControlSettingsParent {
    constructor(settings: any){
        super(settings)

        this.params = ['x','y','color']
        this.validation['params'] = [
            'array', ['x','y','color']
        ]
    }
}

class BubbleControlSettings extends ControlSettingsParent {
    constructor(settings: any){
        super(settings)
        
        this.params = ['x','y','z','color']
        this.validation['params'] = [
            'array', ['x','y','z','color']
        ]
    }
}
// TO DO: Create Validation for Transition Control Params
class TransitionControlSettings extends ControlSettingsParent {
    constructor(settings: any){
        super(settings)
        
        this.params = {
            'bubble' : ['x','y','z','color'],
            'bar' : ['x','y','color']
        }
    }
}

export default {
    BarControlSettings : BarControlSettings,
    BubbleControlSettings : BubbleControlSettings,
    TransitionControlSettings : TransitionControlSettings,
}
