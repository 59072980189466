import * as d3 from "d3";
import _ from "lodash";
import { BarGroup, BubbleBarGroup, BubbleGroup } from "./group";
import { CrossHairs } from "./crosshairs";

export const Markers = function (
  this: typeof d3.selection.prototype,
  chart: any
) {
  CrossHairs.bind(this)(chart);

  this.chart = chart;
  this.groups = [];

  // Finds Any Extra Groups If There Are Any
  this.find_extras = function (data: any) {
    let new_names = _.map(data, "Name");
    let existing_names = _.map(this.groups, "Name");
    let extra = _.difference(existing_names, new_names);
    return extra;
  };

  // Remove Extra Points by Name
  this.remove_extras = function (data: any) {
    let self = this;
    let extra = this.find_extras(data);

    _.each(extra, function (name) {
      let group = _.find(self.groups, { Name: name });
      let index = _.indexOf(self.groups, group);

      self.hideCrossHairs(group.marker);
      group.remove();
      self.groups.splice(index, 1);
    });
  };

  this.focus = function (datum: any) {
    let group = _.find(this.groups, { Name: datum.Name });
    if (!group)
      throw new Error("Error: Cannot Perform Action on Missing Group");

    _.each(this.groups, function (group) {
      if (group.Name == datum.Name) group.focus();
      else group.unfocus();
    });
  };
  // Unfocusing - Label Will be Missing if Deselecting And Then Mousing Outside of Bubble
  this.unfocus = function (datum: any) {
    let group = _.find(this.groups, { Name: datum.Name });
    if (!group)
      throw new Error("Error: Cannot Perform Action on Missing Group");
    group.unfocus();
  };
  this.selectPoint = function (datum: any) {
    let group = _.find(this.groups, { Name: datum.Name });
    if (!group)
      throw new Error("Error: Cannot Perform Action on Missing Group");
    group.select_();
  };

  // Label Can be Already Removed if Method of Unfocusing Involves Removing Label
  this.deselectPoint = function (datum: any) {
    let group = _.find(this.groups, { Name: datum.Name });
    if (!group)
      throw new Error("Error: Cannot Perform Action on Missing Group");
    group.deselect();
  };
  // Draws the elements With Adding Animation to Transition
  this.animate = function (data: any, options?: any) {
    let self = this;
    this.remove_extras(data);
    // Add in Additional Points or Animate Existing Ones
    _.each(data, function (datum: any) {
      let group = _.find(self.groups, { Name: datum.Name });
      if (!group) {
        group = self.createGroup(datum);
        self.groups.push(group);
      }
      group.animate(datum);
    });
  };
  // Draws the elements Without Adding Animation to Transition - Labels Not Drawn
  this.draw = function (data: any, options: any) {
    let self = this;
    this.remove_extras(data);

    // Add in Additional Points or Redraw Existing Ones
    _.each(data, function (datum: any) {
      let group = _.find(self.groups, { Name: datum.Name });
      if (!group) {
        group = self.createGroup(datum);
        self.groups.push(group);
      }
      group.draw(datum);
    });
  };
};

export const Bubbles = function (
  this: typeof d3.selection.prototype,
  chart: any
) {
  Markers.bind(this)(chart);
  this.createGroup = function (datum: any) {
    let group = BubbleGroup.bind(this.append("g").datum(datum))(
      datum,
      this,
      this.chart
    );
    return group;
  };
  return this;
};

export const Bars = function (this: typeof d3.selection.prototype, chart: any) {
  Markers.bind(this)(chart);
  this.createGroup = function (datum: any) {
    let group = BarGroup.bind(this.append("g").datum(datum))(
      datum,
      this,
      this.chart
    );
    return group;
  };
  return this;
};

export const BubbleBars = function (
  this: typeof d3.selection.prototype,
  chart: any
) {
  Markers.bind(this)(chart);
  this.createGroup = function (datum: any) {
    let group = BubbleBarGroup.bind(this.append("g").datum(datum))(
      datum,
      this,
      this.chart
    );
    return group;
  };
  this.toggle = function () {
    let self = this;
    _.each(this.groups, function (group) {
      group.toggle();
    });
  };
  this.toggle();
  return this;
};
