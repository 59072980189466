import DataSettings from './data'
import ChartSettings from './chart'
import ControlSettings from './controls'
import FundListSettings from './fundlist'
import _ from 'lodash'

const BarChartSettings: any = function(this: any, settings: any){

    this.data = new DataSettings(settings.data);
    this.chart = new ChartSettings(settings.chart)
    this.controls = new ControlSettings.BarControlSettings(settings.controls)
    this.fundlist = new FundListSettings(settings.fundlist)

    const self = this
    _.each(_.keys(self), function(key: any){
        if(self[key].populate){
            self[key].populate()
        }
    })

    this.validate = function(model: any){
        const self = this 

        _.each(_.keys(self), function(key){
            if(self[key].validate){
                self[key].validate(model)
            }
        })
    }
}

const BubbleChartSettings: any = function(this: any, settings: any): void{

    this.data = new DataSettings(settings.data);
    this.chart = new ChartSettings(settings.chart)
    this.controls = new ControlSettings.BubbleControlSettings(settings.controls)
    this.fundlist = new FundListSettings(settings.fundlist)
    
    const self = this
    _.each(_.keys(self), function(key: any){
        if(self[key].populate){
            self[key].populate()
        }
    })
    
    this.validate = function(model: any){
        const self = this 

        _.each(_.keys(self), function(key: keyof typeof self){
            if(self[key].validate){
                self[key].validate(model)
            }
        })
    }
}

const TransitionChartSettings: any = function(this: any, settings: any): void{

    this.data = new DataSettings(settings.data);
    this.chart = new ChartSettings(settings.chart)
    this.controls = new ControlSettings.TransitionControlSettings(settings.controls)
    this.fundlist = new FundListSettings(settings.fundlist)

    const self = this
    _.each(_.keys(self), function(key: any){
        if(self[key].populate){
            self[key].populate()
        }
    })

    this.validate = function(model: any){
        const self = this 

        _.each(_.keys(self), function(key: any){
            if(self[key].validate){
                self[key].validate(model)
            }
        })
    }
}

export default {
    'BarChartSettings' : BarChartSettings,
    'BubbleChartSettings' : BubbleChartSettings,
    'TransitionChartSettings' : TransitionChartSettings,
}
