import _ from 'lodash'

export const NumericalDomain = function(array: any[]) {
    const min = _.min(array)
    const max = _.max(array)
    const padding = .16 * (max - min);
    array[0] = min - padding
    array[1] = max + padding
    array.splice(2, array.length - 2)
    return array
}
// If Both Values in Domain Positive or Both Negative, Clamps Appropriate Side to 0 Origin
export const OriginClamp = function(array: number[]) {
    if(array.length != 2 || array[0] > array[1]) throw new Error('Invalid Domain to Clamp')
    if( (array[0] < 0.0 && array[1] < 0.0) || (array[1] > 0.0 && array[0] > 0.0)){
        if(array[0] < 0.0 && array[1] < 0.0){
            array[1] = 0.0
        }
        if(array[1] > 0.0 && array[0] > 0.0){
            array[0] = 0.0
        }
    }
    return array
}



