import * as d3 from "d3";
import Constants from "./constants";
import Base from "./base_chart";
import Settings from "./settings/settings";
import ControlNodes from "./controls";
import Bubble_Chart from "./bubble_chart";
import Bar_Chart from "./bar_chart";
import { TransitionAxes } from "./axes/axes";
import { ZControl } from "./elements/controls";
import _ from 'lodash'
import { BubbleBars } from "./markers/markers";
// Doesnt Use Default Button but Draws a Button With Image
const ChartTypeToggleButton = function (
  this: typeof d3.selection.prototype,
  chart: any,
  type: "bubble" | "bar"
) {
  this.chart = chart;
  this.type = type;

  this.image = Constants.Chart.toggle[type];
  if (!this.image) throw new Error("Invalid Chart Toggle Type");

  this.width = Constants.Chart.toggle.width;
  this.height = Constants.Chart.toggle.height; // Full Height of Menu

  let self = this;

  this.toggleChart = function () {
    this.chart.toggleChartType(this.type);
  };

  this.button = this.append("svg:image")
    .attr("class", "chart-toggle-button")
    .attr("xlink:href", this.image) // TO DO: Make This a Reference from Constants
    .attr("height", this.height)
    .attr("width", this.width);

  this.button.on("click", _.bind(self.toggleChart, self));

  this.button.on("mouseover", function () {
    self.button.style("opacity", 0.8);
  });
  this.button.on("mouseout", function () {
    self.button.style("opacity", 1.0);
  });
  return this;
};

class TransitionChart extends Base {
  public allControls: any;
  public bubbles: any;
  public initialized: any;

  constructor(raw: any, containerId: string, settings: any) {
    let activeChartType = "BubbleChart"; // Default
    let chartType = "TransitionChart";
    let chartSettings = new Settings.TransitionChartSettings(settings);
    super(raw, containerId, chartSettings, chartType, activeChartType);
  }

  afterInitialize() {
    let self = this;
    super.afterInitialize();

    // Have to Set Time Cursor Before Rendering Axes and Timeline
    self.timeCursor = self.startDate;
    self.allControls = {
      BubbleChart: new ControlNodes.BubbleControl(
        self,
        self.settings.controls.params.bubble
      ),
      BarChart: new ControlNodes.BarControl(
        self,
        self.settings.controls.params.bar
      ),
    };

    self.axes = TransitionAxes.bind(self.chart.append("g"))(self);

    // Timeline Has to Come After Axes
    self.timeline.draw();
    self.timeline.render();

    self.currentData = self.points({ date: self.timeCursor }); // Returns As List of Data Points - Points Do Not Include Hidden Points
    _.sortBy(self.currentData, "Name");

    let points = self.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    self.axes.initialize(points);

    // Must Wait Until We Have Data
    if (self.settings.fundlist.enabled) {
      self.fundList.initialize(self.currentData);
    }

    points = self.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    self.axes.initialize(points);

    self.orientDateLabel();
    self.bubbles = BubbleBars.bind(self.chart.append("g"))(self);
    self.bubbles.animate(self.currentData);

    self.renderControls();
    self.drawBoundaries();
    self.initialized = true;
  }
  // Updates Allowed Controls When Chart Type Changes
  updateControls() {
    let self = this;
    _.each(["x", "y", "z", "color"], function (dim) {
      if (
        !_.includes(self.controlDimensions, dim) &&
        self.controls[dim].enabled
      )
        self.controls[dim].disable();
      else if (_.includes(self.controlDimensions, dim)) {
        if (self.controls[dim].disabled) {
          self.controls[dim].enable();
        }
        // Update Children
        let node = _.find(self.controlNodes, { id: dim });
        self.controls[dim].updateNode(node);
      }
    });
  }
  renderControls() {
    if (_.includes(this.controlDimensions, "z")) {
      let zNodes = _.find(this.controlNodes, { id: "z" });
      let zPos = { x: 2.0, y: 18.0 + 50.0 }; // Y Position Accounts for Text
      let dim = {
        width: this.rightMenu.width - 4.0,
        height: Constants.Dropdown.height,
      };

      this.rightMenu
        .append("g")
        .append("text")
        .text("Size")
        .attr("class", "menu-label")
        .attr("alignment-baseline", "middle")
        .attr("x", 2.0) // 2.0 is X Offset of Select Rect, Then Add Width of Select Rect and Right Margin of Select Rect
        .attr("y", 8.0 + 50.0);

      this.controls["z"] = ZControl.bind(this.rightMenu
        .append("g")
      )(zNodes, this, zPos, dim);
      this.controls["z"].initialize();
    }

    super.renderControls();

    // Add Toggle Button
    let position = {
      x: this.chart.width - Constants.Chart.toggle.width,
      y: -1.0 * Constants.Chart.toggle.height,
    };

    ChartTypeToggleButton.bind(this.chart
      .append("g")
      .attr("transform", "translate(" + position.x + "," + position.y + ")"))(this, "bar");

    position = {
      x:
        this.chart.width -
        Constants.Chart.toggle.width -
        Constants.Chart.toggle.width -
        6.0,
      y: -1.0 * Constants.Chart.toggle.height,
    };
    ChartTypeToggleButton.bind(this.chart
      .append("g")
      .attr("transform", "translate(" + position.x + "," + position.y + ")"))(this, "bubble");
  }

  // Positions Date Label to Avoid Bars
  orientDateLabel() {
    if (this.activeChartType == "BarChart") {
      let yOffset = Constants.Timeline.dateLabel.offset.y + 15.0;
      if (this.axes.x.orientation == "top") {
        yOffset = this.chart.height - Constants.Timeline.dateLabel.offset.y;
      }
      this.dateLabel
        .transition()
        .duration(this.settings.chart.animationDuration)
        .ease(d3.easeLinear)
        .attr("y", yOffset);
    }
  }

  // Setting Chart Param Automatically Updates Axis
  dimensionChanged(dimension: any, param: any) {
    if (!_.includes(this.controlDimensions, dimension))
      throw new Error("Cannot Update Non Controllable Dimension");

    this.params[dimension] = param;
    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges

    this.axes.update(points);
    this.bubbles.animate(this.currentData);
    this.orientDateLabel();
  }

  hide(point: any) {
    super.hide(point);
    this.currentData = this.points({ date: this.timeCursor }); // Maintain Time Filtered Points for Non Hidden Markers

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bubbles.animate(this.currentData); // Default Behavior for Hide/Show
    this.orientDateLabel();
  }
  // Called from Context Menu - Shows All Bubbles
  showAll() {
    super.showAll();
    this.currentData = this.points({ date: this.timeCursor });

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bubbles.animate(this.currentData); // Default Behavior for Hide/Show
  }
  show(point: any) {
    super.show(point);
    this.currentData = this.points({ date: this.timeCursor });

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bubbles.animate(this.currentData); // Default Behavior for Hide/Show
    this.orientDateLabel();
  }

  // Update Bubble Positions and Fund List - Do Not Update Axes - Animate Optionally
  update(options: any) {
    this.currentData = this.points({ date: this.timeCursor }); // Returns As List of Data Points - Points Do Not Include Hidden Points

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);

    if (options && options.animate) this.bubbles.animate(this.currentData);
    else this.bubbles.draw(this.currentData); // Default Behavior

    if (this.settings.fundlist.enabled) {
      this.fundList.renderItems(this.currentData);
    }
  }

  focus(point: any, from: any) {
    super.focus(point, from);
    this.bubbles.focus(point); // Will Also Clear All Focuses From Other Bubbles
  }
  unfocus(point: any, from: any) {
    super.unfocus(point, from);
    this.bubbles.unfocus(point);
  }
  selectPoint(point: any, from: any) {
    super.selectPoint(point, from);
    this.bubbles.selectPoint(point);
  }
  deselectPoint(point: any, from: any) {
    super.deselectPoint(point, from);
    this.bubbles.deselectPoint(point);
  }

  get controlNodes() {
    let result = this.allControls[this.activeChartType].nodes();
    if (this.settings.data.idEnabled) {
      result.forEach((node: any) => {
        node.children = node.children.filter((child: any) => {
          return child.label !== this.settings.data.idColumn;
        });
      });
    }
    return result;
  }

  get controlDimensions() {
    if (this.settings.controls.enabled) {
      if (this.activeChartType == "BarChart")
        return this.settings.controls.params["bar"];
      else if (this.activeChartType == "BubbleChart")
        return this.settings.controls.params["bubble"];
    }
    return [];
  }

  get defaultParams() {
    if (this.activeChartType == "BarChart")
      return Bar_Chart.defaultParams_(this);
    else if (this.activeChartType == "BubbleChart")
      return Bubble_Chart.defaultParams_(this);
    else throw new Error("Invalid Chart Type");
  }

  // Determines if Specific Parameter is Valid for Given Dimension
  allowedParam(dimension: any, param: any) {
    if (this.activeChartType == "BarChart")
      return Bar_Chart.allowedParam(this, dimension, param);
    else if (this.activeChartType == "BubbleChart")
      return Bubble_Chart.allowedParam(this, dimension, param);
    else throw new Error("Invalid Chart Type");
  }
  // Just Used for Settings Validation
  static get allowedNodeParents() {
    let parents = ["x", "y", "z", "color"];
    return parents;
  }
  // Toggles Between Bar and Bubble Charts
  // Updates Axes, Animates/Alternates Between Bubbles and Bars
  toggleChartType(type: any) {
    if (this.activeChartType == "BarChart") {
      if (type == "bar") return;
      this.activeChartType = "BubbleChart";
    } else if (this.activeChartType == "BubbleChart") {
      if (type == "bubble") return;
      this.activeChartType = "BarChart";
    }

    // Transition for Y and Color Nodes Irrelevant of Chart Type
    let params = { y: this.params.y, color: this.params.color, x: undefined };
    if (!this.allowedParam("y", this.params.y)) {
      params.y = this.defaultParams.y;
    }

    switch (this.activeChartType) {
      case "BarChart":
        params.x = this.params.y; // Move Y Paramter to X Axis
        if (!this.allowedParam("x", this.params.y)) {
          params.x = this.defaultParams.x;
        }
        break;

      case "BubbleChart":
        params.x = this.params.x;
        if (!this.allowedParam("x", this.params.x)) {
          params.x = this.defaultParams.x;
        }
        // Dont Allow X and Y to be Same
        if (params.x == params.y) {
          let xNodes = _.find(this.controlNodes, { id: "x" });
          let child = _.filter(xNodes.children, function (node) {
            return node.id != params.y;
          });
          params.x = child[0].id;
        }
        break;
    }

    this.params.reset(params); // Bulk Update Parameters - Cannot Set One at a TIme

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.toggle(points);

    this.bubbles.toggle();
    this.updateControls();

    this.currentData = this.points({ date: this.timeCursor });
    this.bubbles.animate(this.currentData);
  }
}

export default TransitionChart;
