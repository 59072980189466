import _ from 'lodash'
import * as d3 from 'd3'

// To DO: Build to Handle More Situations Than Just Positive and Negative
function PositiveHalfColor(this: any, domain: any, lowerClamp: any){
    this.interpolator = d3.interpolateGreens
    this.domain = domain

    // Anything That Would Show Lighter Shade Than This Point is Clamped
    this.lowerClamp = lowerClamp

    let self = this
    this.interpolate = function(value: any){
        let self = this

        if(value < 0.0) throw new Error('Can Only Interpolate Positive Values')

        let ratio = value / (this.domain[1])
        if(this.lowerClamp && ratio < self.lowerClamp){
            ratio = self.lowerClamp
        }
        return self.interpolator(ratio)
    }
    return this
}

function NegativeHalfColor(this: any, domain: any, lowerClamp: any){
    this.interpolator = d3.interpolateReds
    this.domain = domain

    // Anything That Would Show Lighter Shade Than This Point is Clamped
    this.lowerClamp = lowerClamp

    let self = this
    this.interpolate = function(value: any){
        if(value > 0.0) throw new Error('Can Only Interpolate Negative Values')

        let ratio = Math.abs(value) / Math.abs(this.domain[0])
        if(this.lowerClamp && ratio < self.lowerClamp){
            ratio = self.lowerClamp
        }
        return self.interpolator(ratio)
    }
    return this
}
// No Positive vs. Negatives for Gray Interpolation
function Gray(this: any, domain: any, lowerClamp: any, upperClamp: any){
    this.interpolator = d3.interpolateGreys
    this.domain = domain

    // Anything That Would Show Lighter Shade Than This Point is Clamped
    this.lowerClamp = lowerClamp
    this.upperClamp = upperClamp

    let self = this
    this.interpolate = function(value: any){

        let ratio = ( value - this.domain[0] ) / (this.domain[1] - this.domain[0])
        if(this.lowerClamp && ratio < self.lowerClamp){
            ratio = self.lowerClamp
        }
        if(this.upperClamp && ratio < self.upperClamp){
            ratio = self.upperClamp
        }
        return self.interpolator(ratio)
    }
    return this
}

export default {
    'PositiveHalfColor' : PositiveHalfColor,
    'NegativeHalfColor' : NegativeHalfColor,
    'Gray' : Gray,
}