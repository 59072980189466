class DimensionObj extends Object{
    public x: any[];
    public y: any[];
    constructor(){
        super()
        this.x = [0.0, 0.0]
        this.y = [0.0, 0.0]
    }
    y1(value: any[]){this.y[1] = value}
    y0(value: any[]){this.y[0] = value}
    x1(value: any[]){this.x[1] = value}
    x0(value: any[]){this.x[0] = value}
    get width(){
        return this.x[1] - this.x[0]
    }
    get height(){
        if(isNaN(this.y[1] - this.y[0])){
            throw new Error("NANN")
        }
        return this.y[1] - this.y[0]
    }
}

export default DimensionObj