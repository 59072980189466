import Chart from "./port/src/index";
import React, { useEffect, useMemo } from "react";
import { GET_RISK_VISUALIZATIONS_DATA } from "./graphql";
import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import * as d3 from "d3";

function RiskVisualizations({ portfolioId }: { portfolioId: number }) {
  const [chart, setChart] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);

  const divId = useMemo(() => Math.random().toString(36).substring(7), []);
  const visualizationRef = React.useRef<HTMLDivElement>(null);

  const {
    data,
    loading,
    error: queryError,
  } = useQuery(GET_RISK_VISUALIZATIONS_DATA, {
    variables: { portfolioId },
  });

  useEffect(() => {
    if (queryError) {
      setError(queryError);
    }
  }, [queryError]);

  useEffect(() => {
    setError(null);
    if (data && data.riskVisualizationsData.json.length > 0) {
      try {
        const chart = new Chart(
          divId,
          "transition",
          data.riskVisualizationsData.json,
          {
            fundlist: { width: 250 },

            chart: {
              width: 1050,
              height: 650,
              params: {
                z: "same",
                color: "Strategy",
              },
            },
          }
        );

        setChart(chart);
      } catch (e: any) {
        setError(e);
      }
      return () => {
        setError(null);
        visualizationRef.current &&
          d3.select(visualizationRef.current).selectAll("*").remove();
      };
    }
  }, [data]);

  return (
    <div>
      {loading && <Spin />}
      <div
        id={divId}
        ref={visualizationRef}
        data-cy={"visualizations-chart-container"}
      />
      {data && data.riskVisualizationsData.json.length === 0 && (
        <div>No data available</div>
      )}
      {!!error?.message
        ? JSON.stringify({
            message: error?.message,
            stacktrace: error?.stack,
          })
        : null}
    </div>
  );
}

export default RiskVisualizations;
