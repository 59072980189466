/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { handleLogout } from "_sirius/src/actions/action_helpers";
import { setIsExpanded } from "_sirius/src/actions/navigation_sidebar_actions";
import {
  storeNavBarHeight,
  updateNavBarState,
} from "_sirius/src/actions/header_actions";
import { Dropdown, Menu } from "antd";
import FadingHeader from "Components/common/fading_header";
import { GlobalSearch } from "Components/GlobalSearch";
import GlobalSearchControls, {
  pathsWithGlobalSearch,
} from "Components/GlobalSearchWorkspace/GlobalSearchControls.component";
import { onElementHeightChange } from "Helpers/index";
import { isMobileView } from "Helpers/window_wrapper";
import i18n from "i18next";
import RcTooltip from "rc-tooltip";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Authorized } from "./Authorized";
import CreationMenu from "./creation_menu";
import { UserSettingsMenuItem } from "./SettingsLoader/UserSettingsModal";

const CLICK_TRIGGER = ["click"] as ["click"];

export function NavBar() {
  const dispatch = useDispatch();
  const fullName = useSelector((state: any) => state.user.fullName);

  function setNavBarHeight() {
    const navbar = document.getElementById("navbar");
    const navbarHeight =
      (navbar &&
        window.getComputedStyle(navbar, null).getPropertyValue("height")) ||
      0;

    const navBarNode = document.getElementById("navbar-follower");

    if (navBarNode) {
      navBarNode.style.height = String(navbarHeight);
    }

    dispatch(storeNavBarHeight(String(navbarHeight)));
  }

  const navigate = useNavigate();

  useEffect(() => {
    setNavBarHeight();
    onElementHeightChange(document.getElementById("navbar"), () => {
      setNavBarHeight();
    });
  }, []);

  const { pathname } = useLocation();

  return (
    <FadingHeader
      onChange={
        ((state: any) => {
          dispatch(updateNavBarState(state));
        }) as any
      }
      disable={!isMobileView()}
    >
      <div id="navbar" className="main-navbar">
        <div className="main-navbar__content">
          <div
            className="main-logo-sidebar hidden-md-up"
            onClick={() => dispatch(setIsExpanded(true))}
          >
            <i className="main-logo-sidebar__icon" />
          </div>

          <div
            id="fund-explorer-logo"
            className="main-navbar__logo hidden-xl-down"
            onClick={() => navigate("/")}
          >
            <div className="main-navbar__logo-txt">
              {i18n.t("nav_bar.application_name")}
            </div>
          </div>

          <GlobalSearch />

          <div className="main-navbar__content-right">
            <Authorized checks={["isDataManager"]}>
              <CreationMenu />
            </Authorized>

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <a
                      id="logout-button"
                      className="main-popup-dropdown__item"
                      onClick={handleLogout}
                    >
                      {i18n.t("nav_bar.sign_out")}
                    </a>
                  </Menu.Item>
                  <UserSettingsMenuItem key="2" />
                </Menu>
              }
              trigger={CLICK_TRIGGER}
              arrow
            >
              <span
                className="cursor-pointer main-navbar__btn hidden-lg-down"
                id="current-user-email"
              >
                {fullName || i18n.t("nav_bar.user_profile")}
                <i className="main-navbar__btn-arrow icon icon--20 icon-arrow" />
              </span>
            </Dropdown>

            <RcTooltip
              placement="bottomRight"
              trigger={CLICK_TRIGGER}
              overlayClassName="rc-tooltip--no-arrow"
              overlayStyle={{ width: "320px" }}
              overlay={
                <div id="help-modal" className="main-navbar__help-modal">
                  <p>
                    {i18n.t("nav_bar.contact_support")}
                    <br />
                    {i18n.t("nav_bar.contact_support_number")}
                  </p>
                </div>
              }
            >
              <span
                className="main-navbar__btn main-navbar__btn--help hidden-lg-down"
                id="help-dropdown-button"
              >
                <i
                  className="cursor-pointer fa fa-question-circle-o"
                  aria-hidden="true"
                />
              </span>
            </RcTooltip>
          </div>
        </div>

        {/* used for Header component, to define additional sub-header information */}
        <div id="heading-yield" />
        <div id="tabs-yield" />
      </div>
      <div id="navbar-follower" />
      <div id="secondary-menu" />
      {/* Nav bar routing rather than portals 
      https://www.digitalocean.com/community/tutorials/react-using-new-portal-feature-in-react
      My preference is to using routing to determine what goes where up here.
      With portals rarely being the correct way to go.
      Portals make the resulting behavior more confusing than routing.
    */}
      {pathsWithGlobalSearch.includes(pathname) && <GlobalSearchControls />}
    </FadingHeader>
  );
}
