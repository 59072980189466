
class CoordinatePoint {
    constructor(x: any, y: any) {
        this.x = x
        this.y = y
    }

    x: any
    y: any
    marker: any
    destination: any
    dW: any
    dH: any
    theta: any
    dX: any
    dY: any
    label: any
    box: any
    sides: any
    angle: any
    side: any
    

    relativeTo(point: any) {
        let rel: [string | null, string | null] = ['right', 'top']
        if (this.x < point.x) rel[0] = 'left'
        if (this.y > point.y) rel[1] = 'bottom'
        if (this.y == point.y) rel[1] = null
        if (this.x == point.x) rel[0] = null
        return rel
    }

    BubbleAdjustConnectorOrigin(marker: any, destination: any) {
        this.marker = marker
        this.destination = destination

        this.dW = this.destination.x - this.marker.center.x
        this.dH = this.marker.center.y - this.destination.y

        this.theta = Math.tan(this.dH / this.dW)

        this.dX = this.marker.r * Math.cos(this.theta)
        this.dY = this.marker.r * Math.sin(this.theta)
        return this
    }

    AdjustConnectorDestination(label: any, marker: any) {
        this.label = label
        this.box = this.label.coordinates
        this.marker = marker

        this.dH = Math.abs(this.label.center.y - this.marker.center.y)
        this.dW = Math.abs(this.label.center.x - this.marker.center.x)

        this.sides = {
            'top': { x: 0.5 * (this.label.coordinates.x1 + this.label.coordinates.x2), y: this.label.coordinates.y1 },
            'bottom': { x: 0.5 * (this.label.coordinates.x1 + this.label.coordinates.x2), y: this.label.coordinates.y2 },
            'left': { y: 0.5 * (this.label.coordinates.y1 + this.label.coordinates.y2), x: this.label.coordinates.x1 },
            'right': { y: 0.5 * (this.label.coordinates.y1 + this.label.coordinates.y2), x: this.label.coordinates.x2 },
        }

        let relative = this.relativeTo(this.marker.center)
        this.angle = Math.abs(Math.tan(this.dH / this.dW)) * (180.0 / Math.PI)

        if (relative[0] === null) {
            if (relative[1] == 'top') this.side = this.sides['bottom']
            else this.side = this.sides['top']
        }
        else if (relative[1] == null) {
            if (relative[0] == 'left') this.side = this.sides['right']
            else this.side = this.sides['left']
        }
        else {
            if (relative[0] == 'right') {
                if (this.angle < 10.0) {
                    this.side = this.sides['left']
                }
                else {
                    if (relative[1] == 'top') this.side = this.sides['bottom']
                    else this.side = this.sides['top']
                }
            }
            else {
                if (this.angle < 10.0) {
                    this.side = this.sides['right']
                }
                else {
                    if (relative[1] == 'top') this.side = this.sides['bottom']
                    else this.side = this.sides['top']
                }
            }
        }

        this.x = this.side.x
        this.y = this.side.y
    }


}

export default CoordinatePoint
