import React, { useMemo, useState } from "react";

import { Dropdown } from "antd";
import classnames from "classnames";

function FilterControls({
  reset,
  set,
  children,
  disabled,
}: {
  reset?: () => void;
  children: React.ReactNode;
  set: () => void;
  disabled?: boolean;
}) {
  return (
    <div className="main-dropdown__menu-controls">
      {children}
      {!!reset && (
        <button
          className="discard rounded-btn rounded-btn--grey hidden-md-down"
          onClick={reset}
        >
          Reset
        </button>
      )}
      <button
        type="button"
        className={`submit rounded-btn rounded-btn--blue ${
          disabled && "rounded-btn--disabled"
        }`}
        onClick={set}
        disabled={disabled}
      >
        Apply
      </button>
    </div>
  );
}

export function FilterInterface({
  children,
  count,
  label,
  reset,
  set,
  disabled,
  menuClass,
  width,
  minWidth,
  maxWidth,
  additionalControls,
}: {
  children: React.ReactNode;
  additionalControls?: React.ReactNode;
  count: number;
  label: string;
  menuClass: string;
  reset?: () => void;
  set: () => void;
  disabled?: boolean;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
}) {
  const [visible, handleVisibleChange] = useState(false);

  const filterMenu = (
    <div
      className={classnames(
        "main-dropdown__menu",
        "main-dropdown__menu--indents",
        menuClass
      )}
      style={{ width, minWidth, maxWidth }}
    >
      {children}
      <FilterControls
        reset={reset}
        set={() => {
          set();
          handleVisibleChange(false);
        }}
        disabled={disabled}
      >
        {additionalControls}
      </FilterControls>
    </div>
  );

  return (
    <Dropdown
      overlay={filterMenu}
      trigger={["click"]}
      onOpenChange={handleVisibleChange}
      open={visible}
    >
      <div className="main-dropdown" data-cy={`${label}-filter-dropdown`}>
        <div className="main-dropdown__placeholder">
          <div className="main-dropdown__placeholder-text">{label}</div>

          {count ? (
            <span className="main-dropdown__counter">{count}</span>
          ) : null}

          <i className="main-dropdown__arrow hidden-md-down icon icon-arrow" />
        </div>
      </div>
    </Dropdown>
  );
}
