import * as d3 from 'd3'
import _ from 'lodash'


let ContextMenu = function(menu: any, opts?: any){
	let openCallback: any,
		closeCallback: any;

	if (typeof opts === 'function') {
		openCallback = opts;
	} else {
		opts = opts || {};
		openCallback = opts.onOpen;
		closeCallback = opts.onClose;
	}

	// Creates Div Element to Hold Menu
	d3.selectAll('.d3-context-menu').data([1])
		.enter()
		.append('div')
		.attr('class', 'd3-context-menu');

	// Closes Menu
	d3.select('body').on('click.d3-context-menu', function() {
		d3.select('.d3-context-menu').style('display', 'none');
		if (closeCallback) {
			closeCallback();
		}
	});

	// Gets Executed When Context Menu Item Clicked
	return function(this: any, data: any, index: any) {
		let elm = this;

		d3.selectAll('.d3-context-menu').html('');
		let list = d3.selectAll('.d3-context-menu')
			.on('contextmenu', function(event) {
				d3.select('.d3-context-menu').style('display', 'none'); 
				event.preventDefault();
				event.stopPropagation();
			})
			.append('ul');

		list.selectAll('li').data(typeof menu === 'function' ? menu(data) : menu).enter()
			.append('li')
			.attr('class', function(d: any) {
				let ret = '';
				if (d.divider) {
					ret += ' is-divider';
				}
				if (d.disabled) {
					ret += ' is-disabled';
				}
				if (!d.action) {
					ret += ' is-header';
				}
				return ret;
			})
			.html(function(d: any) {
				if (d.divider) {
					return '<hr>';
				}
				if (!d.title) {
					console.error('No title attribute set. Check the spelling of your options.');
				}
				return (typeof d.title === 'string') ? d.title : d.title(data);
			})
			.on('click', function(d: any, i: any) {
				debugger
				if (d.disabled) return; // do nothing if disabled
				if (!d.action) return; // headers have no "action"
				d.action(elm, data, index);
				d3.select('.d3-context-menu').style('display', 'none');

				if (closeCallback) {
					closeCallback();
				}
			});

		// Allows Action to Perform Before Menu Opens
		if (openCallback) {
			if (openCallback(data, index) === false) {
				return;
			}
		}

		d3.select('.your-element')
    .on('contextmenu', function(event) {
				debugger
        // Modern D3-compatible code
        d3.select('.d3-context-menu')
            .style('left', (event.pageX - 2) + 'px')
            .style('top', (event.pageY - 2) + 'px')
            .style('display', 'block');

        event.preventDefault();
        event.stopPropagation();
    });
	};
};

export default ContextMenu;

