import { gql } from "_graphql-types/gql";

export const PRIVATE_PEERS_ROOT = gql(/* GraphQL */ `
  query InvestmentPrivatePeersRoot($id: Int!) {
    investment(id: $id) {
      ...PrivateInvestmentPeersFragment
      id
      name
      performanceDataRange: performancePrivate(
        sort: { field: asOfDate, order: DESC }
      ) {
        returnYear
        returnQuarter
      }
    }
    investmentPrivateQuartileGroupCategoryList(
      page: { limit: 24, offset: 0 }
      sort: [{ field: rank, order: ASC }]
      filter: { investmentId: $id }
    ) {
      items {
        id
        privateQuartileGroupCategory {
          id
          displayLabel
          modifyUser
        }
        privateQuartileGroupCategoryId
        modifyUser
        modifyDate
        isDefault
      }
      total
    }
  }
`);

export const PRIVATE_PEERS_STATS = gql(/* GraphQL */ `
  query InvestmentPrivatePeersStats(
    $id: Int!
    $performancePrivateFilter: PerformancePrivateFilter
    $investmentPeersFilter: InvestmentPeersFilterInput
    $investmentSort: [InvestmentSort!]
    $privateQuartileGroupCategoryId: Int
    $investmentSetId: Int!
    $shouldFetchCurated: Boolean!
    $shouldFetchAuto: Boolean!
    $shouldFetchSet: Boolean!
  ) {
    investment(id: $id) {
      ...PrivateInvestmentPeersFragment
      id
      name
      performanceDataRange: performancePrivate(
        sort: { field: asOfDate, order: DESC }
      ) {
        returnYear
        returnQuarter
      }
      performancePrivate(
        sort: { field: asOfDate, order: DESC }
        filter: $performancePrivateFilter
      ) {
        investmentId
        returnYear
        returnQuarter
        netIRR
        tvpi
        dpi
        relativeStats(
          privateQuartileGroupCategoryId: $privateQuartileGroupCategoryId
        ) {
          relativeTVPIQuartile
          relativeDPIQuartile
          relativeIRRQuartile
          privateQuartileGroupCategoryId
          asOfDate

          privateQuartileGroup {
            id
            asOfDate
          }
          privateQuartileGroupCategory {
            id
            displayLabel
            vintageYear
          }
          privateQuartileGroupId
          investmentId
        }
      }
      peersList(sort: $investmentSort, filter: $investmentPeersFilter)
        @include(if: $shouldFetchCurated) {
        items {
          ...PrivateInvestmentPeersFragment
          id
          name
          performanceDataRange: performancePrivate(
            sort: { field: asOfDate, order: DESC }
          ) {
            returnYear
            returnQuarter
          }
          performancePrivate(
            sort: { field: asOfDate, order: DESC }
            filter: $performancePrivateFilter
          ) {
            investmentId
            returnYear
            returnQuarter
            netIRR
            tvpi
            dpi
            relativeStats(
              privateQuartileGroupCategoryId: $privateQuartileGroupCategoryId
            ) {
              relativeTVPIQuartile
              relativeDPIQuartile
              relativeIRRQuartile
              privateQuartileGroupCategoryId
              asOfDate

              privateQuartileGroup {
                id
                asOfDate
              }
              privateQuartileGroupCategory {
                id
                displayLabel
                vintageYear
              }
              privateQuartileGroupId
              investmentId
            }
          }
        }
      }
      peersAutoList @include(if: $shouldFetchAuto) {
        items {
          ...PrivateInvestmentPeersFragment
          id
          name
          performanceDataRange: performancePrivate(
            sort: { field: asOfDate, order: DESC }
          ) {
            returnYear
            returnQuarter
          }
          performancePrivate(
            sort: { field: asOfDate, order: DESC }
            filter: $performancePrivateFilter
          ) {
            investmentId
            returnYear
            returnQuarter
            netIRR
            tvpi
            dpi
            relativeStats(
              privateQuartileGroupCategoryId: $privateQuartileGroupCategoryId
            ) {
              relativeTVPIQuartile
              relativeDPIQuartile
              relativeIRRQuartile
              privateQuartileGroupCategoryId
              asOfDate

              privateQuartileGroup {
                id
                asOfDate
              }
              privateQuartileGroupCategory {
                id
                displayLabel
                vintageYear
              }
              privateQuartileGroupId
              investmentId
            }
          }
        }
      }
    }

    investmentSet(id: $investmentSetId) @include(if: $shouldFetchSet) {
      id
      name
      investments {
        items {
          ...PrivateInvestmentPeersFragment
          id
          name
          performanceDataRange: performancePrivate(
            sort: { field: asOfDate, order: DESC }
          ) {
            returnYear
            returnQuarter
          }
          performancePrivate(
            sort: { field: asOfDate, order: DESC }
            filter: $performancePrivateFilter
          ) {
            investmentId
            returnYear
            returnQuarter
            netIRR
            tvpi
            dpi
            relativeStats(
              privateQuartileGroupCategoryId: $privateQuartileGroupCategoryId
            ) {
              relativeTVPIQuartile
              relativeDPIQuartile
              relativeIRRQuartile
              privateQuartileGroupCategoryId
              asOfDate

              privateQuartileGroup {
                id
                asOfDate
              }
              privateQuartileGroupCategory {
                id
                displayLabel
                vintageYear
              }
              privateQuartileGroupId
              investmentId
            }
          }
        }
      }
    }
  }
`);

export const PRIVATE_PEERS_SUMMARY = gql(/* GraphQL */ `
  query peersSummary($peersData: [PeersSummaryDetail!]!) {
    peersStatSummary(peersData: $peersData)
  }
`);
