import { gql } from "_graphql-types/gql";

export const SUBMIT_REPORT_JOB = gql(/* GraphQL */ `
  mutation createReport(
    $investmentId: Int!
    $format: FormatOptions!
    $reportType: ReportTypeOptions!
    $elements: [ReportStructure!]
    $subSections: [SubSections!]
    $sendEmail: Boolean!
  ) {
    investmentReport(
      investmentId: $investmentId
      format: $format
      reportType: $reportType
      elements: $elements
      subSections: $subSections
      sendEmail: $sendEmail
    ) {
      id
    }
  }
`);

export const GET_REPORT = gql(/* GraphQL */ `
  query getReport($id: ID!, $format: FormatOptions!) {
    report(id: $id, format: $format)
  }
`);

export const ASYNC_TASK_STATUS_QUERY = gql(`
  query getTask($id: ID!) {
    asyncTaskStatus(id: $id) {
      id
      label
      endDate
      error
    }
  }
`);
