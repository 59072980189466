import * as d3 from "d3";
import _ from "lodash";
import Coordinate from "../utilities/coordinate";
import Utilities from "../utilities/utilities";

const CrossHairLine = function (this: typeof d3.selection.prototype) {
  this.style("stroke-dasharray", "3, 3");
  this.attr("class", "cross-hair-line");
  return this;
};

const CrossHairLabel = function (
  this: typeof d3.selection.prototype,
  text: any
) {
  this.attr("class", "cross-hair-label");
  this.text(String(text));
  return this;
};

const VerticalCrossHairLine = function (
  this: typeof d3.selection.prototype,
  marker: any,
  chart: any
) {
  this.chart = chart;
  this.marker = marker;

  CrossHairLine.bind(this)();

  this.attr("x1", this.marker.crosshairPoint.x);
  this.attr("y1", this.marker.crosshairPoint.y);

  this.attr("x2", this.marker.crosshairXAxisPoint.x);
  this.attr("y2", this.marker.crosshairXAxisPoint.y);

  return this;
};

const VerticalCrossHairLabel = function (
  this: typeof d3.selection.prototype,
  marker: any,
  chart: any
) {
  this.chart = chart;
  this.marker = marker;

  // Only Show Cross Hair If Not Categoric
  if (_.includes(this.chart.numeric, this.chart.params.x)) {
    let point = this.marker.datum();
    let text = point[this.chart.params.x];
    if (Utilities.Format.is_valid_float(text)) {
      text = Utilities.Format.is_valid_float(text).toFixed(4);
    }
    CrossHairLabel.bind(this)(text);

    this.attr(
      "y",
      this.chart.axes.y.scale(this.chart.axes.y.scale.domain()[0]) - 10.0
    );
    this.attr("x", this.marker.crosshairPoint.x + 10.0);
  }
  return this;
};

const HorizontalCrossHairLabel = function (
  this: typeof d3.selection.prototype,
  marker: any,
  chart: any
) {
  this.chart = chart;
  this.marker = marker;

  // Only Show Cross Hair If Not Categoric
  if (_.includes(this.chart.numeric, this.chart.params.y)) {
    let point = this.marker.datum();
    let text = point[this.chart.params.y];
    if (Utilities.Format.is_valid_float(text)) {
      text = Utilities.Format.is_valid_float(text).toFixed(4);
    }
    CrossHairLabel.bind(this)(text);

    this.attr(
      "x",
      this.chart.axes.x.scale(this.chart.axes.x.scale.domain()[0]) + 10.0
    );
    this.attr("y", this.marker.crosshairPoint.y - 10.0);
  }

  return this;
};

const HorizontalCrossHairLine = function (
  this: typeof d3.selection.prototype,
  marker: any,
  chart: any
) {
  this.chart = chart;
  this.marker = marker;

  CrossHairLine.bind(this)();

  this.attr("x1", this.marker.crosshairPoint.x);
  this.attr("y1", this.marker.crosshairPoint.y);

  this.attr("x2", this.marker.crosshairYAxisPoint.x);
  this.attr("y2", this.marker.crosshairYAxisPoint.y);
  return this;
};

export const CrossHairs = function (
  this: typeof d3.selection.prototype,
  chart: any
) {
  this.chart = chart;
  this.lines = {};
  this.texts = {};

  this.showCrossHairs = function (marker: any) {
    this.drawCrossHairLabels(marker);
    this.drawCrossHairLines(marker);
  };

  this.drawCrossHairLines = function (marker: any) {
    this.lines[marker.datum().Name] = { x: null, y: null };
    this.lines[marker.datum().Name].x = HorizontalCrossHairLine.bind(
      this.append("line")
    )(marker, this.chart);
    this.lines[marker.datum().Name].y = VerticalCrossHairLine.bind(
      this.append("line")
    )(marker, this.chart);
  };

  this.drawCrossHairLabels = function (marker: any) {
    this.texts[marker.datum().Name] = { x: null, y: null };
    this.texts[marker.datum().Name].y = VerticalCrossHairLabel.bind(
      this.append("text")
    )(marker, this.chart);
    this.texts[marker.datum().Name].x = HorizontalCrossHairLabel.bind(
      this.append("text")
    )(marker, this.chart);
  };

  this.hideCrossHairs = function (marker: any) {
    if (!this.lines[marker.datum().Name]) return;

    if (this.lines[marker.datum().Name].y)
      this.lines[marker.datum().Name].y.remove();
    if (this.lines[marker.datum().Name].x)
      this.lines[marker.datum().Name].x.remove();
    this.lines[marker.datum().Name] = { x: null, y: null };

    // Text Can be Null if It Was Categoric Param
    if (this.texts[marker.datum().Name].y)
      this.texts[marker.datum().Name].y.remove();
    if (this.texts[marker.datum().Name].x)
      this.texts[marker.datum().Name].x.remove();

    this.texts[marker.datum().Name] = { x: null, y: null };
  };
};

export const BubbleCrossHairPoints = function (
  this: typeof d3.selection.prototype
) {
  let self = this;

  Object.defineProperty(this, "crosshairPoint", {
    configurable: true,
    get: function crosshairPoint() {
      let point = new Coordinate(this.cX, this.cY);
      return point;
    },
  });
  Object.defineProperty(this, "crosshairXAxisPoint", {
    configurable: true,
    get: function crosshairXAxisPoint() {
      let point = new Coordinate(
        this.crosshairPoint.x,
        this.chart.axes.y.scale(this.chart.axes.y.scale.domain()[0])
      );
      return point;
    },
  });
  Object.defineProperty(this, "crosshairYAxisPoint", {
    configurable: true,
    get: function crosshairYAxisPoint() {
      let point = new Coordinate(
        this.chart.axes.x.scale(this.chart.axes.x.scale.domain()[0]),
        this.crosshairPoint.y
      );
      return point;
    },
  });
};

export const BarCrossHairPoints = function (
  this: typeof d3.selection.prototype
) {
  let self = this;

  Object.defineProperty(this, "crosshairPoint", {
    configurable: true,
    get: function crosshairPoint() {
      if (this.negative) return new Coordinate(this.cX, this.y2);
      else return new Coordinate(this.cX, this.y1);
    },
  });
  Object.defineProperty(this, "crosshairXAxisPoint", {
    configurable: true,
    get: function crosshairXAxisPoint() {
      let point = new Coordinate(
        this.chart.axes.x.scale(this.chart.axes.x.scale.domain()[0]) -
          0.5 * this.width,
        this.crosshairPoint.y
      );
      return point;
    },
  });
  Object.defineProperty(this, "crosshairYAxisPoint", {
    configurable: true,
    get: function crosshairYAxisPoint() {
      let point = new Coordinate(
        this.crosshairPoint.x,
        this.chart.axes.y.scale(this.chart.axes.y.scale.domain()[0])
      );
      return point;
    },
  });
};
