import Constants from "../constants";
import _ from "lodash";
import * as d3 from "d3";

function Timeline(this: any, chart: any) {
  this.chart = chart;

  this.line = null;
  this.overlay = null;
  this.slider = null;
  this.button = null;

  this.images = {
    ready: Constants.Timeline.playButton,
    playing: Constants.Timeline.pauseButton,
    paused: Constants.Timeline.playButton,
    finished: Constants.Timeline.playButton,
  };

  Object.defineProperty(this, "image", {
    get: function image() {
      let state = this.chart.state;
      if (!this.images[state])
        throw new Error("Invalid Chart State : " + state);
      return this.images[state];
    },
    set: function image() {
      console.log("Error: Cannot Set image Property");
    },
  });

  // If Not Providing Date - Timeline Uses Time Cursor
  this.render = function () {
    this.button.attr("xlink:href", this.image);
    let xPoint = this.chart.axes.time.scale(this.chart.timeCursor);
    this.slider.attr("x", function (d: any) {
      return xPoint;
    });
  };

  this.draw = function () {
    // Generate View
    let self = this;

    this.line = this.append("line")
      .attr("class", "timeline timeline_line")
      .attr("x1", function (d: any) {
        return (
          Constants.Timeline.button.width +
          Constants.Timeline.button.margins["left"] +
          Constants.Timeline.button.margins["right"]
        );
      })
      .attr("x2", function (d: any) {
        // Because Everything Was Transformed - Have to Renormalize Back to X0
        return self.chart.timeline.width;
      })
      // 17 Pixels Refers to X Axis Height Added - This isn't necessary when the rectangle containing the timeline
      // is larger but when it is small the timeline wont render in the middle of the leftover space without it.
      .attr("y1", function (d: any) {
        return 0.5 * self.chart.timeline.height;
      })
      .attr("y2", function (d: any) {
        return 0.5 * self.chart.timeline.height;
      })

    this.overlay = this.append("rect")
      .attr("class", "timeline overlay")
      .on("click", _.bind(this.chart.clicked, this.chart))
      .attr("x", function (d: any) {
        return (
          Constants.Timeline.button.width +
          Constants.Timeline.button.margins["left"] +
          Constants.Timeline.button.margins["right"]
        );
      })
      .attr("y", function (d: any) {
        return (
          0.5 * (self.chart.timeline.height - Constants.Timeline.overlay.height)
        );
      })
      .attr("width", function (d: any) {
        return (
          self.chart.timeline.width -
          Constants.Timeline.button.width -
          Constants.Timeline.button.margins["left"] -
          Constants.Timeline.button.margins["right"]
        );
      })
      .attr("height", function (d: any) {
        return Constants.Timeline.overlay.height;
      });

    // Initial X Position at 0 - Fill and Stroke Set in CSS
    this.slider = this.append("rect")
      .attr("class", "slider")
      .attr("y", function (d: any) {
        return (
          0.5 * (self.chart.timeline.height - Constants.Timeline.slider.height)
        );
      })
      .attr("height", Constants.Timeline.slider.height)
      .attr("width", Constants.Timeline.slider.width)
      .attr("rx", Constants.Timeline.slider["rx"])
      .attr("ry", Constants.Timeline.slider["ry"])
      .attr("x", function () {
        return (
          Constants.Timeline.button.width +
          Constants.Timeline.button.margins["left"] +
          Constants.Timeline.button.margins["right"]
        );
      });

    this.button = this.append("g")
      .attr("transform", function (d: any) {
        let totalH = self.chart.timeline.height;
        let y = 0.5 * (totalH - Constants.Timeline.button.height);
        let x = Constants.Timeline.button.margins["left"];
        return "translate(" + String(x) + "," + String(y) + ")";
      })
      .append("svg:image")
      .attr("class", "play-button play timeline")
      .attr("y", function (d: any) {
        return 0.0;
      })
      .attr("height", Constants.Timeline.button.height)
      .attr("width", Constants.Timeline.button.width);

    this.button.on("click", _.bind(this.chart.toggleAnimation, this.chart));
    this.slider.call(
      d3.drag().on("drag", _.bind(self.chart.dragged, self.chart))
    );

    this.button.on("mouseover", function () {
      self.button.style("opacity", 0.8);
    });
    this.button.on("mouseout", function () {
      self.button.style("opacity", 1.0);
    });

    this.slider.on("mouseover", function () {
      self.slider.style("opacity", 1.0);
      self.slider.attr("stroke-width", 2.0);
    });
    this.slider.on("mouseout", function () {
      self.slider.style("opacity", 0.8);
      self.slider.attr("stroke-width", 1.0);
    });
  };

  return this;
}

export default Timeline;
