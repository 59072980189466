import * as d3 from 'd3'
import Utilities from '../utilities/utilities'
import Row from './row'
import Point from './point'
import _ from 'lodash'
import FormatRow from './row'

class Parser {

    static parse(model: any, rawData: any){
        if(!rawData || rawData.length == 0){
            throw new Error('Error: Cannot Parse Points With No Data Provided in Model')
        }

        // Format Data Points for Consistency First - Makes Parsing Data Types Easier
        let rows = _.map(rawData, function(row: any){
            return new Row(row)
        })

        const keys = _.map(rows[0], function(value: any, key: any) {
            key = Utilities.Format.is_valid_string(key)
            if(key && key != 'Date'){
                return key;
            }
        });

        const types: any[] = _.map(_.compact(keys), function(key: any){
            for(let i = 0; i < rows.length; i++){
                const value = rows[i][key as keyof FormatRow];
                const float = Utilities.Format.is_valid_float(value)
                if(float || float === 0){
                    return [key, 'numeric']
                }
                const string = Utilities.Format.is_valid_string(value)
                if(string){
                    return [key, 'string']
                }
            }
        });

        model.dataTypes = _.fromPairs(types);

        model.numeric = _.filter(_.keys(model.dataTypes), function(dim: any) { 
            return model.dataTypes[dim] == "numeric"
        })
        model.categoric = _.filter(_.keys(model.dataTypes), function(dim: any) { 
            return model.dataTypes[dim] == "string"
        })

        // Map to Corresponding Data Models
        let series = _.map(rows,function(row: any, index: any){
            return new Point(row, index, model)
        })

        series = Parser.removeInvalids(model, series)
        return series
    }

    // Checks if There Are Any Categorical Parmeters That Differ from Manager to Manager, Checks if 
    // There are Duplicates Entries for Any Given Manager on Given Dates
    static removeInvalids(model: any, series: any){
        let namesPerDate: any = {}
        let categoricalsPerName: any = {}

        _.each(series, function(point: any){
            if(!point.valid){
                point.errors.notify()
            }
            else{
                // Note Any Warnings - Need to Reincorporate
                point.warnings.notify()

                if(!namesPerDate[point.Date]) namesPerDate[point.Date] = []
                if(namesPerDate[point.Date].indexOf(point.Name) != -1){
                    point.errors.duplicate(true) // Notify Immediately
                }
                else{
                    // Keep Track of Observed Names on Date
                    namesPerDate[point.Date].push(point.Name)

                    // Check if Different Categorical Params Specified for Any Given Manager Over Dates
                    _.each(model.categoric, function(dim){
                        if(!categoricalsPerName[point.Name]){
                            categoricalsPerName[point.Name] = {}
                        }
                        if(categoricalsPerName[point.Name][dim] && categoricalsPerName[point.Name][dim] != point[dim]){
                            point.errors.conflictingCategoricalParam(dim, categoricalsPerName[point.Name][dim])
                        }

                    })

                }
            }
        })
        // Remove Points
        series = _.filter(series, function(point: any){
            return point.valid == true;
        })
        return series
    }
}


export default Parser